import { NavLink } from 'react-router-dom'
import { cn } from '@/lib/utils'
import { OrganizationSwitcher } from '@clerk/clerk-react'
import { UserNav } from '@/components/userNav'
import env from '@beam-australia/react-env'
import { ArrowUpRightSquare } from 'lucide-react'

interface Props {
  navDisabled?: boolean
}

export const MainNavigation = (props: Props) => {
  const featureFastChannel = env('FEATURE_FAST_CHANNEL') === 'true'
  const disabledNavigation = props.navDisabled ? true : false
  const baseClasses =
    'text-sm font-medium text-muted-foreground transition-colors hover:text-orange-600'
  const activeClasses = `${baseClasses} text-orange-600`

  return (
    <div className="border-b">
      <div className="flex h-16 items-center px-4">
        <span className="flex-none text-xl font-semibold dark:text-white mr-4" aria-label="Brand">
          <div className="flex gap-2">
            <img src="/radidstream-logo-black.svg" alt="logo" className="w-[30px] h-auto" />
            <span>RapidStream</span>
          </div>
        </span>

        {!disabledNavigation && (
          <nav className={cn('flex items-center space-x-4 lg:space-x-6')}>
            <NavLink to="/" className={({ isActive }) => (isActive ? activeClasses : baseClasses)}>
              Dashboard
            </NavLink>
            <NavLink
              to="/ad-servers"
              className={({ isActive }) => (isActive ? activeClasses : baseClasses)}
            >
              Ad Servers
            </NavLink>
            <NavLink
              to="/ad-rules"
              className={({ isActive }) => (isActive ? activeClasses : baseClasses)}
            >
              Ad Rules
            </NavLink>
            <NavLink
              to="/kill-switches"
              className={({ isActive }) => (isActive ? activeClasses : baseClasses)}
            >
              Kill Switches
            </NavLink>
            {featureFastChannel && (
              <NavLink
                to="/fast-channels"
                className={({ isActive }) => (isActive ? activeClasses : baseClasses)}
              >
                FAST Channels
              </NavLink>
            )}
            <NavLink
              to="/settings"
              className={({ isActive }) => (isActive ? activeClasses : baseClasses)}
            >
              Settings
            </NavLink>
            <NavLink
              to="https://api-docs.rapidstream.video"
              className={baseClasses}
              target={'_blank'}
            >
              <span className={'relative top-0.5 mr-1'}>API Docs</span>
              <ArrowUpRightSquare className={'w-3 inline-block relative -top-1'} />
            </NavLink>
          </nav>
        )}

        <div className="ml-auto flex items-center space-x-4">
          <OrganizationSwitcher
            hidePersonal={true}
            afterSelectOrganizationUrl="/switch-org"
            afterSwitchOrganizationUrl="/switch-org"
            afterCreateOrganizationUrl="/switch-org"
          />
          <UserNav />
        </div>
      </div>
    </div>
  )
}
