import { SignedIn, SignedOut, SignIn } from '@clerk/clerk-react'
import { Dashboard } from '../dashboard'
import { MainNavigation } from '@/components/mainNavigation'
import { Footer } from '@/components/footer'

export const LandingPage = () => {
  return (
    <div>
      <SignedIn>
        <Dashboard />
      </SignedIn>

      <SignedOut>
        <MainNavigation navDisabled={true} />
        <main className="w-full pt-10 px-20 sm:px-10">
          <div className="flex justify-center text-center items-center">
            <div>
              <h1 className="text-5xl mb-20">Login</h1>

              <div>
                <div className="flex justify-center text-center items-center">
                  <SignIn />
                </div>
                <div className="mt-10 mb-20 flex justify-center text-center items-center">
                  <div className="border border-slate-300 rounded-lg shadow-md bg-white p-5 max-w-[400px] text-slate-500">
                    Sign in or up to try RapidStream for free. No credit card required (
                    <a
                      href="https://rapidstream.video/pricing"
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-700"
                    >
                      see pricing
                    </a>
                    ).
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </SignedOut>
    </div>
  )
}
