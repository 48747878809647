import { DataTable } from '@/components/dataTable'
import { ColumnDef } from '@tanstack/react-table'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Button } from '@/components/ui/button'
import { ArrowUpDown, MoreHorizontal } from 'lucide-react'
import { KillSwitchDefinition } from '@/pages/adRules'
import { KillSwitch } from '@/pages/killSwitch'
import { Layout } from '@/components/layout'
import { PageLoadingIndicator } from '@/components/pageLoadingIndicator'
import { Badge } from '@/components/ui/badge'

export interface Props {
  killSwitch: KillSwitch
  onEdit: (definitionId: string) => void
  onDelete: (definitionId: string) => void
}

export const DefinitionsTable = ({ killSwitch, onDelete, onEdit }: Props) => {
  const columns: ColumnDef<KillSwitchDefinition>[] = [
    {
      accessorKey: 'key',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Key
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
    },
    {
      accessorKey: 'values',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Value
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
      cell: ({ row }) => {
        return row.original.values.map((value) => {
          return (
            <span className="font-medium">
              <Badge variant="outline">{value}</Badge>
            </span>
          )
        })
      },
    },
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => {
        const definitionKey: string = row.getValue('key')

        return (
          <div className="flex justify-center">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onEdit(definitionKey)
                  }}
                >
                  Edit Match
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="text-red-500"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onDelete(definitionKey)
                  }}
                >
                  Delete Match
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )
      },
    },
  ]

  if (!killSwitch.definitions) {
    return (
      <Layout>
        <PageLoadingIndicator />
      </Layout>
    )
  }

  return (
    <DataTable
      columns={columns}
      data={killSwitch.definitions}
      hideColumnButton={true}
      onSelect={(data) => {
        onEdit(data.key)
      }}
    />
  )
}
