import { Checkbox } from '@/components/ui/checkbox'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { FormItem } from '@/components/ui/form'
import { Label } from '@/components/ui/label'
import { useToast } from '@/components/ui/use-toast'
import { useBoundStore } from '@/store'
import { useEffect, useState } from 'react'
import { cn } from '@/lib/utils'
import { useToken } from '@/hooks/useToken'

export interface Props {
  adRuleCount: number
  adServerCount: number
  killSwitchCount: number
  ingestCount?: unknown | null
  stripeEmail: boolean
  stripePaymentMethod: boolean
}

export const TodoList = ({
  adRuleCount,
  adServerCount,
  killSwitchCount,
  ingestCount,
  stripeEmail,
  stripePaymentMethod,
}: Props) => {
  const { getClerkToken } = useToken()
  const { toast } = useToast()
  const getAdRules = useBoundStore((state) => state.getAdRules)
  const adRules = useBoundStore((state) => state.adRules)
  const getAdServers = useBoundStore((state) => state.getAdServers)
  const adServers = useBoundStore((state) => state.adServers)

  const [hasInventoryShare, setHasInventoryShare] = useState<boolean>(false)
  const [hasAdRuleDefinitions, setHasAdRuleDefinitions] = useState<boolean>(false)
  const [hasAdServerConditions, setHasAdServerConditions] = useState<boolean>(false)

  useEffect(() => {
    const fetchServers = async () => {
      const token = await getClerkToken()
      try {
        await getAdRules(token)
        await getAdServers(token)
      } catch (e) {
        toast({
          variant: 'destructive',
          title: 'Failed',
          description: `${e}`,
        })
      }
    }

    fetchServers()
  }, [getClerkToken, toast, getAdRules, getAdServers])

  useEffect(() => {
    const inventoryCount = adRules.find((rule) => rule.inventoryShares.length > 0)
    const definitionCount = adRules.find((rule) => rule.definitions.length > 0)
    const conditionCount = adServers.find((server) => server.configuration?.length || 0)

    setHasInventoryShare(inventoryCount ? true : false)
    setHasAdRuleDefinitions(definitionCount ? true : false)
    setHasAdServerConditions(conditionCount ? true : false)
  }, [adRules, adServers])

  if (
    hasInventoryShare &&
    hasAdRuleDefinitions &&
    hasAdServerConditions &&
    adRuleCount > 0 &&
    adServerCount > 0 &&
    killSwitchCount > 0 &&
    ingestCount !== null
  ) {
    return null
  }

  return (
    <Card>
      <CardHeader className="space-y-0 pb-2">
        <CardTitle className="text-xl font-medium mb-5">Your first steps to get started</CardTitle>
        <p>
          In order to get started, it's recommended to do a basic setup. You should perform the
          following steps:
        </p>
      </CardHeader>
      <CardContent>
        <ul className="mt-10 mb-10">
          <li
            className={`rounded-md border ${
              stripeEmail ? 'border-orange-200' : 'border-orange-600'
            } border-input bg-transparent px-3 pt-1 pb-3 mb-2 flex align-center`}
          >
            <FormItem className="flex gap-2 place-items-center">
              <Checkbox className="mt-2" checked={stripeEmail} />{' '}
              <Label className="font-bold">
                Add a payment email address for your organization (required)
              </Label>
            </FormItem>
          </li>

          <li
            className={`rounded-md border ${
              stripePaymentMethod ? 'border-orange-200' : 'border-orange-600'
            } border-input bg-transparent px-3 pt-1 pb-3 mb-10 flex align-center`}
          >
            <FormItem className="flex gap-2 place-items-center">
              <Checkbox className="mt-2" checked={stripePaymentMethod} />{' '}
              <Label className="font-bold">
                Add a payment method for your organization (required)
              </Label>
            </FormItem>
          </li>

          <li className="rounded-md border border-input bg-transparent px-3 pt-1 pb-3 mb-2 flex align-center">
            <FormItem className="flex gap-2 place-items-center">
              <Checkbox className="mt-2" checked={adServerCount > 0} />{' '}
              <Label>
                Create a new AdServer (
                <a
                  href="https://rapidstream.video/docs/ad-server-configuration/"
                  className="text-blue-600"
                  target="_blank"
                  rel="noreferrer"
                >
                  see how in our docs
                </a>
                )
              </Label>
            </FormItem>
          </li>

          <li
            className={cn(
              'rounded-md border border-input bg-transparent px-3 pt-1 pb-3 mb-1 flex flex-row align-center ml-5',
              adServerCount === 0 && 'text-muted-foreground bg-muted'
            )}
          >
            <FormItem className="flex gap-2 place-items-center">
              <Checkbox className="mt-2" checked={hasAdServerConditions} />{' '}
              <Label>Create an Ad Server condition</Label>
            </FormItem>
          </li>

          <li className="rounded-md border border-input bg-transparent px-3 pt-1 pb-3 mb-2 flex align-center">
            <FormItem className="flex gap-2 place-items-center">
              <Checkbox className="mt-2" checked={adRuleCount > 0} />{' '}
              <Label>
                Create at least one Ad Rule (
                <a
                  href="https://rapidstream.video/docs/ad-rules/"
                  className="text-blue-600"
                  target="_blank"
                  rel="noreferrer"
                >
                  see how in our docs
                </a>
                )
              </Label>
            </FormItem>
          </li>
          <li
            className={cn(
              'rounded-md border border-input bg-transparent px-3 pt-1 pb-3 mb-1 flex align-center ml-5',
              adRuleCount === 0 && 'text-muted-foreground bg-muted'
            )}
          >
            <FormItem className="flex gap-2 place-items-center">
              <Checkbox className="mt-2" checked={hasInventoryShare} />{' '}
              <Label>Create an Ad Rule Inventory Share</Label>
            </FormItem>
          </li>
          <li
            className={cn(
              'rounded-md border border-input bg-transparent px-3 pt-1 pb-3 mb-1 flex align-center ml-5',
              adRuleCount === 0 && 'text-muted-foreground bg-muted'
            )}
          >
            <FormItem className="flex gap-2 place-items-center">
              <Checkbox className="mt-2" checked={hasAdRuleDefinitions} />{' '}
              <Label>Create an Ad Rule Match</Label>
            </FormItem>
          </li>

          <li className="rounded-md border border-input bg-transparent px-3 pt-1 pb-3 mb-2 flex align-center">
            <FormItem className="flex gap-2 place-items-center">
              <Checkbox className="mt-2" checked={killSwitchCount > 0} />{' '}
              <Label>
                Create at least one kill switch (
                <a
                  href="https://rapidstream.video/docs/kill-switches/"
                  className="text-blue-600"
                  target="_blank"
                  rel="noreferrer"
                >
                  see how in our docs
                </a>
                )
              </Label>
            </FormItem>
          </li>

          <li className="rounded-md border border-input bg-transparent px-3 pt-1 pb-3 mb-2 flex align-center">
            <FormItem className="flex gap-2 place-items-center">
              <Checkbox className="mt-2" checked={ingestCount !== null} />{' '}
              <Label>
                Ingest EPG data (
                <a
                  href="https://api-docs.rapidstream.video/#ingest"
                  className="text-blue-600"
                  target="_blank"
                  rel="noreferrer"
                >
                  see how in our docs
                </a>
                )
              </Label>
            </FormItem>
          </li>
        </ul>
      </CardContent>
    </Card>
  )
}
