import { StateCreator } from 'zustand'
import { apiGet } from '@/lib/api'
import { convertApiToStoreError, StoreApiToken, StoreCallback } from '@/store/index'

type DashboardLastIngest = {
  dateTime: string
  status: 'succeed' | 'failed' | 'inProgress'
  errorMessage: string
}

export type Dashboard = {
  fastChannels: {
    count: number
  }
  adRules: {
    count: number
  }
  adServers: {
    count: number
  }
  killSwitches: {
    count: number
  }
  epgCatalog: {
    count: number
    lastIngest: DashboardLastIngest | null
  }
}

export interface DashboardSlice {
  dashboard: Dashboard | null
  getDashboard: (token: StoreApiToken, callback?: StoreCallback) => Promise<void>
  dashboardReset: () => void
}

export const createDashboardSlice: StateCreator<DashboardSlice, [], [], DashboardSlice> = (
  set,
  get
) => ({
  dashboard: null,
  getDashboard: async (token, callback) => {
    apiGet({
      token,
      path: `/dashboard`,
      callback: (success, response) => {
        if (success) {
          set({ dashboard: response })

          if (callback) {
            callback(true, response)
          }
        } else {
          if (callback) {
            callback(false, null, convertApiToStoreError(response))
          }
        }
      },
    })
  },
  dashboardReset: () => set({ dashboard: null }),
})
