import { Layout } from '@/components/layout'
import { useToast } from '@/components/ui/use-toast'
import { useBoundStore } from '@/store'
import { useEffect } from 'react'
import { CreateTile } from '@/components/createTile'
import { PageLoadingIndicator } from '@/components/pageLoadingIndicator'
import { KillSwitchTable } from '@/pages/killSwitch/components/killSwitchTable'
import { useToken } from '@/hooks/useToken'

export type KillSwitch = {
  id?: string
  name: string
  definitions: KillSwitchDefinition[]
  enabled: boolean
  revision?: number
}

export type KillSwitchDefinition = {
  key: string
  values: string[]
}

export const KillSwitches = () => {
  const { getClerkToken } = useToken()
  const { toast } = useToast()
  const killSwitches = useBoundStore((state) => state.killSwitches)
  const getKillSwitches = useBoundStore((state) => state.getKillSwitches)
  const createKillSwitch = useBoundStore((state) => state.createKillSwitch)

  useEffect(() => {
    const fetchServers = async () => {
      const token = await getClerkToken()
      await getKillSwitches(token)
    }

    fetchServers()
  }, [getClerkToken, toast, getKillSwitches])

  if (!killSwitches) {
    return (
      <Layout>
        <PageLoadingIndicator />
      </Layout>
    )
  }

  return (
    <Layout>
      <div>
        <h1 className="text-5xl">Kill Switches</h1>

        <div className="flex gap-20 mt-20">
          <div>
            <CreateTile
              label="Kill Switches"
              number={killSwitches.length}
              dialogButtonText="Create"
              dialogDescription="Create a new Kill Switch to set rules"
              dialogLabel="Name"
              dialogTitle="Create Kill Switch"
              dialogTriggerText="Create Kill Switch"
              postEndpoint="/setting/kill-switch"
              redirectPath="/kill-switches"
              storeAction={createKillSwitch}
            />
          </div>
          <KillSwitchTable killSwitches={killSwitches} />
        </div>
      </div>
    </Layout>
  )
}
