import { StateCreator } from 'zustand'
import { apiGet, apiPut, missingTokenError } from '@/lib/api'
import { convertApiToStoreError, StoreApiToken, StoreCallback } from '@/store/index'

export type AdMediationSettings = {
  tracking: {
    errorCallbackUrl: string
    impressionUrl: string
    completeUrl: string
    thirdQuartileUrl: string
    midpointUrl: string
    firstQuartileUrl: string
    startUrl: string
    adBreakPreparedWebhook: string
  }
  filter: {
    supportedMimeTypes: string[]
    minAdBitrate: number
    minAdDuration: number
    frequencyCap: number
    frequencyIntervalInMinutes: number
  }
  revision?: number
} | null

export type ApiTrackingVerb =
  | 'set-error-callback-url'
  | 'set-impression-url'
  | 'set-complete-url'
  | 'set-third-quartile-url'
  | 'set-midpoint-url'
  | 'set-first-quartile-url'
  | 'set-start-url'
  | 'set-ad-break-prepared-webhook'

export type ApiTrackingFieldNames =
  | 'errorCallbackUrl'
  | 'impressionUrl'
  | 'completeUrl'
  | 'thirdQuartileUrl'
  | 'midpointUrl'
  | 'firstQuartileUrl'
  | 'startUrl'
  | 'adBreakPreparedWebhook'

type TrackingFieldVerbs = {
  fieldName: ApiTrackingFieldNames
  verb: ApiTrackingVerb
}

type TrackingFieldData = {
  fieldName: ApiTrackingFieldNames
  url: string
}

export interface AdMediationSlice {
  adMediationSettings: AdMediationSettings
  getAdMediationSettings: (token: StoreApiToken, callback?: StoreCallback) => Promise<void>
  updateTrackingUrl: (
    data: TrackingFieldData,
    token: StoreApiToken,
    etag: string,
    setEtag: any,
    callback: StoreCallback
  ) => void
  updateMinAdDuration: (
    data: number,
    token: StoreApiToken,
    etag: string,
    setEtag: any,
    callback: StoreCallback
  ) => void
  updateMinBitrate: (
    data: number,
    token: StoreApiToken,
    etag: string,
    setEtag: any,
    callback: StoreCallback
  ) => void
  updateSupportedMimeTypes: (
    data: string[],
    token: StoreApiToken,
    etag: string,
    setEtag: any,
    callback: StoreCallback
  ) => void
  updateFrequencyCap: (
    data: number,
    token: StoreApiToken,
    etag: string,
    setEtag: any,
    callback: StoreCallback
  ) => void
  updateFrequencyInterval: (
    data: number,
    token: StoreApiToken,
    etag: string,
    setEtag: any,
    callback: StoreCallback
  ) => void
  adMediationReset: () => void
}

export const createAdMediationSlice: StateCreator<AdMediationSlice, [], [], AdMediationSlice> = (
  set,
  get
) => ({
  adMediationSettings: null,
  getAdMediationSettings: async (token, callback) => {
    if (!token) {
      if (callback) {
        callback(false, null, missingTokenError())
      }

      return
    }

    apiGet({
      token,
      path: `/setting/ad-mediation`,
      callback: (success, response) => {
        if (success) {
          set({ adMediationSettings: response })
          if (callback) {
            callback(success, response)
          }
        } else {
          if (callback) {
            callback(false, null, convertApiToStoreError(response))
          }
        }
      },
    })
  },
  updateTrackingUrl: async (data, token, etag, setEtag, callback) => {
    if (!token) {
      callback(false, null, missingTokenError())
    }

    const trackingFieldsToVerbs: TrackingFieldVerbs[] = [
      {
        fieldName: 'errorCallbackUrl',
        verb: 'set-error-callback-url',
      },
      {
        fieldName: 'impressionUrl',
        verb: 'set-impression-url',
      },
      {
        fieldName: 'completeUrl',
        verb: 'set-complete-url',
      },
      {
        fieldName: 'thirdQuartileUrl',
        verb: 'set-third-quartile-url',
      },
      {
        fieldName: 'midpointUrl',
        verb: 'set-midpoint-url',
      },
      {
        fieldName: 'firstQuartileUrl',
        verb: 'set-first-quartile-url',
      },
      {
        fieldName: 'startUrl',
        verb: 'set-start-url',
      },
      {
        fieldName: 'adBreakPreparedWebhook',
        verb: 'set-ad-break-prepared-webhook',
      },
    ]

    const setting = trackingFieldsToVerbs.find(
      (trackingField) => trackingField.fieldName === data.fieldName
    )

    if (!setting) {
      callback(false, null, {
        type: 'error',
        title: 'Could not update tracking url',
        detail: 'Could not find setting',
      })
      return
    }

    apiPut({
      path: `/setting/ad-mediation/tracking/${setting?.verb}`,
      data: {
        url: data.url,
      },
      etag,
      setEtag,
      token,
      callback: (success, response) => {
        if (success) {
          const adMediationSettings = get().adMediationSettings

          if (!adMediationSettings) {
            callback(false, null, convertApiToStoreError(response))
            return
          }

          adMediationSettings.tracking[setting.fieldName] = data.url

          set({ adMediationSettings })
          callback(true, response)
        } else {
          callback(false, null, convertApiToStoreError(response))
        }
      },
    })
  },
  updateMinAdDuration: async (data, token, etag, setEtag, callback) => {
    if (!token) {
      callback(false, null, missingTokenError())
    }

    apiPut({
      path: `/setting/ad-mediation/ad-filter/set-min-ad-duration`,
      data: {
        duration: data,
      },
      etag,
      setEtag,
      token,
      callback: (success, response) => {
        if (success) {
          const adMediationSettings = get().adMediationSettings

          if (!adMediationSettings) {
            callback(false, null, convertApiToStoreError(response))
            return
          }

          adMediationSettings.filter.minAdDuration = data

          set({ adMediationSettings })
          callback(true, response)
        } else {
          callback(false, null, convertApiToStoreError(response))
        }
      },
    })
  },
  updateMinBitrate: async (data, token, etag, setEtag, callback) => {
    if (!token) {
      callback(false, missingTokenError())
    }

    apiPut({
      path: `/setting/ad-mediation/ad-filter/set-min-bitrate`,
      data: {
        bitrate: data,
      },
      etag,
      setEtag,
      token,
      callback: (success, response) => {
        if (success) {
          const adMediationSettings = get().adMediationSettings

          if (!adMediationSettings) {
            callback(false, null, convertApiToStoreError(response))
            return
          }

          adMediationSettings.filter.minAdBitrate = data

          set({ adMediationSettings })
          callback(true, response)
        } else {
          callback(false, null, convertApiToStoreError(response))
        }
      },
    })
  },
  updateSupportedMimeTypes: async (data, token, etag, setEtag, callback) => {
    if (!token) {
      callback(false, missingTokenError())
    }

    apiPut({
      path: `/setting/ad-mediation/ad-filter/set-supported-mime-types`,
      data: {
        mimeTypes: data,
      },
      etag,
      setEtag,
      token,
      callback: (success, response) => {
        if (success) {
          const adMediationSettings = get().adMediationSettings

          if (!adMediationSettings) {
            callback(false, null, convertApiToStoreError(response))
            return
          }

          adMediationSettings.filter.supportedMimeTypes = data

          set({ adMediationSettings })
          callback(true, response)
        } else {
          callback(false, null, convertApiToStoreError(response))
        }
      },
    })
  },
  updateFrequencyCap: async (data, token, etag, setEtag, callback) => {
    if (!token) {
      callback(false, missingTokenError())
    }

    apiPut({
      path: `/setting/ad-mediation/ad-filter/set-frequency-cap`,
      data: {
        frequencyCap: data,
      },
      etag,
      setEtag,
      token,
      callback: (success, response) => {
        if (success) {
          const adMediationSettings = get().adMediationSettings

          if (!adMediationSettings) {
            callback(false, null, convertApiToStoreError(response))
            return
          }

          adMediationSettings.filter.frequencyCap = data

          set({ adMediationSettings })
          callback(true, response)
        } else {
          callback(false, null, convertApiToStoreError(response))
        }
      },
    })
  },
  updateFrequencyInterval: async (data, token, etag, setEtag, callback) => {
    if (!token) {
      callback(false, missingTokenError())
    }

    apiPut({
      path: `/setting/ad-mediation/ad-filter/set-frequency-interval`,
      data: {
        frequencyIntervalInMinutes: data,
      },
      etag,
      setEtag,
      token,
      callback: (success, response) => {
        if (success) {
          const adMediationSettings = get().adMediationSettings

          if (!adMediationSettings) {
            callback(false, null, convertApiToStoreError(response))
            return
          }

          adMediationSettings.filter.frequencyIntervalInMinutes = data

          set({ adMediationSettings })
          callback(true, response)
        } else {
          callback(false, null, convertApiToStoreError(response))
        }
      },
    })
  },
  adMediationReset: async () => set({ adMediationSettings: null }),
})
