import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { z } from 'zod'
import { Input } from '@/components/ui/input'
import { AdServerConfigurationText } from '@/pages/adServers'
import { UseFormReturn } from 'react-hook-form'

interface Props {
  formValues?: AdServerConfigurationText
  form: UseFormReturn<{ type: string; targetKey: string; targetValue?: any }, any, undefined>
}

export const formTypeTextSchema = z.object({
  type: z.string(),
  targetKey: z.string(),
  targetValue: z.any(),
})

export const getFormTypeTextDefaults = (formValues?: AdServerConfigurationText) => {
  return {
    type: 'text',
    targetKey: formValues?.config.targetKey ?? '',
    targetValue: formValues?.config.targetValue ?? '',
  }
}

export const setFormTypeTextData = (newFormValues: any) => {
  return {
    config: {
      targetKey: newFormValues.targetKey,
      targetValue: newFormValues.targetValue,
    },
    type: 'text',
  }
}

export const FormTypeText = ({ form }: Props) => {
  return (
    <>
      <FormField
        control={form.control}
        name="targetKey"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Target Key</FormLabel>
            <FormControl>
              <Input
                {...form.register('targetKey', {
                  required: true,
                })}
                placeholder="Key"
                autoFocus={true}
              />
            </FormControl>
            <FormDescription>Key</FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="targetValue"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Target Value</FormLabel>
            <FormControl>
              <Input
                {...form.register('targetValue', {
                  required: true,
                })}
                placeholder="Value"
              />
            </FormControl>
            <FormDescription>Value</FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  )
}
