import { Layout } from '@/components/layout'
import { useToast } from '@/components/ui/use-toast'
import { useEffect, useState } from 'react'
import { PageLoadingIndicator } from '@/components/pageLoadingIndicator'
import { StoreError, useBoundStore } from '@/store'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Card, CardContent } from '@/components/ui/card'
import { AutosaveUrlInput } from '@/components/autosaveUrlInput'
import { ApiTrackingFieldNames } from '@/store/adMediation'
import { AutosaveTextInput } from '@/components/autosaveTextInput'
import { useToken } from '@/hooks/useToken'
import { AutosaveTagInput } from '@/components/autosaveTagInput'

export const AdMediationEdit = () => {
  const { getClerkToken } = useToken()
  const { toast } = useToast()

  const adMediationSettings = useBoundStore((state) => state.adMediationSettings)
  const getAdMediationSettings = useBoundStore((state) => state.getAdMediationSettings)
  const updateTrackingUrl = useBoundStore((state) => state.updateTrackingUrl)
  const updateMinAdDuration = useBoundStore((state) => state.updateMinAdDuration)
  const updateMinBitrate = useBoundStore((state) => state.updateMinBitrate)
  const updateFrequencyCap = useBoundStore((state) => state.updateFrequencyCap)
  const updateFrequencyInterval = useBoundStore((state) => state.updateFrequencyInterval)
  const updateSupportedMimeTypes = useBoundStore((state) => state.updateSupportedMimeTypes)

  const [etag, setEtag] = useState<string>('')

  const updateMimeTypes = async (
    data: any,
    callback: (result: boolean, value: string, error?: StoreError) => void
  ) => {
    const token = await getClerkToken()

    try {
      updateSupportedMimeTypes(data.supportedMimeTypes, token, etag, setEtag, callback)
    } catch (e) {
      toast({
        variant: 'destructive',
        title: 'Failed',
        description: `${e}`,
      })
    }
  }

  const onUpdateTrackingUrl = async (
    data: any,
    callback: (result: boolean, value: string, error?: StoreError) => void
  ) => {
    const token = await getClerkToken()
    const dataKey = Object.keys(data)[0] as ApiTrackingFieldNames
    const dataValue = Object.values(data)[0] as string

    try {
      updateTrackingUrl(
        {
          fieldName: dataKey,
          url: dataValue,
        },
        token,
        etag,
        setEtag,
        callback
      )
    } catch (e) {
      toast({
        variant: 'destructive',
        title: 'Failed',
        description: `${e}`,
      })
    }
  }
  const onUpdateMinAdDuration = async (
    data: any,
    callback: (result: boolean, value: string, error?: StoreError) => void
  ) => {
    const token = await getClerkToken()
    try {
      updateMinAdDuration(data.minAdDuration, token, etag, setEtag, callback)
    } catch (e) {
      toast({
        variant: 'destructive',
        title: 'Failed',
        description: `${e}`,
      })
    }
  }

  const onUpdateMinAdBitrate = async (
    data: any,
    callback: (result: boolean, value: string, error?: StoreError) => void
  ) => {
    const token = await getClerkToken()
    try {
      updateMinBitrate(data.minAdBitrate, token, etag, setEtag, callback)
    } catch (e) {
      toast({
        variant: 'destructive',
        title: 'Failed',
        description: `${e}`,
      })
    }
  }

  const onUpdateFrequencyInterval = async (
    data: {
      intervalInMinutes: number
    },
    callback: (result: boolean, value: string, error?: StoreError) => void
  ) => {
    const token = await getClerkToken()
    try {
      updateFrequencyInterval(data.intervalInMinutes, token, etag, setEtag, callback)
    } catch (e) {
      toast({
        variant: 'destructive',
        title: 'Failed',
        description: `${e}`,
      })
    }
  }

  const onUpdateFrequencyCap = async (
    data: {
      cap: number
    },
    callback: (result: boolean, value: string, error?: StoreError) => void
  ) => {
    const token = await getClerkToken()
    try {
      updateFrequencyCap(data.cap, token, etag, setEtag, callback)
    } catch (e) {
      toast({
        variant: 'destructive',
        title: 'Failed',
        description: `${e}`,
      })
    }
  }

  useEffect(() => {
    const fetchServers = async () => {
      const token = await getClerkToken()
      await getAdMediationSettings(token)
    }

    fetchServers()
  }, [getAdMediationSettings, getClerkToken])

  useEffect(() => {
    // only set etag on initial load
    if (etag || (!adMediationSettings?.revision && adMediationSettings?.revision !== 0)) return
    setEtag(`W/"${adMediationSettings.revision.toString()}"`)
  }, [setEtag, etag, adMediationSettings])

  const renderTrackingUrlFields = () => {
    if (!adMediationSettings) {
      return null
    }
    const description =
      'Must contain the placeholder {adId}, {adBreakId}, {adServerId} and {adServerName}'

    const trackingKeys = Object.keys(adMediationSettings.tracking)
    const fieldNames = [
      {
        fieldName: 'errorCallbackUrl',
        label: 'Error Callback URL',
        description,
      },
      {
        fieldName: 'impressionUrl',
        label: 'Impression URL',
        description,
      },
      {
        fieldName: 'completeUrl',
        label: 'Complete URL',
        description,
      },
      {
        fieldName: 'thirdQuartileUrl',
        label: 'Third Quartile URL',
        description,
      },
      {
        fieldName: 'midpointUrl',
        label: 'Midpoint URL',
        description,
      },
      {
        fieldName: 'firstQuartileUrl',
        label: 'First Quartile URL',
        description,
      },
      {
        fieldName: 'startUrl',
        label: 'Start URL',
        description,
      },
      {
        fieldName: 'adBreakPreparedWebhook',
        label: 'Ad Break Prepared Webhook',
        description: '',
      },
    ]

    return trackingKeys.map((fieldName) => {
      const fieldValue = adMediationSettings.tracking[fieldName as ApiTrackingFieldNames]
      const fieldLabel =
        fieldNames.find((field) => field.fieldName === fieldName)?.label ?? fieldName
      const fieldDescription =
        fieldNames.find((field) => field.fieldName === fieldName)?.description ?? ''

      return (
        <AutosaveUrlInput
          onSubmit={onUpdateTrackingUrl}
          value={fieldValue}
          fieldName={fieldName}
          label={fieldLabel}
          required={false}
          placeholder="https://…"
          description={fieldDescription}
        />
      )
    })
  }

  if (!adMediationSettings) {
    return (
      <Layout>
        <PageLoadingIndicator />
      </Layout>
    )
  }

  return (
    <Layout>
      <div>
        <h1 className="text-5xl">Ad Mediation Settings</h1>

        <Tabs defaultValue="tracking" className="w-[100%] mt-20">
          <TabsList>
            <TabsTrigger value="tracking">Tracking</TabsTrigger>
            <TabsTrigger value="filter">Filter</TabsTrigger>
          </TabsList>
          <Card>
            <CardContent>
              <TabsContent value="tracking">
                <h2 className="text-2xl mb-5 mt-10">Tracking</h2>
                <div className="grid grid-cols-2 gap-20">{renderTrackingUrlFields()}</div>
              </TabsContent>
              <TabsContent value="filter">
                <h2 className="text-2xl mb-5 mt-10">Filter</h2>
                <div className="grid grid-cols-2 gap-20">
                  <AutosaveTextInput
                    onSubmit={onUpdateMinAdDuration}
                    value={adMediationSettings.filter.minAdDuration}
                    fieldName="minAdDuration"
                    description="Provide a value in seconds"
                    label="Minimum ad duration"
                    type="number "
                  />

                  <AutosaveTextInput
                    onSubmit={onUpdateMinAdBitrate}
                    value={adMediationSettings.filter.minAdBitrate}
                    fieldName="minAdBitrate"
                    description="Minimum ad bitrate"
                    label="Minimum ad bitrate"
                    type="number "
                  />

                  <AutosaveTagInput
                    onSubmit={updateMimeTypes}
                    value={adMediationSettings?.filter.supportedMimeTypes ?? []}
                    label="Supported mime types"
                    fieldName="supportedMimeTypes"
                    description="Press enter to add values"
                    placeholder="video/mp4"
                  />

                  <div>
                    <AutosaveTextInput
                      onSubmit={onUpdateFrequencyInterval}
                      value={adMediationSettings.filter.frequencyIntervalInMinutes}
                      fieldName="intervalInMinutes"
                      description="Provide a value in minutes"
                      label="Frequency interval"
                      type="number "
                    />

                    <AutosaveTextInput
                      onSubmit={onUpdateFrequencyCap}
                      value={adMediationSettings.filter.frequencyCap}
                      fieldName="cap"
                      description="How often the same ad is shown in given interval"
                      label="Frequency cap"
                      type="number "
                    />
                  </div>
                </div>
              </TabsContent>
            </CardContent>
          </Card>
        </Tabs>
      </div>
    </Layout>
  )
}
