export const Footer = () => {
  return (
    <footer className="bg-gray-100 mt-10">
      <div className="container mx-auto py-10">
        <div className="flex flex-row gap-4 justify-center">
          <div>
            <a href="https://rapidstream.video/panel/tos" target="_blank" rel="noreferrer">
              Terms of Service
            </a>
          </div>
          <div>
            <a href="https://rapidstream.video/panel/privacy" target="_blank" rel="noreferrer">
              Privacy
            </a>
          </div>
          <div>
            <a href="https://rapidstream.video/panel/revocation" target="_blank" rel="noreferrer">
              Revocation Policy
            </a>
          </div>
          <div>
            <a href="https://rapidstream.video/dpa" target="_blank" rel="noreferrer">
              DPA
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}
