import {
  AdServer,
  AdServerConfiguration,
  AdServerConfigurationConditional,
  AdServerConfigurationExists,
  AdServerConfigurationPlaceholder,
  AdServerConfigurationText,
  AdServerConfigurationType,
} from '@/pages/adServers'
import { ColumnDef } from '@tanstack/react-table'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Button } from '@/components/ui/button'
import { ArrowUpDown, MoreHorizontal } from 'lucide-react'
import { DataTable } from '@/components/dataTable'
import { useState } from 'react'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet'
import { FormByType } from '@/pages/adServerEdit/components/formByType'
import { CreateTypeButton } from '@/pages/adServerEdit/components/createTypeButton'

interface Props {
  adServer: AdServer
  updateAdServerConfiguration: (
    data: any,
    callback: (result: boolean, value: string) => void,
    configurationId: string
  ) => void
  addAdServerConfiguration: (data: any, callback: (result: boolean, value: string) => void) => void
  deleteAdServerConfiguration: (data: string, callback: (result: boolean) => void) => void
}

export const ConfigurationTable = ({
  adServer,
  deleteAdServerConfiguration,
  addAdServerConfiguration,
  updateAdServerConfiguration,
}: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [configurationType, setConfigurationType] = useState<AdServerConfigurationType>('text')
  const [configurationId, setConfigurationId] = useState<string>()

  const columns: ColumnDef<AdServerConfiguration>[] = [
    {
      accessorKey: 'id',
      header: 'Configuration Id',
    },
    {
      accessorKey: 'type',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Type
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
    },
    {
      accessorKey: 'config.targetKey',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Target Key
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
    },
    {
      accessorKey: 'config.targetValue',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Target Value
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
    },
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => {
        const configurationId: string = row.getValue('id')
        const configurationType: AdServerConfigurationType = row.getValue('type')

        return (
          <div className="flex justify-center">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <DropdownMenuItem
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    showConfiguration(configurationType, configurationId)
                  }}
                >
                  Edit configuration
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  className="text-red-500"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    deleteAdServerConfiguration(configurationId, () => {})
                  }}
                >
                  Delete Configuration
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )
      },
    },
  ]

  const showConfiguration = (configType: AdServerConfigurationType, configurationId?: string) => {
    if (configurationId) {
      setConfigurationId(configurationId)
    } else {
      setConfigurationId(undefined)
    }

    setConfigurationType(configType)
    setDialogOpen(true)
  }

  const closeDialog = (addAnotherEntry: boolean) => {
    setDialogOpen(addAnotherEntry)
  }

  const renderFormByType = () => {
    const formValues =
      configurationId && adServer.configuration
        ? adServer.configuration.find((config) => config.id === configurationId)
        : undefined

    switch (configurationType) {
      case 'text':
        return (
          <FormByType
            formType="text"
            onCreate={addAdServerConfiguration}
            onUpdate={updateAdServerConfiguration}
            onSaved={closeDialog}
            formValues={formValues as AdServerConfigurationText}
          />
        )
      case 'placeholder':
        return (
          <FormByType
            formType="placeholder"
            onCreate={addAdServerConfiguration}
            onUpdate={updateAdServerConfiguration}
            onSaved={closeDialog}
            formValues={formValues as AdServerConfigurationPlaceholder}
          />
        )
      case 'conditional':
        return (
          <FormByType
            formType="conditional"
            onCreate={addAdServerConfiguration}
            onUpdate={updateAdServerConfiguration}
            onSaved={closeDialog}
            formValues={formValues as AdServerConfigurationConditional}
          />
        )
      case 'exists':
        return (
          <FormByType
            formType="exists"
            onCreate={addAdServerConfiguration}
            onUpdate={updateAdServerConfiguration}
            onSaved={closeDialog}
            formValues={formValues as AdServerConfigurationExists}
          />
        )
    }
  }

  const renderDialog = () => {
    return (
      <>
        <Sheet open={dialogOpen} onOpenChange={setDialogOpen}>
          <SheetContent className="w-[600px]">
            <SheetHeader>
              <SheetTitle>Ad Server Configuration</SheetTitle>
              <SheetDescription className="pb-10">
                This will create a new ad server configuration of the type{' '}
                <strong>{configurationType}</strong>.
              </SheetDescription>
            </SheetHeader>
            {renderFormByType()}
          </SheetContent>
        </Sheet>
      </>
    )
  }

  return (
    <>
      <CreateTypeButton showConfiguration={showConfiguration} buttonText={'+'} />
      <DataTable
        columns={columns}
        data={adServer.configuration || []}
        onSelect={(data: any) => showConfiguration(data.type, data.id)}
        className={'my-4'}
      />
      <div className="mt-4 mb-4">
        <CreateTypeButton showConfiguration={showConfiguration} buttonText={'+'} />
      </div>
      {renderDialog()}
    </>
  )
}
