import { v4 as uuidv4 } from 'uuid'
import { StoreApiToken, StoreError } from '@/store'
import env from '@beam-australia/react-env'

interface IApiGet {
  path: string
  callback: (success: boolean, data?: any) => void
  setEtag?: (etag: string) => void
  token: StoreApiToken
}

interface IApiPut {
  path: string
  data: any
  etag?: string
  setEtag?: (etag: string) => void
  callback: (success: boolean, data?: any) => void
  token: StoreApiToken
}

interface IApiDelete {
  path: string
  etag: string
  setEtag?: (etag: string) => void
  callback: (success: boolean, data?: any) => void
  token: StoreApiToken
}

export const missingTokenError = (): StoreError => {
  return {
    type: 'authentication-error',
    title: 'Unauthorized',
    detail: 'No authentication token was provided.',
  }
}

export const apiGet = async ({ path, setEtag, callback, token }: IApiGet) => {
  if (!token) {
    if (callback) {
      callback(false, missingTokenError())
    }

    return
  }

  fetch(`${env('API_BASE_URL')}${path}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(async (res) => {
      const json = await res.json()
      return {
        etag: res.headers.get('etag'),
        json: json,
        status: res.status,
      }
    })
    .then((res) => {
      if (res.status === 401) {
        callback(false, res.json)
        return res.json
      }

      if (setEtag) {
        setEtag(res.etag || '')
      }

      callback(true, res.json)
    })
    .catch((error) => {
      callback(false, error)
    })
}

export const apiPut = async ({ path, data, etag, setEtag, callback, token }: IApiPut) => {
  if (!token) {
    if (callback) {
      callback(false, missingTokenError())
    }

    return
  }

  fetch(`${env('API_BASE_URL')}${path}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'idempotency-key': uuidv4(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'if-match': `${etag || ''}`,
    },
    body: JSON.stringify(data),
  })
    .then(async (res) => {
      const json = await res.json()
      return {
        etag: res.headers.get('etag'),
        json: json,
        status: res.status,
      }
    })
    .then((res) => {
      if (res.status !== 200 && res.status !== 201 && res.status !== 204) {
        const backendError: StoreError = {
          type: res.json.type ?? 'unknown-error',
          title: res.json.title ?? 'Unknown error',
          detail: res.json.detail ?? 'An unknown error occurred.',
        }

        throw backendError
      }

      if (setEtag) {
        setEtag(res.etag || '')
      }

      callback(true, res.json)
    })
    .catch((error) => {
      callback(false, error)
    })
}
export const apiPost = async ({ path, data, etag, setEtag, callback, token }: IApiPut) => {
  if (!token) {
    if (callback) {
      callback(false, missingTokenError())
    }

    return
  }

  fetch(`${env('API_BASE_URL')}${path}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'idempotency-key': uuidv4(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'if-match': `${etag || ''}`,
    },
    body: JSON.stringify(data),
  })
    .then(async (res) => {
      const json = res.status === 201 || res.status === 200 ? await res.json() : null
      return {
        etag: res.headers.get('etag'),
        json,
        status: res.status,
      }
    })
    .then((res) => {
      if (res.status !== 201 && res.status !== 204) {
        if (!res.json) {
          throw new Error(`Error: ${res.status}`)
        }

        const backendError: StoreError = {
          type: res.json.type ?? 'unknown-error',
          title: res.json.title ?? 'Unknown error',
          detail: res.json.detail ?? 'An unknown error occurred.',
        }

        throw backendError
      }
      if (setEtag) {
        setEtag(res.etag || '')
      }
      return res.json
    })
    .then((data) => {
      callback(true, data)
    })
    .catch((error) => {
      callback(false, error)
    })
}

export const apiDelete = async ({ path, etag, setEtag, callback, token }: IApiDelete) => {
  if (!token) {
    if (callback) {
      callback(false, missingTokenError())
    }

    return
  }

  fetch(`${env('API_BASE_URL')}${path}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'idempotency-key': uuidv4(),
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'if-match': `${etag}`,
    },
  })
    .then(async (res) => {
      const json = res.status === 204 ? null : await res.json()
      return {
        etag: res.headers.get('etag'),
        json,
        status: res.status,
      }
    })
    .then((res) => {
      if (res.status !== 204) {
        const backendError: StoreError = {
          type: res.json.type ?? 'unknown-error',
          title: res.json.title ?? 'Unknown error',
          detail: res.json.detail ?? 'An unknown error occurred.',
        }

        throw backendError
      }

      if (setEtag) {
        setEtag(res.etag || '')
      }

      callback(true, res.json)
    })
    .catch((error) => {
      callback(false, error)
    })
}
