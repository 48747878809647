import { Layout } from '@/components/layout'
import { useToast } from '@/components/ui/use-toast'
import { useBoundStore } from '@/store'
import { useEffect } from 'react'
import { CreateTile } from '@/components/createTile'
import { AdRuleTable } from '@/pages/adRules/adRuleTable'
import { PageLoadingIndicator } from '@/components/pageLoadingIndicator'
import { useToken } from '@/hooks/useToken'

export type AdRule = {
  id: string
  name: string
  enabled: boolean
  revision: number
  definitions: KillSwitchDefinition[]
  inventoryShares: AdRuleInventoryShare[]
}

export type KillSwitchDefinition = {
  key: string
  values: string[]
}

export type AdRuleInventoryShare = {
  id: string
  waterfall: string[]
  share: number
}

export const AdRules = () => {
  const { getClerkToken } = useToken()
  const { toast } = useToast()
  const getAdRules = useBoundStore((state) => state.getAdRules)
  const adRules = useBoundStore((state) => state.adRules)
  const createAdRule = useBoundStore((state) => state.createAdRule)

  useEffect(() => {
    const fetchServers = async () => {
      const token = await getClerkToken()
      await getAdRules(token)
    }

    fetchServers()
  }, [getClerkToken, toast, getAdRules])

  if (!adRules) {
    return (
      <Layout>
        <PageLoadingIndicator />
      </Layout>
    )
  }

  return (
    <Layout>
      <div>
        <h1 className="text-5xl">Ad Rules</h1>

        <div className="flex gap-20 mt-20">
          <div>
            <CreateTile
              label="Ad Rules"
              number={adRules.length}
              dialogButtonText="Create"
              dialogDescription="Enter a name to create a new AdRule"
              dialogLabel="Name"
              dialogTitle="Create Ad Rule"
              dialogTriggerText="Create Ad Rule"
              postEndpoint="/setting/ad-rule"
              redirectPath="/ad-rules"
              storeAction={createAdRule}
            />
          </div>
          <AdRuleTable adRules={adRules} />
        </div>
      </div>
    </Layout>
  )
}
