import React from 'react'
import './App.css'
import {
  ClerkProvider,
  OrganizationSwitcher,
  SignIn,
  useOrganization,
  useUser,
} from '@clerk/clerk-react'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import { LandingPage } from './pages/landingpage'
import { UserProfilePage } from '@/pages/userProfile'
import { AdServers } from '@/pages/adServers'
import { AdServerEdit } from '@/pages/adServerEdit'
import { AdRules } from '@/pages/adRules'
import { AdRulesEdit } from '@/pages/adRulesEdit'
import { KillSwitches } from '@/pages/killSwitch'
import { KillSwitchEdit } from '@/pages/killSwitchEdit'
import { AdMediationEdit } from '@/pages/adMediationEdit'
import { FastChannels } from '@/pages/fastChannels'
import { FastChannelsEdit } from '@/pages/fastChannelsEdit'
import { SwitchOrg } from '@/pages/switch-org'
import env from '@beam-australia/react-env'
import { QuotaPage } from 'src/pages/quota'
import { AccessTokensPage } from '@/pages/access-tokens'
import { AccountPage } from '@/pages/account'
import { MainNavigation } from '@/components/mainNavigation'
import { Toaster } from '@/components/ui/toaster'
import { Footer } from '@/components/footer'
import * as Sentry from '@sentry/react'

if (!env('CLERK_PUBLISHABLE_KEY')) {
  throw new Error('Missing Publishable Key')
}

const clerkPubKey = env('CLERK_PUBLISHABLE_KEY')

const ForceOrganizationSelection = () => {
  const { isLoaded, isSignedIn } = useUser()
  const { organization } = useOrganization()
  const hasOrganization = organization ? true : false

  const FallbackComponent = () => <div>An error has occurred</div>
  const myFallback = () => <FallbackComponent />
  const featureFastChannel = env('FEATURE_FAST_CHANNEL') === 'true'

  if (isLoaded && isSignedIn && !hasOrganization) {
    return (
      <Sentry.ErrorBoundary fallback={myFallback} showDialog>
        <MainNavigation navDisabled={true} />
        <Toaster />
        <main className="w-full pt-10 px-20 sm:px-10">
          <div className="grid grid-cols-1 place-items-center m-20">
            <p>Please select or create an organization to continue using RapidStream.</p>

            <div className="rounded-md bg-slate-50 p-5 mt-5 shadow-lg">
              <OrganizationSwitcher
                hidePersonal={true}
                afterSelectOrganizationUrl="/switch-org"
                afterSwitchOrganizationUrl="/switch-org"
                afterCreateOrganizationUrl="/switch-org"
              />
            </div>
          </div>
        </main>
        <Footer />
      </Sentry.ErrorBoundary>
    )
  } else if (isLoaded) {
    return (
      <Routes>
        <Route path="/" element={<LandingPage />} />

        <Route path="/ad-servers" element={<AdServers />} />
        <Route path="/ad-servers/:id" element={<AdServerEdit />} />

        <Route path="/ad-rules" element={<AdRules />} />
        <Route path="/ad-rules/:id" element={<AdRulesEdit />} />

        <Route path="/kill-switches" element={<KillSwitches />} />
        <Route path="/kill-switches/:id" element={<KillSwitchEdit />} />

        {featureFastChannel && <Route path="/fast-channels" element={<FastChannels />} />}
        {featureFastChannel && <Route path="/fast-channels/:id" element={<FastChannelsEdit />} />}

        <Route path="/settings" element={<AdMediationEdit />} />

        <Route path="/login/*" element={<SignIn routing="path" path="/login" />} />
        <Route path="/user/profile" element={<UserProfilePage />} />
        <Route path="/switch-org" element={<SwitchOrg />} />

        <Route path="/quota" element={<QuotaPage />} />
        <Route path="/account" element={<AccountPage />} />

        <Route path="/access-token" element={<AccessTokensPage />} />
      </Routes>
    )
  }

  return null
}

function ClerkProviderWithRoutes() {
  const navigate = useNavigate()

  return (
    <ClerkProvider
      publishableKey={clerkPubKey}
      navigate={(to) => navigate(to)}
      appearance={{
        variables: {
          colorPrimary: '#ff470f',
          colorBackground: '#ffffff',
          colorText: '#151515',
        },
      }}
    >
      <ForceOrganizationSelection />
    </ClerkProvider>
  )
}

function App() {
  return (
    <BrowserRouter>
      <ClerkProviderWithRoutes />
    </BrowserRouter>
  )
}

export default App
