import { Layout } from '@/components/layout'
import { StoreError, useBoundStore } from '@/store'
import { useEffect, useState } from 'react'
import { useToken } from '@/hooks/useToken'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import { CalendarIcon, CopyIcon } from 'lucide-react'
import { Calendar } from '@/components/ui/calendar'
import { format } from 'date-fns'
import { cn } from '@/lib/utils'
import { Checkbox } from '@/components/ui/checkbox'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import { SubmitButton } from '@/components/submitButton'
import { TokenTable } from '@/pages/access-tokens/components/tokenTable'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { toast } from '@/components/ui/use-toast'

export const AccessTokensPage = () => {
  const { getClerkToken } = useToken()
  const scopes = useBoundStore((state) => state.scopes)
  const tokens = useBoundStore((state) => state.tokens)
  const getTokens = useBoundStore((state) => state.getTokens)
  const getScopes = useBoundStore((state) => state.getScopes)
  const createToken = useBoundStore((state) => state.createToken)
  const deleteToken = useBoundStore((state) => state.deleteToken)

  const { handleError } = useErrorHandler()

  const [isSaving, setIsSaving] = useState(false)
  const [latestToken, setLatestToken] = useState<string>()

  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  tomorrow.setHours(0, 0, 0, 0)

  const formSchema = z.object({
    name: z.string(),
    scopes: z.array(z.string()),
    expiryDate: z.date().or(z.null()),
    noExpiry: z.boolean().optional(),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      scopes: [],
      expiryDate: tomorrow,
    },
    mode: 'all',
  })

  const formatScopes = (scopes: string[]) => {
    // groups scopes by context sample: context::scope
    return scopes.reduce((acc: any, scope: string) => {
      const [context, scopeName] = scope.split('::')
      if (!acc[context]) {
        acc[context] = []
      }
      acc[context].push(scopeName)
      return acc
    }, {})
  }

  const handleSubmit = async () => {
    const token = await getClerkToken()
    const data = form.getValues()
    setIsSaving(true)

    if (data.noExpiry) {
      data.expiryDate = null
    }

    delete data.noExpiry

    createToken(token, data, (success: boolean, response: any, error?: StoreError) => {
      setIsSaving(false)

      if (success) {
        form.reset()
        setLatestToken(response.accessToken)
      }
      handleError(
        success,
        () => {
          getTokens(token)
        },
        error
      )
    })
  }

  const copyTokenToClipboard = () => {
    if (!latestToken) return

    navigator.clipboard.writeText(latestToken)

    toast({
      title: 'Copied',
      description: 'The token has been copied to your clipboard.',
    })
  }

  const onDelete = async (id: string) => {
    const token = await getClerkToken()
    deleteToken(token, id, (success: boolean, _response: any, error?: StoreError) => {
      handleError(
        success,
        () => {
          getTokens(token)
        },
        error
      )
    })
  }

  const renderScopeList = (scopes: string[], context: string) => {
    return scopes.map((scope, index) => (
      <li className="mb-2" key={`scope_${index}`}>
        <FormField
          control={form.control}
          name={`scopes`}
          key={`scopes_${index}`}
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0">
              <FormControl>
                <Checkbox
                  checked={field.value?.includes(`${context}::${scope}`)}
                  onCheckedChange={(checked) => {
                    return checked
                      ? field.onChange([...field.value, `${context}::${scope}`])
                      : field.onChange(
                          field.value?.filter((value: string) => value !== `${context}::${scope}`)
                        )
                  }}
                />
              </FormControl>
              <FormLabel>{scope}</FormLabel>
            </FormItem>
          )}
        />
      </li>
    ))
  }

  const renderScopes = (scopes: string[]) => {
    const groupedScopes = formatScopes(scopes)

    // loop through each context and render the context with a list of scopes
    return Object.keys(groupedScopes).map((context, index) => {
      return (
        <ul key={index} className="pl-4 mb-5">
          <li className="text-sm">{context}</li>
          <ul className="pl-4 mt-2">{renderScopeList(groupedScopes[context], context)}</ul>
        </ul>
      )
    })
  }

  useEffect(() => {
    const fetchQuota = async () => {
      const token = await getClerkToken()

      await getScopes(token)
      await getTokens(token)
    }
    fetchQuota()
  }, [getTokens, getScopes, getClerkToken])

  return (
    <Layout>
      <div>
        <h1 className="text-5xl">Access Tokens</h1>

        <div className="flex gap-20 mt-20">
          <div>
            <Card className="w-80">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="font-semibold">New Access Token</CardTitle>
              </CardHeader>
              <CardContent>
                <Form {...form}>
                  <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-8 m-2">
                    <FormField
                      control={form.control}
                      name="name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Name</FormLabel>
                          <FormControl>
                            <Input
                              {...form.register('name', {
                                required: true,
                              })}
                              placeholder="Name"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <div>
                      <h3 className="mb-2">Scopes</h3>
                      {renderScopes(scopes)}
                    </div>

                    <FormField
                      control={form.control}
                      name="expiryDate"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Expires at</FormLabel>
                          <Popover>
                            <PopoverTrigger asChild>
                              <FormControl>
                                <Button
                                  variant={'outline'}
                                  className={cn(
                                    'w-[240px] pl-3 text-left font-normal',
                                    !field.value && 'text-muted-foreground'
                                  )}
                                >
                                  {field.value ? (
                                    format(field.value, 'PPP')
                                  ) : (
                                    <span>Pick a date</span>
                                  )}
                                  <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                </Button>
                              </FormControl>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0" align="start">
                              <Calendar
                                mode="single"
                                selected={field.value ?? undefined}
                                onSelect={field.onChange}
                                disabled={(date) => {
                                  return date < tomorrow
                                }}
                                initialFocus
                              />
                            </PopoverContent>
                          </Popover>

                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name={`noExpiry`}
                      key={`noExpiry`}
                      render={({ field }) => (
                        <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                          <FormControl>
                            <Checkbox
                              onCheckedChange={(checked) => {
                                return checked ? field.onChange(true) : field.onChange(false)
                              }}
                            />
                          </FormControl>
                          <FormLabel>Never expire</FormLabel>
                        </FormItem>
                      )}
                    />

                    <SubmitButton isSaving={isSaving} label="Create token" />
                  </form>
                </Form>
              </CardContent>
            </Card>
          </div>

          <div>
            {latestToken && (
              <Alert className="mb-10">
                <CopyIcon className="h-4 w-4" />
                <AlertTitle>Your Access Token</AlertTitle>
                <AlertDescription>
                  <p>
                    This is your access token. Copy and store it save, you will not be able to see
                    it again.
                  </p>
                  <div className="bg-gray-100 rounded p-3 flex mt-5 justify-between">
                    <pre>{latestToken}</pre>

                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <CopyIcon className="h-4 w-4" onClick={() => copyTokenToClipboard()} />
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Click to copy to clipboard</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                </AlertDescription>
              </Alert>
            )}

            <TokenTable accessTokens={tokens} onDelete={onDelete} />
          </div>
        </div>
      </div>
    </Layout>
  )
}
