import { Button } from '@/components/ui/button'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { AdServerConfigurationType } from '@/pages/adServers'
import { useState } from 'react'

interface Props {
  showConfiguration: (configType: AdServerConfigurationType, configurationId?: string) => void
  buttonText: string
}

export const CreateTypeButton = ({ showConfiguration, buttonText }: Props) => {
  const [selectedType, setSelectedType] = useState<AdServerConfigurationType>('text')

  return (
    <div className="flex">
      <Select
        onValueChange={(value) => {
          const newValue = value as AdServerConfigurationType
          setSelectedType(newValue)
          showConfiguration(newValue)
        }}
      >
        <SelectTrigger className="w-44">
          <SelectValue placeholder="Configuration type" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="text">Simple Text</SelectItem>
          <SelectItem value="placeholder">Placeholder</SelectItem>
          <SelectItem value="conditional">Conditional</SelectItem>
          <SelectItem value="exists">Check Existence</SelectItem>
        </SelectContent>
      </Select>
      <Button
        className="ml-2"
        type="button"
        onClick={() => {
          showConfiguration(selectedType)
        }}
      >
        {buttonText}
      </Button>
    </div>
  )
}
