import { useEffect } from 'react'
import { useBoundStore } from '@/store'
import { PageLoadingIndicator } from '@/components/pageLoadingIndicator'
import { Layout } from '@/components/layout'
import { useNavigate } from 'react-router-dom'

export const SwitchOrg = () => {
  const adMediationReset = useBoundStore((state) => state.adMediationReset)
  const adRulesReset = useBoundStore((state) => state.adRulesReset)
  const adServerReset = useBoundStore((state) => state.adServerReset)
  const dashboardReset = useBoundStore((state) => state.dashboardReset)
  const fastChannelsReset = useBoundStore((state) => state.fastChannelsReset)
  const killSwitchesReset = useBoundStore((state) => state.killSwitchesReset)
  const tagsReset = useBoundStore((state) => state.tagsReset)
  const quotaReset = useBoundStore((state) => state.quotaReset)

  const navigate = useNavigate()

  useEffect(() => {
    const fetchServers = async () => {
      await adMediationReset()
      await adRulesReset()
      await adServerReset()
      await dashboardReset()
      await fastChannelsReset()
      await killSwitchesReset()
      await tagsReset()
      await quotaReset()

      navigate('/')
    }

    fetchServers()
  }, [
    adMediationReset,
    adRulesReset,
    adServerReset,
    dashboardReset,
    fastChannelsReset,
    killSwitchesReset,
    navigate,
    tagsReset,
    quotaReset,
  ])

  return (
    <Layout>
      <PageLoadingIndicator />
    </Layout>
  )
}
