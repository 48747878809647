import { Layout } from '@/components/layout'
import { useEffect, useState } from 'react'
import { AutosaveTextInput } from '@/components/autosaveTextInput'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '@/components/ui/button'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet'
import { PageLoadingIndicator } from '@/components/pageLoadingIndicator'
import { StoreCallback, StoreError, useBoundStore } from '@/store'
import { KillSwitch, KillSwitchDefinition } from '@/pages/killSwitch'
import { DefinitionsTable } from '@/pages/killSwitchEdit/components/definitionsTable'
import { DefinitionForm } from '@/pages/killSwitchEdit/components/definitionForm'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from '@/components/ui/menubar'
import { MenubarToggleEntry } from '@/components/menubarToggleEntry'
import { useToken } from '@/hooks/useToken'

export const KillSwitchEdit = () => {
  const { getClerkToken } = useToken()
  const { id } = useParams()
  const navigate = useNavigate()

  const killSwitches = useBoundStore((state) => state.killSwitches)
  const getKillSwitches = useBoundStore((state) => state.getKillSwitches)
  const updateKillSwitchDefinitions = useBoundStore((state) => state.updateKillSwitchDefinitions)
  const enableKillSwitch = useBoundStore((state) => state.enableKillSwitch)
  const updateKillSwitchName = useBoundStore((state) => state.updateKillSwitchName)
  const deleteKillSwitch = useBoundStore((state) => state.deleteKillSwitch)

  const [killSwitch, setKillSwitch] = useState<KillSwitch>()
  const [etag, setEtag] = useState<string>('')
  const [definitionDialogOpen, setDefinitionDialogOpen] = useState<boolean>(false)
  const [usedDefinitions, setUsedDefinitions] = useState<string[]>([])
  const [definitionsFormValues, setDefinitionsFormValues] = useState<KillSwitchDefinition>()

  const { handleError } = useErrorHandler()

  const onDeleteKillSwitch = async () => {
    const token = await getClerkToken()
    if (!killSwitch?.id) return

    deleteKillSwitch(
      killSwitch?.id,
      token,
      (success: boolean, response: any, error?: StoreError) => {
        handleError(
          success,
          () => {
            getKillSwitches(token)
          },
          error
        )
        if (success) {
          navigate('/kill-switches')
        }
      }
    )
  }

  const onUpdateKillSwitchName = async (
    data: any,
    callback: (result: boolean, value: string) => void
  ) => {
    const token = await getClerkToken()

    if (!killSwitch) {
      return
    }

    updateKillSwitchName(
      killSwitch,
      data.name,
      token,
      etag,
      setEtag,
      (success: boolean, response: any, error?: StoreError) => {
        callback(success, response)
        handleError(
          success,
          () => {
            getKillSwitches(token)
          },
          error
        )
      }
    )
  }

  const onEnableKillSwitch = async (
    data: any,
    callback: (result: boolean, value: boolean) => void
  ) => {
    const token = await getClerkToken()

    if (!killSwitch) {
      return
    }

    enableKillSwitch(
      killSwitch,
      data.enabled,
      token,
      etag,
      setEtag,
      (success: boolean, response: any, error?: StoreError) => {
        callback(success, response)
        handleError(
          success,
          () => {
            getKillSwitches(token)
          },
          error
        )
      }
    )
  }

  const onChangeDefinitions = async (data: any, callback: StoreCallback) => {
    const token = await getClerkToken()

    if (!killSwitch) {
      return
    }

    const filteredDefinitions = killSwitch.definitions.filter(
      (definition) => definition.key !== data.key
    )
    const updatedDefinitions = [...filteredDefinitions, data]

    updateKillSwitchDefinitions(
      killSwitch,
      updatedDefinitions,
      token,
      etag,
      setEtag,
      (success, response, error) => {
        callback(success, response)
        handleError(
          success,
          () => {
            getKillSwitches(token)
          },
          error
        )
      }
    )
  }

  const onDeleteDefinition = async (definitionKey: string) => {
    const token = await getClerkToken()

    if (!killSwitch) {
      return
    }

    const updatedDefinitions = killSwitch.definitions.filter(
      (definition) => definition.key !== definitionKey
    )

    updateKillSwitchDefinitions(
      killSwitch,
      updatedDefinitions,
      token,
      etag,
      setEtag,
      (success, response, error) => {
        handleError(
          success,
          () => {
            getKillSwitches(token)
          },
          error
        )
      }
    )
  }

  const editDefinition = async (definitionKey: string) => {
    const selectedFormValues = killSwitch?.definitions.find(
      (definition) => definition.key === definitionKey
    )
    setDefinitionsFormValues(selectedFormValues)
    showDefinitionDialog()
  }

  const closeDialog = (addAnotherEntry: boolean) => {
    const definitions = killSwitch?.definitions.map((definition) => definition.key) || []
    setUsedDefinitions(definitions)
    setDefinitionsFormValues(undefined)
    setDefinitionDialogOpen(addAnotherEntry)
  }

  const toggleDialog = (open: boolean) => {
    if (!open) {
      closeDialog(false)
      return
    }

    setDefinitionDialogOpen(open)
  }

  const renderDefinitionDialog = () => {
    return (
      <>
        <Sheet open={definitionDialogOpen} onOpenChange={toggleDialog}>
          <SheetContent className="w-[600px]">
            <SheetHeader>
              <SheetTitle>Definition</SheetTitle>
              <SheetDescription className="pb-10">
                Create a new definition for this Kill Switch.
              </SheetDescription>
            </SheetHeader>
            <div>
              <DefinitionForm
                onSaved={closeDialog}
                onCreate={onChangeDefinitions}
                formValues={definitionsFormValues}
                usedDefinitions={usedDefinitions}
              />
            </div>
          </SheetContent>
        </Sheet>
      </>
    )
  }

  const showDefinitionDialog = () => {
    const definitions = killSwitch?.definitions.map((definition) => definition.key) || []
    setUsedDefinitions(definitions)
    setDefinitionDialogOpen(true)
  }

  useEffect(() => {
    const fetchServers = async () => {
      const token = await getClerkToken()
      await getKillSwitches(token)
    }

    fetchServers()
  }, [getKillSwitches, getClerkToken])

  useEffect(() => {
    const selectedKillSwitch = killSwitches.find((killSwitch) => killSwitch.id === id)

    if (!selectedKillSwitch) return
    setKillSwitch(selectedKillSwitch)

    // only set etag on initial load
    if (etag || (!selectedKillSwitch?.revision && selectedKillSwitch?.revision !== 0)) return
    setEtag(`W/"${selectedKillSwitch.revision.toString()}"`)
  }, [killSwitches, setEtag, etag, id])

  if (!killSwitch) {
    return (
      <Layout>
        <PageLoadingIndicator />
      </Layout>
    )
  }

  return (
    <Layout>
      <div>
        <h1 className="text-5xl">Kill Switch</h1>

        <div className="my-10 flex place-items-center justify-items-center">
          <Menubar>
            <MenubarToggleEntry
              enabled={killSwitch.enabled}
              onSubmit={onEnableKillSwitch}
              fieldName="enabled"
              labelTrue="Enabled"
              labelFalse="Disabled"
              label="Status"
            />
            <MenubarMenu>
              <MenubarTrigger>Actions</MenubarTrigger>
              <MenubarContent>
                <MenubarItem onClick={onDeleteKillSwitch}>
                  <span className="text-red-600 ml-1">Delete Kill Switch</span>
                </MenubarItem>
              </MenubarContent>
            </MenubarMenu>
          </Menubar>
        </div>

        <div className="flex gap-20 mt-20">
          <AutosaveTextInput
            onSubmit={onUpdateKillSwitchName}
            value={killSwitch.name}
            fieldName="name"
            description="Enter a Kill Switch name"
            label="Kill Switch name"
            className="basis-1/4"
          />
        </div>
        <div>
          <h2 className="text-2xl mb-5 mt-10">Kill Switch matches</h2>

          {renderDefinitionDialog()}
          <Button onClick={() => showDefinitionDialog()} className="mb-4">
            Create match
          </Button>

          <DefinitionsTable
            killSwitch={killSwitch}
            onDelete={onDeleteDefinition}
            onEdit={editDefinition}
          />
        </div>
      </div>
    </Layout>
  )
}
