import { Layout } from '@/components/layout'
import { useToast } from '@/components/ui/use-toast'
import { useEffect, useState } from 'react'
import { AutosaveTextInput } from '@/components/autosaveTextInput'
import { useNavigate, useParams } from 'react-router-dom'
import { AdRule, AdRuleInventoryShare, KillSwitchDefinition } from '@/pages/adRules'
import { DefinitionsTable } from 'src/pages/adRulesEdit/components/definitionsTable'
import { DefinitionForm } from 'src/pages/adRulesEdit/components/definitionForm'
import { Button } from '@/components/ui/button'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet'
import { PageLoadingIndicator } from '@/components/pageLoadingIndicator'
import { StoreCallback, StoreError, useBoundStore } from '@/store'
import { Progress } from '@/components/ui/progress'
import { InventoryTable } from 'src/pages/adRulesEdit/components/inventoryTable'
import { InventoryForm } from '@/pages/adRulesEdit/components/inventoryForm'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Card, CardContent } from '@/components/ui/card'
import { Badge } from '@/components/ui/badge'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from '@/components/ui/menubar'
import { MenubarToggleEntry } from '@/components/menubarToggleEntry'
import { useToken } from '@/hooks/useToken'

export const AdRulesEdit = () => {
  const { getClerkToken } = useToken()
  const { toast } = useToast()
  const { id } = useParams()
  const { handleError } = useErrorHandler()
  const navigate = useNavigate()

  const adRules = useBoundStore((state) => state.adRules)
  const adServers = useBoundStore((state) => state.adServers)
  const getAdRules = useBoundStore((state) => state.getAdRules)
  const getAdServers = useBoundStore((state) => state.getAdServers)
  const updateAdRuleDefinitions = useBoundStore((state) => state.updateAdRuleDefinitions)
  const updateAdRuleInventory = useBoundStore((state) => state.updateAdRuleInventory)
  const enableAdRule = useBoundStore((state) => state.enableAdRule)
  const updateAdRuleName = useBoundStore((state) => state.updateAdRuleName)
  const deleteAdRule = useBoundStore((state) => state.deleteAdRule)

  const [adRule, setAdRule] = useState<AdRule>()
  const [etag, setEtag] = useState<string>('')
  const [definitionDialogOpen, setDefinitionDialogOpen] = useState<boolean>(false)
  const [inventoryDialogOpen, setInventoryDialogOpen] = useState<boolean>(false)
  const [usedDefinitions, setUsedDefinitions] = useState<string[]>([])
  const [definitionsFormValues, setDefinitionsFormValues] = useState<KillSwitchDefinition>()
  const [inventoryFormValues, setInvetoryFormValues] = useState<AdRuleInventoryShare>()
  const [usedShares, setUsedShares] = useState<number>(0)
  const [inventory, setInventory] = useState<AdRuleInventoryShare[]>([])
  const [inventorySaved, setInventorySaved] = useState<boolean>(true)

  const onUpdateAdRuleName = async (data: any, callback: StoreCallback) => {
    const token = await getClerkToken()

    if (!adRule) {
      return
    }

    updateAdRuleName(
      adRule,
      data.name,
      token,
      etag,
      setEtag,
      (success: boolean, response: any, error?: StoreError) => {
        callback(success, response)

        handleError(
          success,
          () => {
            getAdRules(token)
          },
          error
        )
      }
    )
  }

  const onEnableAdRule = async (data: any, callback: StoreCallback) => {
    const token = await getClerkToken()

    if (!adRule) {
      return
    }

    enableAdRule(adRule, data.enabled, token, etag, setEtag, (success, response, error) => {
      callback(success, response)
      handleError(
        success,
        () => {
          getAdRules(token)
        },
        error
      )
    })
  }

  const changeDefinitions = async (data: any, callback: StoreCallback) => {
    const token = await getClerkToken()

    if (!adRule) {
      return
    }

    const filteredDefinitions = adRule.definitions.filter(
      (definition) => definition.key !== data.key
    )
    const updatedDefinitions = [...filteredDefinitions, data]

    updateAdRuleDefinitions(
      adRule,
      updatedDefinitions,
      token,
      etag,
      setEtag,
      (success: boolean, response: any, error?: StoreError) => {
        callback(success, response)
        handleError(
          success,
          () => {
            getAdRules(token)
          },
          error
        )
      }
    )
  }

  const changeInventory = async (data: any, callback: StoreCallback) => {
    const filteredInventory = inventory.filter((item) => item.id !== data.id)
    const updatedInventory = [...filteredInventory, data]
    setInventory(updatedInventory)

    setInventorySaved(false)
    callback(true, data)
  }

  const deleteInventory = async (deletedInventory: string) => {
    const cleanedInventory = inventory.filter((item) => item.id !== deletedInventory)
    setInventory(cleanedInventory)
    setInventorySaved(false)
  }

  const saveInventory = async () => {
    const token = await getClerkToken()

    if (!adRule) {
      return
    }

    updateAdRuleInventory(
      adRule,
      inventory,
      token,
      etag,
      setEtag,
      (success: boolean, response: any, error?: StoreError) => {
        setInventorySaved(true)

        handleError(
          success,
          () => {
            getAdRules(token)
          },
          error
        )

        toast({
          title: 'Saved',
          description: `Your changes have been saved.`,
        })
      }
    )
  }

  const deleteDefinition = async (definitionKey: string) => {
    const token = await getClerkToken()

    if (!adRule) {
      return
    }

    const updatedDefinitions = adRule.definitions.filter(
      (definition) => definition.key !== definitionKey
    )

    updateAdRuleDefinitions(
      adRule,
      updatedDefinitions,
      token,
      etag,
      setEtag,
      (result: boolean, data: any) => {
        getAdRules(token)
      }
    )
  }

  const editDefinition = async (definitionKey: string) => {
    const selectedFormValues = adRule?.definitions.find(
      (definition) => definition.key === definitionKey
    )
    setDefinitionsFormValues(selectedFormValues)
    showDefinitionDialog()
  }

  const onDeleteAdRule = async () => {
    const token = await getClerkToken()

    if (!adRule?.id) return

    deleteAdRule(adRule?.id, token, (success: boolean, response: any, error?: StoreError) => {
      handleError(
        success,
        () => {
          getAdRules(token)
        },
        error
      )

      if (success) {
        navigate('/ad-rules')
      }
    })
  }

  const closeDialog = (addAnotherEntry: boolean) => {
    const definitions = adRule?.definitions.map((definition) => definition.key) || []
    setUsedDefinitions(definitions)
    setDefinitionDialogOpen(addAnotherEntry)
    setDefinitionsFormValues(undefined)
  }

  const closeInventoryDialog = (addAnotherEntry: boolean) => {
    setInventoryDialogOpen(addAnotherEntry)
    setDefinitionsFormValues(undefined)
  }

  const toggleDefinitionDialog = (open: boolean) => {
    if (!open) {
      closeDialog(false)
      return
    }

    setDefinitionDialogOpen(open)
  }

  const renderDefinitionDialog = () => {
    return (
      <>
        <Sheet open={definitionDialogOpen} onOpenChange={toggleDefinitionDialog}>
          <SheetContent className="w-[600px]">
            <SheetHeader>
              <SheetTitle>Definition</SheetTitle>
              <SheetDescription className="pb-10">
                Create a new definition for this ad rule.
              </SheetDescription>
            </SheetHeader>
            <div>
              <DefinitionForm
                onSaved={closeDialog}
                onCreate={changeDefinitions}
                formValues={definitionsFormValues}
                usedDefinitions={usedDefinitions}
              />
            </div>
          </SheetContent>
        </Sheet>
      </>
    )
  }

  const renderInventoryDialog = (inventoryShare?: number) => {
    return (
      <>
        <Sheet open={inventoryDialogOpen} onOpenChange={setInventoryDialogOpen}>
          <SheetContent className="w-[600px]">
            <SheetHeader>
              <SheetTitle>Inventory Share</SheetTitle>
              <SheetDescription className="pb-10">
                Create a new inventory share for this ad rule.{' '}
                <strong>
                  This inventory share can have a maximum share of {100 - usedShares} percent
                </strong>
              </SheetDescription>
            </SheetHeader>
            <div>
              <InventoryForm
                onCreate={changeInventory}
                onSaved={closeInventoryDialog}
                usedShares={usedShares}
                adServers={adServers}
                formValues={inventoryFormValues}
              />
            </div>
          </SheetContent>
        </Sheet>
      </>
    )
  }

  const showDefinitionDialog = () => {
    const definitions = adRule?.definitions.map((definition) => definition.key) || []
    setUsedDefinitions(definitions)
    setDefinitionDialogOpen(true)
  }

  const showInventoryDialog = (inventoryShare?: string) => {
    const formValues = inventoryShare
      ? inventory.find((item) => item.id === inventoryShare)
      : undefined
    setInvetoryFormValues(formValues)
    setInventoryDialogOpen(true)
  }

  const renderInventoryUnsavedWarning = () => {
    if (inventorySaved) return null
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger className="ml-1">
            <span>
              <Badge variant="destructive">!</Badge>
            </span>
          </TooltipTrigger>
          <TooltipContent>
            <p>Unsaved changes</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
  }

  const renderInventorySaveButton = () => {
    if (usedShares < 100) {
      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger className="ml-1">
              <Button onClick={() => saveInventory()} className="mb-4" disabled={true}>
                Save Inventory Shares
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Inventory shares must be filled a 100 percent to be able to save.</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )
    }

    return (
      <Button onClick={() => saveInventory()} className="mb-4">
        Save Inventory Shares
      </Button>
    )
  }

  useEffect(() => {
    const updatedShares = inventory.map((share) => share.share) || 0
    const updatedUsedShares = updatedShares.reduce((a, b) => a + b, 0)

    setUsedShares(updatedUsedShares)
  }, [inventory])

  useEffect(() => {
    const fetchServers = async () => {
      const token = await getClerkToken()
      await getAdRules(token)
      getAdServers(token)
    }

    fetchServers()
  }, [getAdRules, getClerkToken, getAdServers])

  useEffect(() => {
    const selectedAdRule = adRules.find((adRule) => adRule.id === id)

    if (!selectedAdRule) return
    setAdRule(selectedAdRule)

    if (!selectedAdRule.inventoryShares) return

    if (inventory.length === 0 && selectedAdRule.inventoryShares.length > 0) {
      setInventory(selectedAdRule.inventoryShares)
    }

    // only set etag on initial load
    if (etag || (!selectedAdRule?.revision && selectedAdRule?.revision !== 0)) return
    setEtag(`W/"${selectedAdRule.revision.toString()}"`)
  }, [adRules, setEtag, etag, id, inventory.length])

  if (!adRule) {
    return (
      <Layout>
        <PageLoadingIndicator />
      </Layout>
    )
  }

  return (
    <Layout>
      <div>
        <h1 className="text-5xl">Ad Rule</h1>

        <div className="my-10 flex place-items-center justify-items-center">
          <Menubar>
            <MenubarToggleEntry
              enabled={adRule.enabled}
              onSubmit={onEnableAdRule}
              fieldName="enabled"
              labelTrue="Enabled"
              labelFalse="Disabled"
              label="Status"
            />
            <MenubarMenu>
              <MenubarTrigger>Actions</MenubarTrigger>
              <MenubarContent>
                <MenubarItem onClick={onDeleteAdRule}>
                  <span className="text-red-600 ml-1">Delete Ad Rule</span>
                </MenubarItem>
              </MenubarContent>
            </MenubarMenu>
          </Menubar>
        </div>

        <div className="flex gap-20 mt-20">
          <AutosaveTextInput
            onSubmit={onUpdateAdRuleName}
            value={adRule.name}
            fieldName="name"
            description="Enter a Ad Rule name"
            label="Ad Rule Name"
            className="basis-1/4"
          />
        </div>

        <Tabs defaultValue="share" className="w-[100%] mt-20">
          <TabsList>
            <TabsTrigger value="share">
              Inventory Share {renderInventoryUnsavedWarning()}
            </TabsTrigger>
            <TabsTrigger value="definition">AdRule Matches</TabsTrigger>
          </TabsList>
          <Card>
            <CardContent>
              <TabsContent value="share">
                <h2 className="text-2xl mb-5 mt-10">Inventory Share</h2>

                {renderInventoryDialog()}
                <Button
                  onClick={() => showInventoryDialog()}
                  className="mb-4 mr-2"
                  disabled={usedShares === 100}
                >
                  Add Inventory Share
                </Button>

                {renderInventorySaveButton()}

                <div className="mt-10">
                  <div className="text-sm">{usedShares}% of the shares distributed</div>
                  <div>
                    <Progress value={usedShares} />
                  </div>
                </div>

                <InventoryTable
                  inventory={inventory}
                  adServers={adServers}
                  onDelete={deleteInventory}
                  onEdit={showInventoryDialog}
                />
              </TabsContent>
              <TabsContent value="definition">
                <h2 className="text-2xl mb-5 mt-10">AdRule Matches</h2>

                {renderDefinitionDialog()}
                <Button onClick={() => showDefinitionDialog()} className="mb-4">
                  Create Match
                </Button>

                <DefinitionsTable
                  adRule={adRule}
                  onDelete={deleteDefinition}
                  onEdit={editDefinition}
                />
              </TabsContent>
            </CardContent>
          </Card>
        </Tabs>
      </div>
    </Layout>
  )
}
