import 'unfetch/polyfill'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { HoverCard, HoverCardContent, HoverCardTrigger } from '@/components/ui/hover-card'

export type EpgCatalog = {
  count: number
  lastUpdated?: Date
  status?: string
  error?: string
}

interface DataTileIngestProps {
  title: string
  data?: EpgCatalog
}

export const DataTileIngest = (props: DataTileIngestProps) => {
  const formattedDate = props.data?.lastUpdated?.toLocaleString('de-DE') || ''

  const renderStatusIcon = (status?: string) => {
    switch (status) {
      case 'succeed':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 mt-1 text-green-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        )
      case 'failed':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 mt-1 text-red-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>
        )
      case 'inProgress':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 mt-1 animate-spin text-blue-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>
        )
      default:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 mt-1 text-gray-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
            />
          </svg>
        )
    }
  }

  const renderStatusText = (status?: string) => {
    switch (status) {
      case 'succeed':
        return `The last run at ${formattedDate} was successful`
      case 'failed':
        return `The last run at ${formattedDate} failed: ${props.data?.error}`
      case 'inProgress':
        return 'The ingest is currently running'
      default:
        return 'No ingest yet'
    }
  }

  return (
    <Card className="basis-1/4 relative">
      {props.data?.status === 'failed' && (
        <span className="animate-ping absolute inline-flex -top-1 -right-1 h-3 w-3 rounded-full bg-red-400 opacity-75"></span>
      )}
      <HoverCard>
        <HoverCardTrigger>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">{props.title}</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold grid grid-cols-2 gap-2 align-middle">
              <span>{props.data?.count}</span>
              <span className="justify-self-end">{renderStatusIcon(props.data?.status)}</span>
            </div>
            <div className="text-sm text-gray-500"></div>
          </CardContent>
        </HoverCardTrigger>
        <HoverCardContent className="text-sm">
          {renderStatusText(props.data?.status)}
        </HoverCardContent>
      </HoverCard>
    </Card>
  )
}
