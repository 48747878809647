import { Button } from '@/components/ui/button'
import { Loader2 } from 'lucide-react'

export interface Props {
  isSaving: boolean
  label: string
  hasError?: boolean
}

export const SubmitButton = ({ isSaving, label, hasError }: Props) => {
  if (isSaving) {
    return (
      <Button type="submit" disabled>
        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
        Please wait
      </Button>
    )
  }

  return (
    <Button type="submit" disabled={hasError}>
      {label}
    </Button>
  )
}
