import { Currency, KeyIcon, LogOut, TrendingUpIcon, User } from 'lucide-react'
import { Avatar, AvatarImage } from '@/components/ui/avatar'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Button } from '@/components/ui/button'
import { SignOutButton, useOrganization, useUser } from '@clerk/clerk-react'
import { Link } from 'react-router-dom'
import env from '@beam-australia/react-env'

export function UserNav() {
  const { user, isLoaded } = useUser()
  const { organization } = useOrganization()

  if (!isLoaded) {
    return <></>
  }

  const userEmail = user?.primaryEmailAddress ? user?.primaryEmailAddress.emailAddress : ''
  const userName = user?.fullName ? user?.fullName : 'Unknown'
  const userAvatar = user?.profileImageUrl ? user?.profileImageUrl : ''

  const renderStripeLink = () => {
    if (organization?.publicMetadata.paymentEmailAddress)
      return (
        <Link
          to={`${env('STRIPE_ACCOUNT_URL')}?prefilled_email=${organization?.publicMetadata
            .paymentEmailAddress}`}
        >
          <DropdownMenuItem>
            <Currency className="mr-2 h-4 w-4" />
            Billing
          </DropdownMenuItem>
        </Link>
      )
  }
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative h-8 w-8 rounded-full">
          <Avatar className="h-8 w-8">
            <AvatarImage src={userAvatar} alt="@shadcn" />
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">{userName}</p>
            <p className="text-xs leading-none text-muted-foreground">{userEmail}</p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <Link to="/user/profile">
            <DropdownMenuItem>
              <User className="mr-2 h-4 w-4" />
              Profile
            </DropdownMenuItem>
          </Link>
          <Link to="/access-token">
            <DropdownMenuItem>
              <KeyIcon className="mr-2 h-4 w-4" />
              Access Tokens
            </DropdownMenuItem>
          </Link>
          <DropdownMenuSeparator />
          <Link to="/quota">
            <DropdownMenuItem>
              <TrendingUpIcon className="mr-2 h-4 w-4" />
              Quota &amp; Costs
            </DropdownMenuItem>
          </Link>
          <div>{renderStripeLink()}</div>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem>
          <LogOut className="mr-2 h-4 w-4" />
          <SignOutButton />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
