import { DataTable } from '@/components/dataTable'
import { ColumnDef } from '@tanstack/react-table'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Button } from '@/components/ui/button'
import { ArrowUpDown, MoreHorizontal } from 'lucide-react'
import { Layout } from '@/components/layout'
import { PageLoadingIndicator } from '@/components/pageLoadingIndicator'
import { AccessToken } from '@/store/access-token'
import { Badge } from '@/components/ui/badge'

export interface Props {
  accessTokens: AccessToken[]
  onDelete: (tokenId: string) => void
}

export const TokenTable = ({ accessTokens, onDelete }: Props) => {
  const columns: ColumnDef<AccessToken>[] = [
    {
      accessorKey: 'id',
      header: 'Server Id',
    },
    {
      accessorKey: 'name',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Name
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
    },
    {
      accessorKey: 'scopes',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Scopes
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
      cell: ({ row }) => {
        const scopes = row.getValue('scopes') as string[]

        return scopes.map((scope) => {
          return (
            <span className="font-medium">
              <Badge variant="outline">{scope}</Badge>
            </span>
          )
        })
      },
    },
    {
      accessorKey: 'expiryDate',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Expires
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
      cell: ({ row }) => {
        const expireDate = row.getValue('expiryDate') as string | null

        if (!expireDate) {
          return 'never'
        }

        const date = new Date(expireDate)
        return date.toLocaleDateString()
      },
    },
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => {
        const tokenId: string = row.getValue('id')

        return (
          <div className="flex justify-center">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <DropdownMenuItem
                  className="text-red-500"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onDelete(tokenId)
                  }}
                >
                  Revoke Token
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )
      },
    },
  ]

  if (!accessTokens) {
    return (
      <Layout>
        <PageLoadingIndicator />
      </Layout>
    )
  }

  return <DataTable columns={columns} data={accessTokens} hideColumnButton={true} />
}
