import { Layout } from '@/components/layout'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AutosaveTextInput } from 'src/components/autosaveTextInput'
import { StoreCallback, StoreError, useBoundStore } from '@/store'
import { ConfigurationTable } from '@/pages/adServerEdit/components/configurationTable'
import { Loader2 } from 'lucide-react'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from '@/components/ui/menubar'
import { MenubarToggleEntry } from '@/components/menubarToggleEntry'
import { useToken } from '@/hooks/useToken'

export const AdServerEdit = () => {
  const { getClerkToken } = useToken()
  const { id } = useParams()
  const { handleError } = useErrorHandler()
  const navigate = useNavigate()

  const adServer = useBoundStore((state) => state.adServer)
  const adServers = useBoundStore((state) => state.adServers)
  const getAdServerById = useBoundStore((state) => state.getAdServerById)
  const getAdServers = useBoundStore((state) => state.getAdServers)
  const enableAdServer = useBoundStore((state) => state.enableAdServer)
  const setDefault = useBoundStore((state) => state.setDefault)
  const updateAdServerName = useBoundStore((state) => state.updateAdServerName)
  const updateAdServerBaseUrl = useBoundStore((state) => state.updateAdServerBaseUrl)
  const addAdServerConfiguration = useBoundStore((state) => state.addAdServerConfiguration)
  const updateAdServerConfiguration = useBoundStore((state) => state.updateAdServerConfiguration)
  const deleteAdServerConfiguration = useBoundStore((state) => state.deleteAdServerConfiguration)
  const deleteAdServer = useBoundStore((state) => state.deleteServer)

  const [etag, setEtag] = useState<string>('')

  const onEnableAdServer = async (data: any, callback: StoreCallback) => {
    if (!adServer) return
    const token = await getClerkToken()
    enableAdServer(adServer, data.enabled, token, etag, setEtag, (success, response, error) => {
      callback(success, response)
      handleError(
        success,
        () => {
          getAdServers(token)
        },
        error
      )
    })
  }

  const onSetDefault = async (data: any, callback: StoreCallback) => {
    if (!adServer) return
    const token = await getClerkToken()
    await setDefault(adServer, data.isDefault, token, etag, setEtag, (success, response, error) => {
      callback(success, response)
      handleError(
        success,
        () => {
          getAdServers(token)
        },
        error
      )
    })
  }

  const onUpdateAdServername = async (data: any, callback: StoreCallback) => {
    if (!adServer) return
    const token = await getClerkToken()
    updateAdServerName(adServer, data.name, token, etag, setEtag, (success, response, error) => {
      callback(success, response, error)
      handleError(
        success,
        () => {
          getAdServers(token)
        },
        error
      )
    })
  }

  const onUpdateAdServerBaseUrl = async (data: any, callback: StoreCallback) => {
    if (!adServer) return
    const token = await getClerkToken()
    updateAdServerBaseUrl(
      adServer,
      data.baseUrl,
      token,
      etag,
      setEtag,
      (success, response, error) => {
        callback(success, response, error)
        handleError(
          success,
          () => {
            getAdServers(token)
          },
          error
        )
      }
    )
  }

  const onAddAdServerConfiguration = async (
    data: any,
    callback: (result: boolean, value: string) => void
  ) => {
    if (!adServer) return
    const token = await getClerkToken()
    addAdServerConfiguration(adServer, data, token, etag, setEtag, (success, response, error) => {
      callback(success, response)
      handleError(
        success,
        () => {
          getAdServers(token)
        },
        error
      )
    })
  }

  const onUpdateAdServerConfiguration = async (
    data: any,
    callback: (result: boolean, value: string) => void,
    configurationId: string
  ) => {
    if (!adServer) return
    const token = await getClerkToken()
    updateAdServerConfiguration(
      adServer,
      data,
      configurationId,
      token,
      etag,
      setEtag,
      (success, response, error) => {
        callback(success, response)
        handleError(
          success,
          () => {
            getAdServers(token)
          },
          error
        )
      }
    )
  }

  const onDeleteAdServerConfiguration = async (
    configurationId: string,
    callback: (result: boolean) => void
  ) => {
    if (!adServer) return
    const token = await getClerkToken()
    deleteAdServerConfiguration(
      adServer,
      configurationId,
      token,
      etag,
      setEtag,
      (success, response, error) => {
        callback(success)
        handleError(
          success,
          () => {
            getAdServers(token)
          },
          error
        )
      }
    )
  }

  const onDeleteServer = async () => {
    const token = await getClerkToken()

    if (!adServer?.id) return

    deleteAdServer(adServer?.id, token, (success: boolean, response: any, error?: StoreError) => {
      handleError(
        success,
        () => {
          getAdServers(token)
        },
        error
      )

      if (success) {
        navigate('/ad-servers')
      }
    })
  }

  useEffect(() => {
    const fetchServers = async () => {
      const token = await getClerkToken()
      await getAdServers(token)
    }

    fetchServers()
  }, [getClerkToken, id, getAdServers])

  useEffect(() => {
    if (id && adServers) {
      getAdServerById(id)
    }
  }, [getAdServerById, id, adServers])

  useEffect(() => {
    if (etag || (!adServer?.revision && adServer?.revision !== 0)) return
    setEtag(`W/"${adServer?.revision.toString()}"`)
  }, [etag, adServer, adServers])

  if (!adServer)
    return (
      <Layout>
        <div>
          <Loader2 className="mr-2 h-4 w-4 animate-spin" /> Loading...
        </div>
      </Layout>
    )

  return (
    <Layout>
      <div>
        <h1 className="text-5xl mb-5">Ad Server Configuration</h1>

        <div className="my-10 flex place-items-center justify-items-center">
          <Menubar>
            <MenubarToggleEntry
              enabled={adServer.enabled}
              onSubmit={onEnableAdServer}
              fieldName="enabled"
              labelTrue="Enabled"
              labelFalse="Disabled"
              label="Status"
            />
            <MenubarToggleEntry
              enabled={adServer.isDefault}
              onSubmit={onSetDefault}
              fieldName="isDefault"
              labelTrue="Yes"
              labelFalse="No"
              labelEnable="Set as default"
              labelDisable="Remove as default"
              label="Default Ad Server"
            />
            <MenubarMenu>
              <MenubarTrigger>Actions</MenubarTrigger>
              <MenubarContent>
                <MenubarItem onClick={onDeleteServer}>
                  <span className="text-red-600 ml-1">Delete Ad Server</span>
                </MenubarItem>
              </MenubarContent>
            </MenubarMenu>
          </Menubar>
        </div>

        <div className="flex gap-20 mt-20">
          <AutosaveTextInput
            onSubmit={onUpdateAdServername}
            value={adServer.name}
            fieldName="name"
            description="Enter a server name"
            label="Server name"
            className="basis-1/4"
          />

          <AutosaveTextInput
            onSubmit={onUpdateAdServerBaseUrl}
            value={adServer.baseUrl}
            fieldName="baseUrl"
            description="Enter the base URL"
            label="Base URL"
            className="basis-1/2"
          />
        </div>
        <div className="mt-20">
          <h2 className="text-2xl mb-5">Configurations</h2>
          <ConfigurationTable
            adServer={adServer}
            updateAdServerConfiguration={onUpdateAdServerConfiguration}
            addAdServerConfiguration={onAddAdServerConfiguration}
            deleteAdServerConfiguration={onDeleteAdServerConfiguration}
          />
        </div>
      </div>
    </Layout>
  )
}
