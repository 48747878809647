import { useForm } from 'react-hook-form'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form'
import { z } from 'zod'
import { useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from '@/components/ui/use-toast'
import { DialogFooter } from '@/components/ui/dialog'
import { SubmitButton } from '@/components/submitButton'
import { AdRuleInventoryShare } from '@/pages/adRules'
import { Input } from '@/components/ui/input'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { Check, ChevronsUpDown } from 'lucide-react'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@/components/ui/command'
import { AdServer } from '@/pages/adServers'
import { v4 as uuidv4 } from 'uuid'
import { CreateAnotherEntryToggle } from '@/components/createAnotherEntryToogle'
import { ReactSortable } from 'react-sortablejs'
import { Label } from '@/components/ui/label'
import { FormFieldError } from '@/components/formFieldError'

interface Props {
  usedShares: number
  formValues?: AdRuleInventoryShare
  adServers: AdServer[]
  onCreate: (data: any, callback: (result: boolean, value: string) => void) => void
  onSaved: (addAnotherEntry: boolean) => void
}

type SortableType = {
  id: string
  name: string
}

export const InventoryForm = ({ formValues, usedShares, adServers, onCreate, onSaved }: Props) => {
  const [isSaving, setIsSaving] = useState(false)
  const [addAnotherEntry, setAddAnotherEntry] = useState(false)
  const [availShares, setAvailShares] = useState<number>(0)
  const [selectedServers, setSelectedServers] = useState<string[]>([])
  const [sortedServers, setSortedServers] = useState<SortableType[]>([])

  const formSchema = z.object({
    id: z.string().optional(),
    waterfall: z.array(z.string()),
    share: z.number().min(1).max(availShares),
  })

  const form: any = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      id: formValues?.id ?? '',
      waterfall: formValues?.waterfall ?? [],
      share: formValues?.share ?? 0,
    },
    mode: 'all',
  })

  const formCallback = (result: boolean, _value: string) => {
    setIsSaving(false)
    if (result) {
      onSaved(addAnotherEntry)
      form.setFocus('key')
      form.reset()
      setSelectedServers([])
    } else {
      toast({
        variant: 'destructive',
        title: 'Failed',
        description: `Could not save the configuration.`,
      })
    }
  }

  const submitForm = (data: AdRuleInventoryShare) => {
    if (data.waterfall.length === 0) {
      toast({
        variant: 'destructive',
        title: 'Failed',
        description: `Please select at least one server.`,
      })
      return
    }

    if (!data.id) {
      data.id = uuidv4()
    }

    setIsSaving(true)
    onCreate(data, formCallback)
  }

  const toggleAddAnotherEntry = () => {
    setAddAnotherEntry(!addAnotherEntry)
  }

  const updateWaterfallValues = (value: string) => {
    const updatedValues = selectedServers.includes(value)
      ? selectedServers.filter((server: string) => server !== value)
      : [...selectedServers, value]
    setSelectedServers(updatedValues)

    form.setValue('waterfall', updatedValues)
  }

  const isServerSelected = (serverId: string): boolean => {
    return selectedServers.includes(serverId)
  }

  useEffect(() => {
    setAvailShares(100 - usedShares)
  }, [usedShares])

  useEffect(() => {
    if (formValues) {
      setSelectedServers(formValues.waterfall)
      setAvailShares(100 - usedShares + formValues.share)
    }
  }, [formValues, usedShares])

  useEffect(() => {
    if (sortedServers.length === 0) {
      setSortedServers(
        selectedServers.map((server) => {
          return {
            id: server,
            name: adServers.find((adServer) => adServer.id === server)?.name ?? '',
          }
        })
      )
      return
    }

    const existingSortedServers = sortedServers

    const currentlySelectedServers = adServers
      .filter((server) => selectedServers.includes(server.id))
      .map((server) => {
        return {
          id: server.id,
          name: server.name,
        }
      })

    // Step 1: Add entries from selectedServers to sortedServers if not already present
    currentlySelectedServers.forEach((selectedServer) => {
      const foundIndex = existingSortedServers.findIndex(
        (sortedServer) => sortedServer.id === selectedServer.id
      )

      if (foundIndex === -1) {
        existingSortedServers.push(selectedServer)
      }
    })

    // Step 2: Remove entries from existingSortedServers that are not in currentlySelectedServers
    const newSortedServers = existingSortedServers.filter((sortedServer) => {
      return currentlySelectedServers.some(
        (selectedServer) => selectedServer.id === sortedServer.id
      )
    })

    setSortedServers(newSortedServers)
  }, [selectedServers, adServers, sortedServers])

  useEffect(() => {
    const sortedServerIds = sortedServers.map((server) => server.id)
    form.setValue('waterfall', sortedServerIds)
  }, [form, sortedServers])

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(submitForm)} className="space-y-8">
          <FormField
            control={form.control}
            name="share"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Values</FormLabel>
                <FormControl>
                  <Input
                    {...form.register('share', {
                      required: true,
                      valueAsNumber: true,
                    })}
                    placeholder="0"
                    type="number"
                    min={0}
                    max={availShares}
                  />
                </FormControl>
                <FormDescription>
                  <FormFieldError>{availShares}% shares left</FormFieldError>
                </FormDescription>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="waterfall"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>AdServers</FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant="outline"
                        role="combobox"
                        className={cn(
                          'w-[200px] justify-between',
                          !field.value && 'text-muted-foreground'
                        )}
                      >
                        {selectedServers.length > 0
                          ? `${selectedServers.length} servers selected`
                          : 'Select servers'}
                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-[200px] p-0">
                    <Command>
                      <CommandInput placeholder="Search for server..." />
                      <CommandEmpty>No servers found.</CommandEmpty>
                      <CommandGroup>
                        {adServers.map((server) => (
                          <CommandItem
                            value={server.id}
                            key={server.id}
                            onSelect={(value) => {
                              updateWaterfallValues(server.id)
                            }}
                          >
                            <Check
                              className={cn(
                                'mr-2 h-4 w-4',
                                isServerSelected(server.id) ? 'opacity-100' : 'opacity-0'
                              )}
                            />
                            {server.name}
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </Command>
                  </PopoverContent>
                </Popover>
                <FormDescription>
                  <FormFieldError>
                    Select the AdServers you want to use for this inventory.
                  </FormFieldError>
                </FormDescription>
              </FormItem>
            )}
          />

          {sortedServers.length > 0 && (
            <FormItem>
              <Label>Ad Server Order</Label>
              <ReactSortable list={sortedServers} setList={setSortedServers}>
                {sortedServers.map((item) => (
                  <div key={item.id}>
                    <div className="rounded-md border border-input bg-transparent px-3 py-2 cursor-move">
                      {item.name}
                    </div>
                  </div>
                ))}
              </ReactSortable>
              <FormDescription>
                Drag and drop the AdServers to change the order in which they are called.
              </FormDescription>
            </FormItem>
          )}

          <DialogFooter>
            <CreateAnotherEntryToggle
              addAnotherEntry={addAnotherEntry}
              toggleAddAnotherEntry={toggleAddAnotherEntry}
            />
            <SubmitButton isSaving={isSaving} label="Save" />
          </DialogFooter>
        </form>
      </Form>
    </>
  )
}
