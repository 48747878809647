import { MenubarContent, MenubarItem, MenubarMenu, MenubarTrigger } from '@/components/ui/menubar'
import { StoreCallback } from '@/store'
import { Loader2 } from 'lucide-react'
import { useState } from 'react'

interface Props {
  enabled: boolean
  onSubmit: (data: any, callback: StoreCallback) => void
  fieldName: string
  labelTrue: string
  labelFalse: string
  labelEnable?: string
  labelDisable?: string
  label: string
}

export const MenubarToggleEntry = ({
  enabled,
  label,
  labelTrue,
  labelFalse,
  onSubmit,
  fieldName,
  labelEnable,
  labelDisable,
}: Props) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const toggleStatus = async (enabled: boolean) => {
    setIsProcessing(true)
    onSubmit({ [fieldName]: enabled }, () => {
      setIsProcessing(false)
    })
  }

  const renderStatus = () => {
    if (isProcessing) {
      return <Loader2 className="w-5 h-5 text-gray-300 animate-spin" />
    }

    if (enabled) {
      return <span className="text-green-600 ml-1">{labelTrue}</span>
    }

    return <span className="text-red-600 ml-1">{labelFalse}</span>
  }

  return (
    <MenubarMenu>
      <MenubarTrigger>
        {label}:{renderStatus()}
      </MenubarTrigger>
      <MenubarContent>
        <MenubarItem
          onClick={() => {
            toggleStatus(true)
          }}
          disabled={enabled}
        >
          {labelEnable || 'Enable'}
        </MenubarItem>
        <MenubarItem
          onClick={() => {
            toggleStatus(false)
          }}
          disabled={!enabled}
        >
          {labelDisable || 'Disable'}
        </MenubarItem>
      </MenubarContent>
    </MenubarMenu>
  )
}
