import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { useErrorHandler } from '@/hooks/useErrorHandler'

export const StoreErrorDialog = () => {
  const { visible, hideVersionErrorDialog, runAction } = useErrorHandler()

  return (
    <AlertDialog open={visible}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Version conflict</AlertDialogTitle>
          <AlertDialogDescription>
            Your changes are in conflict with the latest version on the server. Maybe another user
            has made changes in the meantime. You should fetch the latest version and try again.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={hideVersionErrorDialog}>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={runAction}>Fetch latest version</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
