import { useFieldArray, UseFormReturn } from 'react-hook-form'
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { z } from 'zod'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { MinusSquareIcon, PlusSquareIcon } from 'lucide-react'
import { AdServerConfigurationConditional } from '@/pages/adServers'
import { PlaceholderAutocomplete } from '@/components/placeholderAutocomplete'

interface Props {
  formValues?: AdServerConfigurationConditional
  form: UseFormReturn<
    {
      type: string
      key: string
      targetKey: string
      defaultValue?: any
      mappings: { key: string; value: string }[]
    },
    any,
    undefined
  >
}

type FormValues = {
  key: string
  targetKey: string
  defaultValue?: any
  mappings: { key: string; value: string }[]
}

export const formTypeConditionalSchema = z.object({
  type: z.string(),
  key: z.string(),
  targetKey: z.string(),
  defaultValue: z.any(),
  mappings: z.array(
    z.object({
      key: z.string(),
      value: z.string(),
    })
  ),
})

export const getFormTypeConditionalDefaults = (formValues?: AdServerConfigurationConditional) => {
  return {
    type: 'conditional',
    key: formValues?.config.key ?? '',
    targetKey: formValues?.config.targetKey ?? '',
    defaultValue: formValues?.config.default ?? '',
    mappings: formValues?.config.valueMapping ?? [],
  }
}

export const setFormTypeConditionalData = (newFormValues: FormValues) => {
  return {
    config: {
      key: newFormValues.key,
      targetKey: newFormValues.targetKey,
      default: newFormValues.defaultValue ? newFormValues.defaultValue : null,
      valueMapping: newFormValues.mappings.map((mapping) => {
        return {
          key: mapping.key,
          value: mapping.value,
        }
      }),
    },
    type: 'conditional',
  }
}

export const FormTypeConditional = ({ form }: Props) => {
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'mappings',
  })

  return (
    <>
      <PlaceholderAutocomplete
        form={form}
        context="adServer"
        fieldName="key"
        fieldLabel="Key"
        fieldDescription="This system will check if the key exists. Use { } to reference a placeholder. For example {gdpr_consent}"
      />
      <FormField
        control={form.control}
        name="targetKey"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Target Key</FormLabel>
            <FormControl>
              <Input
                {...form.register('targetKey', {
                  required: true,
                })}
                placeholder="Key"
              />
            </FormControl>
            <FormDescription>
              The URL key, for example <code>gdpr_consent</code>
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />

      <div>
        <h3>Mapping</h3>
        <Button
          variant="secondary"
          type="button"
          onClick={() => {
            append({ key: '', value: '' })
          }}
        >
          <PlusSquareIcon />
        </Button>
      </div>

      {fields.map((field, index) => {
        return (
          <div className="grid auto-cols-max gap-10" key={index}>
            <FormField
              control={form.control}
              name={`mappings.${index}.key`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Mapping Key</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="valueMappingKeys"
                      {...form.register(`mappings.${index}.key` as const, {
                        required: true,
                      })}
                    />
                  </FormControl>
                  <FormDescription>valueMappingKeys</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={`mappings.${index}.value`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Mapping value</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="valueMappingValues"
                      {...form.register(`mappings.${index}.value` as const, {
                        required: true,
                      })}
                    />
                  </FormControl>
                  <FormDescription>valueMappingValues</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              variant="secondary"
              type="button"
              onClick={() => {
                remove(index)
              }}
            >
              <MinusSquareIcon />
            </Button>
            {index < fields.length && <div className="w-full h-px bg-gray-200" />}
          </div>
        )
      })}

      <FormField
        control={form.control}
        name="defaultValue"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Default value</FormLabel>
            <FormControl>
              <Input
                {...form.register('defaultValue', {
                  required: true,
                })}
                placeholder="Value"
              />
            </FormControl>
            <FormDescription>
              If the lookup key is not found, this value will be used. Leave empty to remove the
              key.
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  )
}
