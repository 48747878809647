import { RedirectToSignIn, UserProfile, useUser } from '@clerk/clerk-react'
import { Layout } from '@/components/layout'

export const UserProfilePage = () => {
  const { isLoaded, isSignedIn } = useUser()

  // In case the user signs out while on the page.
  if (!isLoaded || !isSignedIn) {
    console.log('User is not signed in. Redirecting to landing page.')
    return <RedirectToSignIn />
  }

  return (
    <Layout>
      <div>
        <h1 className="text-5xl">Profile</h1>

        <UserProfile />
      </div>
    </Layout>
  )
}
