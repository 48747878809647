import { StateCreator } from 'zustand'
import { apiGet, apiPost } from '@/lib/api'

export interface TagSlice {
  tags: {
    revision: string
    tags: Tags[]
  }
  setTags: (tags: Tags[], revision?: string) => void
  getTags: (token: string | null) => void
  addTag: (tag: string, context: string, token: string | null, callback: any) => void
  tagsReset: () => void
}

export type Tags = {
  systemTags: string[]
  userTags: string[]
  context: string
}

export const createTagsSlice: StateCreator<TagSlice, [], [], TagSlice> = (set, get) => ({
  tags: { revision: '', tags: [] },
  getTags: (token) => {
    apiGet({
      token,
      path: `/setting/tag`,
      callback: (success, response) => {
        if (success) {
          get().setTags(response.tags, `W/"${response.revision.toString()}"`)
        } else {
          throw new Error('Could not get tags. Please try again later.')
        }
      },
    })
  },
  setTags: (tags, revision) => {
    const state = get()

    set({
      tags: {
        tags: tags,
        revision: revision || state.tags.revision,
      },
    })
  },
  addTag: (newTag, context, token, callback) => {
    const data = {
      context: context,
      tag: newTag,
    }

    const etag = get().tags.revision

    apiPost({
      path: `/setting/tag`,
      data,
      etag,
      setEtag: (etag) => {
        get().setTags(get().tags.tags, etag)
      },
      callback: (success, response) => {
        const state = get()

        if (state.tags.tags.filter((tag) => tag.context === context)[0].userTags.includes(newTag)) {
          callback(success, state.tags)
        }

        if (
          state.tags.tags.filter((tag) => tag.context === context)[0].systemTags.includes(newTag)
        ) {
          callback(success, state.tags)
        }

        const newTagList = state.tags
        newTagList.tags.filter((tag) => tag.context === context)[0].userTags.push(newTag)

        const uniqueTagList = JSON.parse(JSON.stringify(newTagList))

        callback(success, newTagList)
        get().setTags(uniqueTagList.tags)
      },
      token,
    })
  },
  tagsReset: () => set({ tags: { revision: '', tags: [] } }),
})
