import { StateCreator } from 'zustand'
import { apiDelete, apiGet, apiPut } from '@/lib/api'
import { convertApiToStoreError, StoreApiToken, StoreCallback } from '@/store/index'
import { KillSwitch, KillSwitchDefinition } from '@/pages/killSwitch'

export interface KillSwitchSlice {
  killSwitches: KillSwitch[]
  getKillSwitches: (token: StoreApiToken, callback?: StoreCallback) => Promise<void>
  createKillSwitch: (killSwitch: KillSwitch) => void
  setKillSwitch: (killSwitch: KillSwitch) => void
  deleteKillSwitch: (killSwitchId: string, token: StoreApiToken, callback?: StoreCallback) => void
  setKillSwitchList: (killSwitches: KillSwitch[]) => void
  updateKillSwitchDefinitions: (
    killSwitch: KillSwitch,
    definitions: KillSwitchDefinition[],
    token: StoreApiToken,
    etag: string,
    setEtag: any,
    callback: StoreCallback
  ) => void
  updateKillSwitchName: (
    killSwitchId: KillSwitch,
    name: string,
    token: StoreApiToken,
    etag: string,
    setEtag: any,
    callback: StoreCallback
  ) => void
  enableKillSwitch: (
    killSwitchId: KillSwitch,
    enabled: boolean,
    token: StoreApiToken,
    etag: string,
    setEtag: any,
    callback: StoreCallback
  ) => void
  killSwitchesReset: () => void
}

export const createKillSwitchSlice: StateCreator<KillSwitchSlice, [], [], KillSwitchSlice> = (
  set,
  get
) => ({
  killSwitches: [],
  createKillSwitch: (killSwitch) => {
    get().setKillSwitch(killSwitch)
  },
  setKillSwitch: (killSwitch) => {
    const state = get()
    const cleanedKillSwitches = state.killSwitches.filter((rule) => rule.id !== killSwitch.id)
    const updatedKillSwitches = [...cleanedKillSwitches, killSwitch]

    set({ killSwitches: updatedKillSwitches })
  },
  deleteKillSwitch: (killSwitchId, token, callback) => {
    const state = get()
    const affectedKillSwitch = state.killSwitches.find((rule) => rule.id === killSwitchId)

    apiDelete({
      path: `/setting/kill-switch/${killSwitchId}`,
      etag: `W/"${affectedKillSwitch?.revision || 0}"`,
      token,
      callback: (success, response) => {
        if (success) {
          const killSwitches = state.killSwitches.filter((rule) => rule.id !== killSwitchId)
          set({ killSwitches: killSwitches })

          if (callback) {
            callback(true, null)
          }
        } else {
          if (callback) {
            callback(false, null, convertApiToStoreError(response))
          }
        }
      },
    })
  },
  setKillSwitchList: (adKillSwitches) => set((state) => ({ killSwitches: adKillSwitches })),
  getKillSwitches: async (token, callback) => {
    apiGet({
      token,
      path: `/setting/kill-switch`,
      callback: (success, response) => {
        if (success) {
          set({ killSwitches: response })
          if (callback) {
            callback(true, null)
          }
        } else {
          if (callback) {
            callback(false, null, {
              title: 'Error',
              detail: 'Could not get kill switches. Please try again later.',
              type: 'api-error',
            })
          }
        }
      },
    })
  },
  updateKillSwitchDefinitions: async (killSwitch, definitions, token, etag, setEtag, callback) => {
    apiPut({
      path: `/setting/kill-switch/${killSwitch.id}/change-definitions`,
      data: definitions,
      etag,
      setEtag,
      token,
      callback: (success, response) => {
        if (success) {
          const updatedKillSwitch = { ...killSwitch, definitions: response.definitions }
          get().setKillSwitch(updatedKillSwitch)
          callback(success, response)
        } else {
          if (callback) {
            callback(false, null, convertApiToStoreError(response))
          }
        }
      },
    })
  },
  updateKillSwitchName: async (killSwitch, name, token, etag, setEtag, callback) => {
    apiPut({
      path: `/setting/kill-switch/${killSwitch.id}/change-name`,
      data: {
        name,
      },
      etag,
      setEtag,
      token,
      callback: (success, response) => {
        if (success) {
          const updatedKillSwitch = { ...killSwitch, name: response.name }
          get().setKillSwitch(updatedKillSwitch)
          callback(success, response)
        } else {
          if (callback) {
            callback(false, null, convertApiToStoreError(response))
          }
        }
      },
    })
  },
  enableKillSwitch: async (killSwitch, enabled, token, etag, setEtag, callback) => {
    const apiVerb = enabled ? 'enable' : 'disable'
    apiPut({
      path: `/setting/kill-switch/${killSwitch.id}/${apiVerb}`,
      data: {},
      etag,
      setEtag,
      token,
      callback: (success, response) => {
        if (success) {
          const updatedKillSwitch = { ...killSwitch, enabled: response.enabled }
          get().setKillSwitch(updatedKillSwitch)

          if (callback) {
            callback(success, response)
          }
        } else {
          if (callback) {
            callback(false, null, convertApiToStoreError(response))
          }
        }
      },
    })
  },
  killSwitchesReset: () => set({ killSwitches: [] }),
})
