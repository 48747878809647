import { create } from 'zustand'
import { AdServerSlice, createAdServerSlice } from '@/store/adServer'
import { createTagsSlice, TagSlice } from '@/store/tags'
import { AdRuleSlice, createAdRuleSlice } from '@/store/adRules'
import { createKillSwitchSlice, KillSwitchSlice } from '@/store/killSwitches'
import { AdMediationSlice, createAdMediationSlice } from '@/store/adMediation'
import { createFastChannelSlice, FastChannelSlice } from '@/store/fastChannels'
import { createDashboardSlice, DashboardSlice } from '@/store/dashboard'
import { BillingSlice, createBillingSlice } from '@/store/billing'
import { AccessTokenSlice, createAccessTokenSlice } from '@/store/access-token'

export type StoreCallback = (success: boolean, response: any, error?: StoreError) => void

export type StoreError = {
  type: string
  title: string
  detail: string
}

export type StoreApiToken = string | null
export const useBoundStore = create<
  AdServerSlice &
    TagSlice &
    AdRuleSlice &
    KillSwitchSlice &
    AdMediationSlice &
    FastChannelSlice &
    DashboardSlice &
    BillingSlice &
    AccessTokenSlice
>()((...a) => ({
  ...createAdServerSlice(...a),
  ...createTagsSlice(...a),
  ...createAdRuleSlice(...a),
  ...createKillSwitchSlice(...a),
  ...createAdMediationSlice(...a),
  ...createFastChannelSlice(...a),
  ...createDashboardSlice(...a),
  ...createBillingSlice(...a),
  ...createAccessTokenSlice(...a),
}))

export const convertApiToStoreError = (error: any): StoreError => {
  if (error.status === 422 && error.invalidParameter['if-match']) {
    return {
      type: 'version-error',
      title: 'Data consistency conflict',
      detail: 'You are trying to update an outdated version of the data.',
    }
  }

  return {
    type: error.type,
    title: error.title,
    detail: error.detail,
  }
}
