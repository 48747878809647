import { useFormField } from '@/components/ui/form'
import { cn } from '@/lib/utils'
import React from 'react'

export const FormFieldError = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField()
  const body = error ? String(error?.message) : children

  if (!body) {
    return null
  }

  const statusClassName = error ? 'text-destructive' : 'text-gray-500'
  return (
    <p
      ref={ref}
      id={formMessageId}
      className={cn('text-sm font-medium', statusClassName, className)}
      {...props}
    >
      {body}
    </p>
  )
})
