import { useAuth } from '@clerk/clerk-react'
import { useCallback } from 'react'

export function useToken() {
  const { getToken } = useAuth()

  const getClerkToken = useCallback(
    async function () {
      return await getToken({ template: 'rapidstream' })
    },
    [getToken]
  )

  return { getClerkToken }
}
