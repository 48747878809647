import { FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form'
import { useBoundStore } from '@/store'
import { useEffect, useState } from 'react'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { Checkbox } from '@/components/ui/checkbox'
import { useToken } from '@/hooks/useToken'

interface Props {
  form: any
  context: string
  fieldName: string
  fieldLabel: string
  disabledTags?: string[]
}

export type ComboboxEntry = {
  label: string
  value: string
  group?: string
}

export const TagCheckboxGroup = ({ form, context, fieldName, fieldLabel, disabledTags }: Props) => {
  const { getClerkToken } = useToken()

  const tags = useBoundStore((state) => state.tags)
  const getTags = useBoundStore((state) => state.getTags)

  const [formValues, setFormValues] = useState<string[]>([])
  const [tagData, setTagData] = useState<ComboboxEntry[]>()

  const onToggleCheckbox = (value: string) => {
    const newFormValue = formValues.includes(value)
      ? formValues.filter((item: string) => item !== value)
      : [...formValues, value]

    setFormValues(newFormValue)
    form.setValue(fieldName, newFormValue)
  }

  const isCheckboxChecked = (value: string) => {
    return formValues?.includes(value)
  }

  useEffect(() => {
    const fetchServers = async () => {
      const token = await getClerkToken()
      getTags(token)
    }

    fetchServers()
  }, [getClerkToken, getTags])

  useEffect(() => {
    const tagsByContext = tags.tags.filter((tag) => tag.context === context)[0]

    if (!tagsByContext) {
      return
    }

    const systemTags = tagsByContext.systemTags.filter((tag) => !disabledTags?.includes(tag))

    const newSystemTagData = systemTags.map((tag) => {
      return {
        label: tag,
        value: tag,
        group: 'system',
      }
    })

    setTagData(newSystemTagData)
  }, [setTagData, tags, context, disabledTags])

  useEffect(() => {
    const initialValues = form.getValues()
    if (initialValues[fieldName]) {
      setFormValues(initialValues[fieldName])
    }
  }, [setFormValues, form, fieldName])

  if (!tagData) {
    return (
      <Alert variant="destructive">
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>
          We could not load the needed data for the {fieldLabel} field. Please try again.
        </AlertDescription>
      </Alert>
    )
  }

  const renderCheckboxes = () => {
    return tagData
      .filter((tag) => tag.group === 'system')
      .map((tag, index) => (
        <FormField
          control={form.control}
          name={`useragent.${index}`}
          key={index}
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0">
              <FormControl>
                <Checkbox
                  checked={isCheckboxChecked(tag.value)}
                  onCheckedChange={() => {
                    onToggleCheckbox(tag.value)
                  }}
                />
              </FormControl>
              <FormLabel>{tag.label}</FormLabel>
            </FormItem>
          )}
        />
      ))
  }

  return <div className="grid grid-cols-2 gap-4">{renderCheckboxes()}</div>
}
