import React, { useEffect } from 'react'
import { RedirectToSignIn, useOrganization, useUser } from '@clerk/clerk-react'
import { MainNavigation } from '@/components/mainNavigation'
import { Toaster } from '@/components/ui/toaster'
import { StoreErrorDialog } from '@/components/storeErrorDialog'
import * as Sentry from '@sentry/react'
import { PaymentReminder } from '@/components/paymentReminder'
import { Button } from '@/components/ui/button'
import env from '@beam-australia/react-env'
import { Footer } from '@/components/footer'

export const Layout = ({ children }: { children: React.ReactElement }) => {
  const { isLoaded, isSignedIn, user } = useUser()
  const { organization } = useOrganization()
  const disabledNavigation = organization ? false : true

  useEffect(() => {
    const test = document.querySelector('head')
    if (!test) {
      return
    }

    const umamiScript = document.createElement('script')
    umamiScript.type = 'text/javascript'
    umamiScript.src = 'https://umami.mauricerenck.de/script.js'
    umamiScript.dataset.websiteId = `${env('UMAMI_SITE_ID')}`

    document.head.appendChild(umamiScript)
  }, [])

  // In case the user signs out while on the page.
  if (!isLoaded || !isSignedIn) {
    return <RedirectToSignIn />
  }

  const renderMissingStripeData = () => {
    if (!user?.hasVerifiedEmailAddress) {
      return (
        <div className="bg-orange-200 p-5 flex justify-between content-center">
          <span className="content-center">
            <span className="font-semibold">Please verify your email address to continue.</span>
            <br />
            <span className={'text-sm'}>
              In order to use the full RapidStream functionality, you have to verify your email
              address.
            </span>
          </span>

          <div>
            <Button
              onClick={() => {
                window.location.href = '/user/profile'
              }}
              data-umami-event="click-reminder-verify-email"
            >
              Go to profile
            </Button>
          </div>
        </div>
      )
    }

    if (!organization?.publicMetadata.paymentEmailAddress) {
      return (
        <div className="bg-orange-200 p-5 flex justify-between content-center">
          <span className="content-center">
            <span className="font-semibold">
              Please set your billing email address to continue.
            </span>
            <br />
            <span className={'text-sm'}>
              In order to use the full RapidStream functionality, you have to add a billing email
              address for your organization. (
              <a
                className="text-blue-600"
                href="https://rapidstream.video/pricing/"
                target="_blank"
                rel="noreferrer"
              >
                see our pricing
              </a>
              )
            </span>
          </span>

          <div>
            <PaymentReminder />
          </div>
        </div>
      )
    }

    if (!organization?.publicMetadata.isPaymentMethodAvailable) {
      return (
        <div className="bg-orange-200 p-5 flex justify-between content-center">
          <span className="content-center">
            <span className="font-semibold">Please add a payment method.</span>
            <br />
            <span className="text-sm">
              In order to use the full RapidStream functionality, you have to add a payment method
              for your organization (
              <a
                className="text-blue-600"
                href="https://rapidstream.video/pricing/"
                target="_blank"
                rel="noreferrer"
              >
                see our pricing
              </a>
              )
            </span>
          </span>
          <Button
            onClick={() => {
              const email = (organization?.publicMetadata.paymentEmailAddress as string) || ''
              const encodedEmail = encodeURIComponent(email)
              const url = `${env('STRIPE_ACCOUNT_URL')}?prefilled_email=${encodedEmail}`
              window.open(url, '_blank')
            }}
            data-umami-event="click-reminder-set-payment"
          >
            Set payment method
          </Button>
        </div>
      )
    }
  }

  const FallbackComponent = () => <div>An error has occurred</div>
  const myFallback = () => <FallbackComponent />

  return (
    <>
      <Sentry.ErrorBoundary fallback={myFallback} showDialog>
        <MainNavigation navDisabled={disabledNavigation} />
        {renderMissingStripeData()}
        <Toaster />
        <StoreErrorDialog />
        <main className="w-full pt-10 px-20 sm:px-10">{children}</main>
        <Footer />
      </Sentry.ErrorBoundary>
    </>
  )
}
