import { StateCreator } from 'zustand'
import { apiDelete, apiGet, apiPut } from '@/lib/api'
import { convertApiToStoreError, StoreApiToken, StoreCallback } from '@/store/index'
import { FastChannel } from '@/pages/fastChannels'

export interface FastChannelSlice {
  fastChannels: FastChannel[]
  setFastChannel: (fastChannel: FastChannel) => void
  getFastChannels: (token: StoreApiToken) => Promise<void>
  createFastChannel: (fastChannel: FastChannel) => void
  deleteFastChannel: (fastChannelId: string, token: StoreApiToken, callback?: StoreCallback) => void
  updateFastChannelName: (
    fastChannel: FastChannel,
    name: string,
    token: StoreApiToken,
    etag: string,
    setEtag: any,
    callback: StoreCallback
  ) => void
  updateFastChannelExternalId: (
    fastChannel: FastChannel,
    externalId: string,
    token: StoreApiToken,
    etag: string,
    setEtag: any,
    callback: StoreCallback
  ) => void
  enableFastChannel: (
    fastChannel: FastChannel,
    enabled: boolean,
    token: StoreApiToken,
    etag: string,
    setEtag: any,
    callback: StoreCallback
  ) => void
  fastChannelsReset: () => void
}

export const createFastChannelSlice: StateCreator<FastChannelSlice, [], [], FastChannelSlice> = (
  set,
  get
) => ({
  fastChannels: [],
  setFastChannel: (fastChannel) => {
    const state = get()
    const cleanedFastChannels = state.fastChannels.filter(
      (channel) => channel.id !== fastChannel.id
    )
    const updatedFastChannels = [...cleanedFastChannels, fastChannel]

    set({ fastChannels: updatedFastChannels })
  },
  getFastChannels: async (token) => {
    apiGet({
      token,
      path: `/setting/fast-channel`,
      callback: (success, response) => {
        if (success) {
          set({ fastChannels: response })
        } else {
          return
        }
      },
    })
  },
  createFastChannel: (fastChannel) => {
    get().setFastChannel(fastChannel)
  },
  deleteFastChannel: (fastChannelId, token, callback) => {
    const state = get()
    const affectedFastChannel = state.fastChannels.find((channel) => channel.id === fastChannelId)

    apiDelete({
      path: `/setting/fast-channel/${fastChannelId}`,
      etag: `W/"${affectedFastChannel?.revision || 0}"`,
      callback: (success, response) => {
        if (success) {
          const fastChannels = state.fastChannels.filter((rule) => rule.id !== fastChannelId)
          set({ fastChannels: fastChannels })

          if (callback) {
            callback(true, null)
          }
        } else {
          if (callback) {
            callback(false, null, convertApiToStoreError(response))
          }
        }
      },
      token,
    })
  },
  updateFastChannelName: async (fastChannel, name, token, etag, setEtag, callback) => {
    apiPut({
      path: `/setting/fast-channel/${fastChannel.id}/change-name`,
      data: {
        name,
      },
      etag,
      setEtag,
      token,
      callback: (success, response) => {
        if (success) {
          const updatedFastChannel = { ...fastChannel, name: response.name }
          get().setFastChannel(updatedFastChannel)
          callback(success, response)
        } else {
          callback(false, null, convertApiToStoreError(response))
          return
        }
      },
    })
  },
  updateFastChannelExternalId: async (fastChannel, externalId, token, etag, setEtag, callback) => {
    apiPut({
      path: `/setting/fast-channel/${fastChannel.id}/set-external-id`,
      data: {
        externalId,
      },
      etag,
      setEtag,
      token,
      callback: (success, response) => {
        if (success) {
          const updatedFastChannel = { ...fastChannel, externalId: response.externalId }
          get().setFastChannel(updatedFastChannel)
          callback(success, response)
        } else {
          callback(false, null, convertApiToStoreError(response))
          return
        }
      },
    })
  },
  enableFastChannel: async (fastChannel, enabled, token, etag, setEtag, callback) => {
    const apiVerb = enabled ? 'enable' : 'disable'
    apiPut({
      path: `/setting/fast-channel/${fastChannel.id}/${apiVerb}`,
      data: {},
      etag,
      setEtag,
      token,
      callback: (success, response) => {
        if (success) {
          const updatedFastChannel = { ...fastChannel, enabled: response.enabled }
          get().setFastChannel(updatedFastChannel)
          callback(success, response)
        } else {
          callback(false, null, convertApiToStoreError(response))
          return
        }
      },
    })
  },
  fastChannelsReset: () => set({ fastChannels: [] }),
})
