import { DataTable } from '@/components/dataTable'
import { ColumnDef } from '@tanstack/react-table'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Button } from '@/components/ui/button'
import { ArrowUpDown, MoreHorizontal } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { StoreError, useBoundStore } from '@/store'
import { AdRule } from '@/pages/adRules'
import { Badge } from '@/components/ui/badge'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import { useToken } from '@/hooks/useToken'

export interface Props {
  adRules: AdRule[]
}

export const AdRuleTable = ({ adRules }: Props) => {
  const { getClerkToken } = useToken()
  const navigate = useNavigate()
  const { handleError } = useErrorHandler()

  const deleteAdRule = useBoundStore((state) => state.deleteAdRule)
  const getAdRules = useBoundStore((state) => state.getAdRules)

  const onDeleteAdRule = async (id: string) => {
    const token = await getClerkToken()

    deleteAdRule(id, token, (success: boolean, response: any, error?: StoreError) => {
      handleError(
        success,
        () => {
          getAdRules(token)
        },
        error
      )
    })
  }

  const onSelectAdRule = (adRule: AdRule) => {
    navigate(`/ad-rules/${adRule.id}`)
  }

  const columns: ColumnDef<AdRule>[] = [
    {
      accessorKey: 'id',
      header: 'Rule Id',
    },
    {
      accessorKey: 'name',
      enableHiding: false,
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Name
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
    },
    {
      accessorKey: 'enabled',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Enabled
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
      cell: ({ row }) => {
        const badgeVariant = row.getValue('enabled') ? 'secondary' : 'destructive'
        return <Badge variant={badgeVariant}>{row.getValue('enabled') ? 'Yes' : 'No'}</Badge>
      },
    },
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => {
        const adRuleId: string = row.getValue('id')

        return (
          <div className="flex justify-center">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <DropdownMenuItem
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    navigate(`/ad-rules/${adRuleId}`)
                  }}
                >
                  Edit Ad Rule
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  className="text-red-500"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onDeleteAdRule(adRuleId)
                  }}
                >
                  Delete Ad Rule
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )
      },
    },
  ]

  return <DataTable columns={columns} data={adRules} onSelect={onSelectAdRule} />
}
