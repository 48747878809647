import { StateCreator } from 'zustand'
import { AdRule, AdRuleInventoryShare, KillSwitchDefinition } from '@/pages/adRules'
import { apiDelete, apiGet, apiPut } from '@/lib/api'
import { convertApiToStoreError, StoreApiToken, StoreCallback } from '@/store/index'
import { v4 as uuidv4 } from 'uuid'

export interface AdRuleSlice {
  adRules: AdRule[]
  getAdRules: (token: StoreApiToken, callback?: StoreCallback) => Promise<void>
  createAdRule: (adRule: AdRule, callback?: StoreCallback) => void
  setAdRule: (adRule: AdRule) => void
  deleteAdRule: (adRuleId: string, token: StoreApiToken, callback: StoreCallback) => void
  setAdRuleList: (adRules: AdRule[]) => void
  updateAdRuleDefinitions: (
    adRule: AdRule,
    definitions: KillSwitchDefinition[],
    token: StoreApiToken,
    etag: string,
    setEtag: any,
    callback: StoreCallback
  ) => void
  updateAdRuleInventory: (
    adRule: AdRule,
    inventory: AdRuleInventoryShare[],
    token: StoreApiToken,
    etag: string,
    setEtag: any,
    callback: StoreCallback
  ) => void
  updateAdRuleName: (
    adRuleId: AdRule,
    name: string,
    token: StoreApiToken,
    etag: string,
    setEtag: any,
    callback: StoreCallback
  ) => void
  enableAdRule: (
    adRuleId: AdRule,
    enabled: boolean,
    token: StoreApiToken,
    etag: string,
    setEtag: any,
    callback: StoreCallback
  ) => void
  adRulesReset: () => void
}

export const createAdRuleSlice: StateCreator<AdRuleSlice, [], [], AdRuleSlice> = (set, get) => ({
  adRules: [],
  createAdRule: (adRule) => {
    get().setAdRule(adRule)
  },
  setAdRule: (adRule) => {
    const state = get()
    const cleanedAdRules = state.adRules.filter((rule) => rule.id !== adRule.id)
    const updatedAdRules = [...cleanedAdRules, adRule]

    set({ adRules: updatedAdRules })
  },
  deleteAdRule: (adRuleId, token, callback) => {
    const state = get()
    const affectedKillSwitch = state.adRules.find((rule) => rule.id === adRuleId)

    apiDelete({
      path: `/setting/ad-rule/${adRuleId}`,
      etag: `W/"${affectedKillSwitch?.revision || 0}"`,
      callback: (success, response) => {
        if (success) {
          const adRules = state.adRules.filter((rule) => rule.id !== adRuleId)
          set({ adRules: adRules })

          if (callback) {
            callback(true, null)
          }
        } else {
          if (callback) {
            callback(false, null, convertApiToStoreError(response))
          }
        }
      },
      token,
    })
  },
  setAdRuleList: (adRules) => set((state) => ({ adRules: adRules })),
  getAdRules: async (token, callback) => {
    apiGet({
      token,
      path: `/setting/ad-rule`,
      callback: (success, response) => {
        if (success) {
          const enhancedAdRules: AdRule[] = response.map((adRule: AdRule) => {
            return {
              ...adRule,
              inventoryShares: adRule.inventoryShares.map((share: AdRuleInventoryShare) => {
                return {
                  ...share,
                  id: uuidv4(),
                }
              }),
            }
          })

          set({ adRules: enhancedAdRules })
        } else {
          if (callback) {
            callback(false, null, {
              title: 'Error',
              detail: 'Could not get ad rules. Please try again later.',
              type: 'api-error',
            })
          }
        }
      },
    })
  },
  updateAdRuleDefinitions: async (adRule, definitions, token, etag, setEtag, callback) => {
    apiPut({
      path: `/setting/ad-rule/${adRule.id}/change-definitions`,
      data: definitions,
      etag,
      setEtag,
      token,
      callback: (success, response) => {
        if (success) {
          const updatedAdRule = { ...adRule, definitions: response.definitions }
          get().setAdRule(updatedAdRule)
          callback(success, response)
        } else {
          if (callback) {
            callback(false, null, convertApiToStoreError(response))
          }
        }
      },
    })
  },
  updateAdRuleInventory: async (adRule, inventory, token, etag, setEtag, callback) => {
    const inventoryWithoutId = inventory.map((share: AdRuleInventoryShare) => {
      return {
        ...share,
        id: undefined,
      }
    })

    apiPut({
      path: `/setting/ad-rule/${adRule.id}/change-inventory-shares`,
      data: inventoryWithoutId,
      etag,
      setEtag,
      token,
      callback: (success, response) => {
        if (success) {
          const updatedAdRule = { ...adRule, inventoryShares: response.inventoryShares }
          get().setAdRule(updatedAdRule)
          callback(success, response)
        } else {
          if (callback) {
            callback(false, null, convertApiToStoreError(response))
          }
        }
      },
    })
  },
  updateAdRuleName: async (adRule, name, token, etag, setEtag, callback) => {
    apiPut({
      path: `/setting/ad-rule/${adRule.id}/change-name`,
      data: {
        name,
      },
      etag,
      setEtag,
      token,
      callback: (success, response) => {
        if (success) {
          const updatedAdRule = { ...adRule, name: response.name }
          get().setAdRule(updatedAdRule)
          callback(success, response)
        } else {
          if (callback) {
            callback(false, null, convertApiToStoreError(response))
          }
        }
      },
    })
  },
  enableAdRule: async (adRule, enabled, token, etag, setEtag, callback) => {
    const apiVerb = enabled ? 'enable' : 'disable'
    apiPut({
      path: `/setting/ad-rule/${adRule.id}/${apiVerb}`,
      data: {},
      etag,
      setEtag,
      token,
      callback: (success, response) => {
        if (success) {
          const updatedAdRule = { ...adRule, enabled: response.enabled }
          get().setAdRule(updatedAdRule)

          if (callback) {
            callback(success, response)
          }
        } else {
          if (callback) {
            callback(false, null, convertApiToStoreError(response))
          }
        }
      },
    })
  },
  adRulesReset: async () => set({ adRules: [] }),
})
