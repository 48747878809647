import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { StoreError } from '@/store'
import { toast } from '@/components/ui/use-toast'
import { TagsInput } from 'react-tag-input-component'

type FormStatus = 'idle' | 'changed' | 'saving' | 'saved' | 'error'

interface Props {
  onSubmit: (
    data: any,
    callback: (result: boolean, value: string, error?: StoreError) => void
  ) => void
  value: any
  label: string
  description?: string
  placeholder?: string
  fieldName: string
  className?: string
  type?: string
}

export const AutosaveTagInput = ({
  value,
  onSubmit,
  label,
  fieldName,
  placeholder,
  description,
  className,
  type,
}: Props) => {
  const [formStatus, setFormStatus] = useState<FormStatus>('idle')
  const [initialValue, setInitialValue] = useState<string>()

  let idleTimer: ReturnType<typeof setTimeout> | null = null

  useEffect(() => {
    setInitialValue(value)
  }, [setInitialValue, value])

  const formSchema = z.object({
    [fieldName]: z.array(z.string()).optional(),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      [fieldName]: value,
    },
    mode: 'all',
  })

  const formCallback = (result: boolean, value: string, error?: StoreError) => {
    setFormStatus(result ? 'saved' : 'error')

    if (!result) {
      toast({
        variant: 'destructive',
        title: error?.title || 'Error',
        description: `${error?.detail || 'Could not save changes'}`,
      })
    }

    if (result) {
      setInitialValue(value)
    }
  }

  const saveFormValue = async (formValue: string) => {
    // no changes, no need to save
    if (formValue === initialValue) {
      return
    }

    // if there are errors, don't save
    if (form.formState.errors[fieldName]) {
      return
    }

    const cleanedFormValue = formValue.length > 0 ? formValue : null

    // if changes were made, but the last change was less than 2 seconds ago, wait 2 seconds and then save
    setFormStatus('saving')
    await onSubmit({ [fieldName]: cleanedFormValue }, formCallback)
    setIdleMode()
  }

  const onChange = (event: any) => {
    setFormStatus('changed')
    saveFormValue(event)
  }
  const setIdleMode = () => {
    if (idleTimer !== null) {
      clearTimeout(idleTimer)
    }

    idleTimer = setTimeout(() => {
      setFormStatus('idle')
    }, 4000)
  }

  const submitForm = async (data: any) => {
    saveFormValue(data[fieldName])
  }

  const renderStatus = () => {
    switch (formStatus) {
      case 'changed':
        return 'Value modified'
      case 'saving':
        return 'Saving...'
      case 'saved':
        return 'Changes saved'
      case 'error':
        return 'Could not save changes'
      default:
        return description
    }
  }

  const statusColor = (border: boolean) => {
    switch (formStatus) {
      case 'changed':
        return border ? 'border-gray-500' : 'text-gray-500'
      case 'saving':
        return border ? 'border-gray-500' : 'text-gray-500'
      case 'saved':
        return border ? 'border-green-500' : 'text-green-500'
      case 'error':
        return border ? 'border-red-500' : 'text-red-500'
      default:
        return border ? 'border-gray-500' : 'text-gray-500'
    }
  }

  const disabled = formStatus === 'saving'

  return (
    <div className={className}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(submitForm)} className="space-y-8">
          <FormField
            control={form.control}
            name={fieldName}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{label}</FormLabel>
                <FormControl>
                  <TagsInput
                    {...form.register(fieldName, {
                      disabled,
                    })}
                    onChange={onChange}
                    placeHolder={placeholder}
                    value={value ?? []}
                  />
                </FormControl>
                <FormDescription className={statusColor(false)}>{renderStatus()}</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </form>
      </Form>
    </div>
  )
}
