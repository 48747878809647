import { Layout } from '@/components/layout'
import { useEffect, useState } from 'react'
import { AutosaveTextInput } from '@/components/autosaveTextInput'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { PageLoadingIndicator } from '@/components/pageLoadingIndicator'
import { StoreCallback, StoreError, useBoundStore } from '@/store'
import { FastChannel } from '@/pages/fastChannels'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarTrigger,
} from '@/components/ui/menubar'
import { MenubarToggleEntry } from '@/components/menubarToggleEntry'
import { useToast } from '@/components/ui/use-toast'
import env from '@beam-australia/react-env'
import { useToken } from '@/hooks/useToken'

export const FastChannelsEdit = () => {
  const { getClerkToken } = useToken()
  const { id } = useParams()
  const { toast } = useToast()
  const navigate = useNavigate()

  const fastChannels = useBoundStore((state) => state.fastChannels)
  const getFastChannels = useBoundStore((state) => state.getFastChannels)
  const enableFastChannel = useBoundStore((state) => state.enableFastChannel)
  const updateFastChannelName = useBoundStore((state) => state.updateFastChannelName)
  const updateFastChannelExternalId = useBoundStore((state) => state.updateFastChannelExternalId)
  const deleteFastChannel = useBoundStore((state) => state.deleteFastChannel)

  const [fastChannel, setFastChannel] = useState<FastChannel>()
  const [fastChannelBaseUrl, setFastChannelBaseUrl] = useState<string>('')
  const [etag, setEtag] = useState<string>('')

  const { handleError } = useErrorHandler()

  const onDeleteFastChannel = async () => {
    const token = await getClerkToken()
    if (!fastChannel?.id) return

    deleteFastChannel(
      fastChannel.id,
      token,
      (success: boolean, response: any, error?: StoreError) => {
        handleError(
          success,
          () => {
            getFastChannels(token)
          },
          error
        )

        if (success) {
          navigate('/fast-channels')
        }
      }
    )
  }

  const onUpdateFastChannelName = async (data: any, callback: StoreCallback) => {
    const token = await getClerkToken()

    if (!fastChannel) {
      return
    }

    updateFastChannelName(
      fastChannel,
      data.name,
      token,
      etag,
      setEtag,
      (success, response, error) => {
        callback(response, data)
        handleError(
          success,
          () => {
            getFastChannels(token)
          },
          error
        )
      }
    )
  }

  const onUpdateFastChannelExternalId = async (data: any, callback: StoreCallback) => {
    const token = await getClerkToken()

    if (!fastChannel) {
      return
    }

    updateFastChannelExternalId(
      fastChannel,
      data.externalId,
      token,
      etag,
      setEtag,
      (success, response, error) => {
        callback(response, data)
        handleError(
          success,
          () => {
            getFastChannels(token)
          },
          error
        )
      }
    )
  }

  const onEnableFastChannel = async (data: any, callback: StoreCallback) => {
    const token = await getClerkToken()

    if (!fastChannel) {
      return
    }

    enableFastChannel(
      fastChannel,
      data.enabled,
      token,
      etag,
      setEtag,
      (success, response, error) => {
        callback(response, data)
        handleError(
          success,
          () => {
            getFastChannels(token)
          },
          error
        )
      }
    )
  }

  const copyFastChannelUrl = async () => {
    const fastChannelUrl = `${fastChannelBaseUrl}/${fastChannel?.id}/master.m3u8`
    navigator.clipboard.writeText(fastChannelUrl)

    toast({
      variant: 'default',
      title: 'FAST Channel URL copied to clipboard',
    })
  }

  useEffect(() => {
    const fetchServers = async () => {
      const token = await getClerkToken()
      await getFastChannels(token)
    }

    fetchServers()
  }, [getFastChannels, getClerkToken])

  useEffect(() => {
    const selectedFastChannel = fastChannels.find((channel) => channel.id === id)

    if (!selectedFastChannel) return
    setFastChannel(selectedFastChannel)

    const baseUrl = env('MEDIATAILOR_BASE_URL').endsWith('/')
      ? env('MEDIATAILOR_BASE_URL').slice(0, -1)
      : env('MEDIATAILOR_BASE_URL')
    setFastChannelBaseUrl(baseUrl)

    // only set etag on initial load
    if (etag || (!selectedFastChannel?.revision && selectedFastChannel?.revision !== 0)) return
    setEtag(`W/"${selectedFastChannel.revision.toString()}"`)
  }, [fastChannels, setEtag, etag, id])

  if (!fastChannel) {
    return (
      <Layout>
        <PageLoadingIndicator />
      </Layout>
    )
  }

  return (
    <Layout>
      <div>
        <h1 className="text-5xl">FAST Channel</h1>

        <div className="my-10 flex place-items-center justify-items-center">
          <Menubar>
            <MenubarToggleEntry
              enabled={fastChannel.enabled}
              onSubmit={onEnableFastChannel}
              fieldName="enabled"
              labelTrue="Enabled"
              labelFalse="Disabled"
              label="Status"
            />
            <MenubarMenu>
              <MenubarTrigger>Actions</MenubarTrigger>
              <MenubarContent>
                <Link to={`${fastChannelBaseUrl}/${fastChannel?.id}/master.m3u8`} target="_blank">
                  <MenubarItem>Open FAST Channel URL</MenubarItem>
                </Link>
                <MenubarItem onClick={copyFastChannelUrl}>Copy FAST Channel URL</MenubarItem>
                <MenubarSeparator />
                <MenubarItem onClick={onDeleteFastChannel}>
                  <span className="text-red-600 ml-1">Delete FAST Channel</span>
                </MenubarItem>
              </MenubarContent>
            </MenubarMenu>
          </Menubar>
        </div>

        <div className="grid grid-cols-2 gap-20 mt-20">
          <AutosaveTextInput
            onSubmit={onUpdateFastChannelName}
            value={fastChannel.name}
            fieldName="name"
            description="Enter a FAST Channel name"
            label="FAST Channel name"
            className="basis-1/4"
          />

          <AutosaveTextInput
            onSubmit={onUpdateFastChannelExternalId}
            value={fastChannel.externalId}
            fieldName="externalId"
            description="Enter the FAST Channel external id of your system"
            label="Your FAST Channel id"
            className="basis-1/4"
          />
        </div>
      </div>
    </Layout>
  )
}
