import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { CreateByName } from '@/components/createByName'

export interface Props {
  label: string
  number: number
  dialogTitle: string
  dialogDescription: string
  dialogLabel: string
  dialogButtonText: string
  dialogTriggerText: string
  postEndpoint: string
  storeAction: (data: any) => void
  redirectPath?: string
}

export const CreateTile = ({
  label,
  number,
  dialogTitle,
  dialogDescription,
  dialogLabel,
  dialogButtonText,
  dialogTriggerText,
  postEndpoint,
  storeAction,
  redirectPath,
}: Props) => {
  return (
    <Card className="w-80">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">{label}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-bold mb-5">{number}</div>
        <CreateByName
          title={dialogTitle}
          triggerText={dialogTriggerText}
          label={dialogLabel}
          buttonText={dialogButtonText}
          description={dialogDescription}
          postEndpoint={postEndpoint}
          storeAction={storeAction}
          redirectPath={redirectPath}
        />
      </CardContent>
    </Card>
  )
}
