import { StateCreator } from 'zustand'
import { apiGet } from '@/lib/api'
import { StoreApiToken, StoreCallback } from '@/store/index'

export type BillingQuotaType = 'ad-impression' | 'kill-switch'
export type BillingQuotaCamelType = 'adImpression' | 'killSwitch'

export type BillingQuota = {
  type: BillingQuotaCamelType
  quotas: {
    quota: number
    period: string
  }[]
}

type QuotaResponse = {
  period: string
  quota: number
}

type CostResponse = {
  name: BillingQuotaCamelType
  amount: number
  currency: string
  period: string
  quantity: number
}

export interface BillingSlice {
  currentQuota: BillingQuota[] | null
  upcomingCosts: CostResponse[]
  quotaList: BillingQuota[]
  getQuota: (
    token: StoreApiToken,
    type: BillingQuotaType,
    callback?: StoreCallback
  ) => Promise<void>
  getCosts: (token: StoreApiToken, callback?: StoreCallback) => Promise<void>
  quotaReset: () => void
}

export const createBillingSlice: StateCreator<BillingSlice, [], [], BillingSlice> = (set, get) => ({
  currentQuota: [],
  upcomingCosts: [],
  quotaList: [],
  getQuota: async (token, type, callback) => {
    apiGet({
      token,
      path: `/billing/quota/${type}`,
      callback: (success, response: QuotaResponse[]) => {
        if (success) {
          const camelCaseType = type.replace(/-([a-z])/g, (g) =>
            g[1].toUpperCase()
          ) as BillingQuotaCamelType

          const otherQuotas = get().quotaList.filter((q) => q.type !== camelCaseType)
          const newQuotas = response.map((q: QuotaResponse) => ({
            quota: q.quota,
            period: q.period,
          }))

          const quotas: BillingQuota = {
            type: camelCaseType,
            quotas: newQuotas,
          }

          // get latest quota
          const latestQuota = newQuotas.reduce((prev, current) => {
            return prev.period > current.period ? prev : current
          })

          const currentQuota = get().currentQuota ?? []
          const updatedLatestQuotaList = [
            ...currentQuota,
            { type: camelCaseType, quotas: [latestQuota] },
          ]

          set({ quotaList: [...otherQuotas, quotas] })
          set({ currentQuota: updatedLatestQuotaList })

          if (callback) {
            callback(true, null)
          }
        } else {
          if (callback) {
            callback(false, null, {
              title: 'Error',
              detail: `Could not get quota for type ${type}. Please try again later.`,
              type: 'api-error',
            })
          }
        }
      },
    })
  },
  getCosts: async (token, callback) => {
    apiGet({
      token,
      path: `/billing/costs/upcoming`,
      callback: (success, response: CostResponse[]) => {
        if (success) {
          set({ upcomingCosts: response })

          if (callback) {
            callback(true, response)
          }
        } else {
          if (callback) {
            callback(false, null, {
              title: 'Error',
              detail: `Could not get upcoming costs. Please try again later.`,
              type: 'api-error',
            })
          }
        }
      },
    })
  },
  quotaReset: () => set({ quotaList: [], currentQuota: null, upcomingCosts: [] }),
})
