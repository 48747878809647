import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { useBoundStore } from '@/store'
import { useEffect, useState } from 'react'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { Input } from '@/components/ui/input'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandSeparator,
} from '@/components/ui/command'
import { Check } from 'lucide-react'
import { cn } from '@/lib/utils'
import { useToken } from '@/hooks/useToken'

interface Props {
  form: any
  context: string
  fieldName: string
  fieldLabel: string
  fieldDescription?: string
}

export type ComboboxEntry = {
  label: string
  value: string
  group?: string
}

export const PlaceholderAutocomplete = ({
  form,
  context,
  fieldName,
  fieldLabel,
  fieldDescription,
}: Props) => {
  const { getClerkToken } = useToken()

  const tags = useBoundStore((state) => state.tags)
  const getTags = useBoundStore((state) => state.getTags)
  const addTag = useBoundStore((state) => state.addTag)

  const [tagData, setTagData] = useState<ComboboxEntry[]>()
  const [fieldValue, setFieldValue] = useState<string>('')
  const [autocompleteValue, setAutocompleteValue] = useState<string>('')
  const [showAutocomplete, setShowAutocomplete] = useState<boolean>(false)

  const createNewTag = async (event: any) => {
    if (event.key === 'Enter' && event.target instanceof HTMLInputElement) {
      const token = await getClerkToken()

      if (!token) {
        return
      }

      addTag(event.target.value, context, token, () => {
        writeTagToInput(event.target.value)
      })
    }
  }

  const handleKeyUp = (event: any) => {
    if (event.key === '{') {
      setShowAutocomplete(true)
      setAutocompleteValue('')
    } else if (event.key === '}') {
      setShowAutocomplete(false)
    }
    setFieldValue(event.target.value)
  }

  const handleKeyDown = (event: any) => {
    if (
      showAutocomplete &&
      !event.ctrlKey &&
      !event.altKey &&
      !event.metaKey &&
      !event.shiftKey &&
      event.key.length === 1
    ) {
      setAutocompleteValue(autocompleteValue + event.key)
    }
  }

  const writeTagToInput = (tag: string) => {
    const newValue = fieldValue + tag + '}'
    setFieldValue(newValue)
    form.setValue(fieldName, newValue)
    setShowAutocomplete(false)
    form.setFocus(fieldName)
  }

  const renderAutocomplete = (field: any) => {
    if (!showAutocomplete || !tagData) {
      return null
    }

    return (
      <Command>
        <CommandInput
          placeholder={`Search ${fieldLabel}...`}
          onKeyUp={createNewTag}
          autoFocus={true}
        />
        <CommandEmpty>Press enter to create</CommandEmpty>
        <CommandGroup heading="Custom">
          {tagData
            .filter((tag) => tag.group === 'user')
            .map((tag) => (
              <CommandItem
                value={tag.value}
                key={tag.value}
                onSelect={() => {
                  writeTagToInput(tag.value)
                }}
              >
                <Check
                  className={cn(
                    'mr-2 h-4 w-4',
                    tag.value === field.value ? 'opacity-100' : 'opacity-0'
                  )}
                />
                {tag.label}
              </CommandItem>
            ))}
        </CommandGroup>
        <CommandSeparator />
        <CommandGroup heading="System">
          {tagData
            .filter((tag) => tag.group === 'system')
            .map((tag) => (
              <CommandItem
                value={tag.value}
                key={tag.value}
                onSelect={() => {
                  writeTagToInput(tag.value)
                }}
              >
                <Check
                  className={cn(
                    'mr-2 h-4 w-4',
                    tag.value === field.value ? 'opacity-100' : 'opacity-0'
                  )}
                />
                {tag.label}
              </CommandItem>
            ))}
        </CommandGroup>
      </Command>
    )
  }

  useEffect(() => {
    const fetchServers = async () => {
      const token = await getClerkToken()
      getTags(token)
    }

    if (tags.tags.length === 0) {
      fetchServers()
    }
  }, [getClerkToken, tags.tags.length, getTags])

  useEffect(() => {
    const tagsByContext = tags.tags.filter((tag) => tag.context === context)[0]

    if (!tagsByContext) {
      return
    }

    const newUserTagData = tagsByContext.userTags.map((tag) => {
      return {
        label: tag,
        value: tag,
        group: 'user',
      }
    })

    const newSystemTagData = tagsByContext.systemTags.map((tag) => {
      return {
        label: tag,
        value: tag,
        group: 'system',
      }
    })

    const newTags = [...newUserTagData, ...newSystemTagData]
    setTagData(newTags)
  }, [setTagData, tags, context])

  if (!tagData) {
    return (
      <Alert variant="destructive">
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>
          We could not load the needed data for the {fieldLabel} field. Please try again.
        </AlertDescription>
      </Alert>
    )
  }

  return (
    <FormField
      control={form.control}
      name={fieldName}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{fieldLabel}</FormLabel>
          <FormControl>
            <Input
              {...form.register(fieldName, {
                required: true,
              })}
              placeholder={fieldLabel}
              onKeyDown={handleKeyDown}
              onKeyUp={handleKeyUp}
              autoComplete="off"
            />
          </FormControl>
          <FormDescription>{fieldDescription}</FormDescription>
          <FormMessage />
          {renderAutocomplete(field)}
        </FormItem>
      )}
    />
  )
}
