import 'unfetch/polyfill'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Link } from 'react-router-dom'

interface DataTileProps {
  title: string
  count: string | number
  link?: string
}

export const DataTile = (props: DataTileProps) => {
  const link = props.link ?? '#'
  return (
    <Link to={link} className="basis-1/4">
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">{props.title}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{props.count}</div>
        </CardContent>
      </Card>
    </Link>
  )
}
