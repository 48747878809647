import { StateCreator } from 'zustand'
import { apiDelete, apiGet, apiPost } from '@/lib/api'
import { convertApiToStoreError, StoreApiToken, StoreCallback } from '@/store/index'

export interface AccessTokenSlice {
  tokens: AccessToken[]
  scopes: string[]
  getScopes: (token: StoreApiToken, callback?: StoreCallback) => Promise<void>
  getTokens: (token: StoreApiToken, callback?: StoreCallback) => Promise<void>
  setToken: (adRule: AccessToken) => void
  createToken: (token: StoreApiToken, data: AccessToken, callback?: StoreCallback) => void
  deleteToken: (token: StoreApiToken, id: string, callback?: StoreCallback) => void
  tokensReset: () => void
}

export type AccessToken = {
  id?: string
  accessToken?: string
  name: string
  expiryDate: Date | null
  scopes: string[]
  revision?: number
}

export const createAccessTokenSlice: StateCreator<AccessTokenSlice, [], [], AccessTokenSlice> = (
  set,
  get
) => ({
  tokens: [],
  scopes: [],
  getScopes: async (token, callback) => {
    apiGet({
      token,
      path: `/access-token/scope`,
      callback: (success, response: string[]) => {
        if (success) {
          set({ scopes: response })

          if (callback) {
            callback(true, null)
          }
        } else {
          if (callback) {
            callback(false, null, {
              title: 'Error',
              detail: `Could not get scopes. Please try again later.`,
              type: 'api-error',
            })
          }
        }
      },
    })
  },
  getTokens: async (token, callback) => {
    apiGet({
      token,
      path: `/access-token`,
      callback: (success, response: any) => {
        if (success) {
          set({ tokens: response })

          if (callback) {
            callback(true, null)
          }
        } else {
          if (callback) {
            callback(false, null, {
              title: 'Error',
              detail: `Could not get tokens. Please try again later.`,
              type: 'api-error',
            })
          }
        }
      },
    })
  },
  setToken: (accessToken) => {
    const state = get()
    const cleanedTokens = state.tokens.filter((token) => token.id !== accessToken.id)
    const updatedTokens = [...cleanedTokens, accessToken]

    set({ tokens: updatedTokens })
  },
  createToken: async (token, data, callback) => {
    apiPost({
      path: '/access-token',
      data,
      callback: (success, response: any) => {
        if (success) {
          get().setToken(response)

          if (callback) {
            callback(true, response)
          }
        } else {
          if (callback) {
            callback(false, null, {
              title: 'Error',
              detail: `Could not create token. Please try again later.`,
              type: 'api-error',
            })
          }
        }
      },
      token,
    })
  },
  deleteToken: async (token, id, callback) => {
    const state = get()
    const affectedToken = state.tokens.find((token) => token.id === id)

    apiDelete({
      path: `/access-token/${id}`,
      etag: `W/"${affectedToken?.revision || 0}"`,
      callback: (success, response) => {
        if (success) {
          const accessTokens = state.tokens.filter((token) => token.id !== id)
          set({ tokens: accessTokens })

          if (callback) {
            callback(true, null)
          }
        } else {
          if (callback) {
            callback(false, null, convertApiToStoreError(response))
          }
        }
      },
      token,
    })
  },
  tokensReset: () => set({ tokens: [] }),
})
