import { DataTable } from '@/components/dataTable'
import { ColumnDef } from '@tanstack/react-table'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Button } from '@/components/ui/button'
import { ArrowUpDown, MoreHorizontal } from 'lucide-react'
import { AdRuleInventoryShare } from '@/pages/adRules'
import { AdServer } from '@/pages/adServers'
import { Badge } from '@/components/ui/badge'

export interface Props {
  inventory: AdRuleInventoryShare[]
  adServers: AdServer[]
  onEdit: (inventoryId: string) => void
  onDelete: (inventoryId: string) => void
}

export const InventoryTable = ({ inventory, adServers, onDelete, onEdit }: Props) => {
  const columns: ColumnDef<AdRuleInventoryShare>[] = [
    {
      accessorKey: 'id',
      header: 'Id',
    },
    {
      accessorKey: 'share',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Share
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
      cell: ({ row }) => {
        const share = row.getValue('share') as string
        return <div className="text-right font-medium">{share}</div>
      },
    },
    {
      accessorKey: 'waterfall',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Ad Server
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
      cell: ({ row }) => {
        const servers = row.getValue('waterfall') as string[]

        return servers.map((server) => {
          return (
            <span className="font-medium">
              <Badge variant="outline">{server}</Badge>
            </span>
          )
        })
      },
    },
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => {
        const inventoryId = row.getValue('id') as string
        return (
          <div className="flex justify-center">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onEdit(inventoryId)
                  }}
                >
                  Edit Share
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="text-red-500"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onDelete(inventoryId)
                  }}
                >
                  Delete Share
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )
      },
    },
  ]

  const formatInventoryShares = (): AdRuleInventoryShare[] => {
    return inventory.map((share): AdRuleInventoryShare => {
      const serverNames = share.waterfall.map((waterfall) => {
        return adServers.find((server) => server.id === waterfall)?.name || 'no name'
      })

      return {
        id: share.id,
        share: share.share,
        waterfall: serverNames,
      }
    })
  }

  return (
    <DataTable
      columns={columns}
      data={formatInventoryShares()}
      hideColumnButton={true}
      onSelect={(data) => onEdit(data.id)}
    />
  )
}
