import { DataTable } from '@/components/dataTable'
import { ColumnDef } from '@tanstack/react-table'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Button } from '@/components/ui/button'
import { ArrowUpDown, MoreHorizontal } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { StoreError, useBoundStore } from '@/store'
import { KillSwitch, KillSwitchDefinition } from '@/pages/killSwitch'
import { Badge } from '@/components/ui/badge'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import { useToken } from '@/hooks/useToken'

export interface Props {
  killSwitches: KillSwitch[]
}

export const KillSwitchTable = ({ killSwitches }: Props) => {
  const { getClerkToken } = useToken()
  const navigate = useNavigate()
  const deleteKillSwitch = useBoundStore((state) => state.deleteKillSwitch)
  const getKillSwitches = useBoundStore((state) => state.getKillSwitches)

  const { handleError } = useErrorHandler()

  const onDeleteKillSwitch = async (id: string) => {
    const token = await getClerkToken()
    deleteKillSwitch(id, token, (success: boolean, response: any, error?: StoreError) => {
      handleError(
        success,
        () => {
          getKillSwitches(token)
        },
        error
      )
    })
  }

  const onSelectKillSwitch = (killSwitch: KillSwitch) => {
    navigate(`/kill-switches/${killSwitch.id}`)
  }

  const columns: ColumnDef<KillSwitch>[] = [
    {
      accessorKey: 'id',
      header: 'Kill Switch Id',
    },
    {
      accessorKey: 'name',
      enableHiding: false,
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Name
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
    },
    {
      accessorKey: 'definitions',
      enableHiding: false,
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Matches
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
      cell: ({ row }) => {
        const definitions = row.getValue('definitions') as KillSwitchDefinition[]

        return definitions.map((definition) => {
          return (
            <span className="font-medium">
              <Badge variant="outline">
                {definition.key} : {definition.values.join(', ')}
              </Badge>
            </span>
          )
        })
      },
    },
    {
      accessorKey: 'enabled',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Enabled
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
      cell: ({ row }) => {
        const badgeVariant = row.getValue('enabled') ? 'secondary' : 'destructive'
        return <Badge variant={badgeVariant}>{row.getValue('enabled') ? 'Yes' : 'No'}</Badge>
      },
    },
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => {
        const killSwitchId: string = row.getValue('id')

        return (
          <div className="flex justify-center">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <DropdownMenuItem
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    navigate(`/kill-switches/${killSwitchId}`)
                  }}
                >
                  Edit Kill Switch
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  className="text-red-500"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onDeleteKillSwitch(killSwitchId)
                  }}
                >
                  Delete Kill Switch
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )
      },
    },
  ]

  return <DataTable columns={columns} data={killSwitches} onSelect={onSelectKillSwitch} />
}
