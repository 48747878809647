import { StateCreator } from 'zustand'
import { AdServer } from '@/pages/adServers'
import { convertApiToStoreError, StoreApiToken, StoreCallback } from '@/store/index'
import { apiDelete, apiGet, apiPost, apiPut } from '@/lib/api'
import * as Sentry from '@sentry/react'

export interface AdServerSlice {
  adServers: AdServer[]
  adServer: AdServer | null
  setAdServer: (adServer: AdServer) => void
  getAdServers: (token: StoreApiToken, callback?: StoreCallback) => Promise<void>
  getAdServerById: (adServerId: string, callback?: StoreCallback) => Promise<void>
  createServer: (adServer: AdServer) => void
  deleteServer: (adServerId: string, token: StoreApiToken, callback?: StoreCallback) => void
  enableAdServer: (
    adServer: AdServer,
    enabled: boolean,
    token: StoreApiToken,
    etag: string,
    setEtag: any,
    callback: StoreCallback
  ) => void
  setDefault: (
    adServer: AdServer,
    isDefault: boolean,
    token: StoreApiToken,
    etag: string,
    setEtag: any,
    callback: StoreCallback
  ) => void
  updateAdServerName: (
    adServer: AdServer,
    name: string,
    token: StoreApiToken,
    etag: string,
    setEtag: any,
    callback: StoreCallback
  ) => void
  updateAdServerBaseUrl: (
    adServer: AdServer,
    baseUrl: string,
    token: StoreApiToken,
    etag: string,
    setEtag: any,
    callback: StoreCallback
  ) => void
  addAdServerConfiguration: (
    adServer: AdServer,
    data: any,
    token: StoreApiToken,
    etag: string,
    setEtag: any,
    callback: StoreCallback
  ) => void
  updateAdServerConfiguration: (
    adServer: AdServer,
    data: any,
    configurationId: string,
    token: StoreApiToken,
    etag: string,
    setEtag: any,
    callback: StoreCallback
  ) => void
  deleteAdServerConfiguration: (
    adServer: AdServer,
    configurationId: string,
    token: StoreApiToken,
    etag: string,
    setEtag: any,
    callback: StoreCallback
  ) => void
  adServerReset: () => void
}

export const createAdServerSlice: StateCreator<AdServerSlice, [], [], AdServerSlice> = (
  set,
  get
) => ({
  adServers: [],
  adServer: null,
  setAdServer: (adServer) => {
    const state = get()
    const cleanedAdServers = state.adServers.filter((server) => server.id !== adServer.id)
    const updatedAdServers = [...cleanedAdServers, adServer]

    set({ adServers: updatedAdServers })
    set({ adServer: adServer })
  },
  getAdServers: async (token, callback) => {
    apiGet({
      token,
      path: `/setting/ad-server`,
      callback: (success, response) => {
        if (success) {
          if (Array.isArray(response)) {
            set({ adServers: response })

            if (callback) {
              callback(true, response)
            }
          } else {
            Sentry.captureMessage(
              `Ad server response is not an array, but "${typeof response}": ${JSON.stringify(
                response
              )}`
            )

            if (callback) {
              callback(false, null, convertApiToStoreError(response))
            }
          }
        } else {
          if (callback) {
            callback(false, null, convertApiToStoreError(response))
          }
        }
      },
    })
  },
  getAdServerById: async (adServerId, callback) => {
    const serverById = get().adServers.find((server) => server.id === adServerId)

    if (serverById) {
      set({ adServer: serverById })
    }
  },
  createServer: (adServer) => {
    get().setAdServer(adServer)
  },
  deleteServer: (adServerId, token, callback) => {
    const state = get()
    const affectedAdServer = state.adServers.find((server) => server.id === adServerId)

    apiDelete({
      path: `/setting/ad-server/${adServerId}`,
      etag: `W/"${affectedAdServer?.revision || 0}"`,
      callback: (success, response) => {
        if (success) {
          const adServers = state.adServers.filter((server) => server.id !== adServerId)
          set({ adServers: adServers })
          set({ adServer: null })

          if (callback) {
            callback(true, null)
          }
        } else {
          if (callback) {
            callback(false, null, convertApiToStoreError(response))
          }
        }
      },
      token,
    })
  },
  enableAdServer: async (adServer, enabled, token, etag, setEtag, callback) => {
    const apiVerb = enabled ? 'enable' : 'disable'
    apiPut({
      path: `/setting/ad-server/${adServer.id}/${apiVerb}`,
      data: {},
      etag,
      setEtag,
      token,
      callback: (success, response) => {
        if (success) {
          const updatedAdServer = { ...adServer, enabled: response.enabled }
          get().setAdServer(updatedAdServer)
          callback(success, response)
        } else {
          callback(false, null, convertApiToStoreError(response))
        }
      },
    })
  },
  setDefault: async (adServer, isDefault, token, etag, setEtag, callback) => {
    const apiVerb = isDefault ? 'set-default' : 'unset-default'

    apiPut({
      path: `/setting/ad-server/${adServer.id}/${apiVerb}`,
      data: {},
      etag,
      setEtag,
      token,
      callback: (success, response) => {
        if (success) {
          const updatedAdServer = { ...adServer, isDefault: response.isDefault }
          get().setAdServer(updatedAdServer)
          callback(success, response)
        } else {
          callback(false, null, convertApiToStoreError(response))
        }
      },
    })
  },
  updateAdServerName: async (adServer, name, token, etag, setEtag, callback) => {
    apiPut({
      path: `/setting/ad-server/${adServer.id}/change-name`,
      data: {
        name,
      },
      etag,
      setEtag,
      token,
      callback: (success, response) => {
        if (success) {
          const updatedAdServer = { ...adServer, name: response.name }
          get().setAdServer(updatedAdServer)
          callback(success, response)
        } else {
          callback(false, null, convertApiToStoreError(response))
        }
      },
    })
  },
  updateAdServerBaseUrl: async (adServer, baseUrl, token, etag, setEtag, callback) => {
    apiPut({
      path: `/setting/ad-server/${adServer.id}/set-base-url`,
      data: {
        baseUrl,
      },
      etag,
      setEtag,
      token,
      callback: (success, response) => {
        if (success) {
          const updatedAdServer = { ...adServer, name: response.name }
          get().setAdServer(updatedAdServer)
          callback(success, response)
        } else {
          callback(false, null, convertApiToStoreError(response))
        }
      },
    })
  },
  addAdServerConfiguration: async (adServer, data, token, etag, setEtag, callback) => {
    apiPost({
      path: `/setting/ad-server/${adServer.id}/add-configuration`,
      data,
      etag,
      setEtag,
      token,
      callback: (success, response) => {
        if (success) {
          const updatedConfiguration = [...(adServer.configuration || []), response.configuration]
          const updatedAdServer = { ...adServer, configuration: updatedConfiguration }

          get().setAdServer(updatedAdServer)
          callback(success, response)
        } else {
          callback(false, null, convertApiToStoreError(response))
        }
      },
    })
  },
  updateAdServerConfiguration: async (
    adServer,
    data,
    configurationId,
    token,
    etag,
    setEtag,
    callback
  ) => {
    apiPut({
      path: `/setting/ad-server/${adServer.id}/add-configuration/${configurationId}`,
      data,
      etag,
      setEtag,
      token,
      callback: (success, response) => {
        if (success) {
          const configuration = adServer.configuration || []
          const updatedConfiguration = configuration.map((config) => {
            if (config.id === configurationId) {
              return response.configuration
            }

            return config
          })

          const updatedAdServer = { ...adServer, configuration: updatedConfiguration }
          get().setAdServer(updatedAdServer)
          callback(success, response)
        } else {
          callback(false, null, convertApiToStoreError(response))
        }
      },
    })
  },
  deleteAdServerConfiguration: async (
    adServer,
    configurationId,
    token,
    etag,
    setEtag,
    callback
  ) => {
    apiDelete({
      path: `/setting/ad-server/${adServer.id}/add-configuration/${configurationId}`,
      etag,
      setEtag,
      callback: (success, response) => {
        if (success) {
          const newConfigurations = !adServer.configuration
            ? []
            : adServer.configuration.filter((config) => config.id !== configurationId)
          const updatedAdServer = { ...adServer, configuration: newConfigurations }
          get().setAdServer(updatedAdServer)
          callback(success, null)
        } else {
          callback(false, null, convertApiToStoreError(response))
        }
      },
      token,
    })
  },
  adServerReset: () => set({ adServer: null, adServers: [] }),
})
