import { useForm } from 'react-hook-form'
import { Form } from '@/components/ui/form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { DialogFooter } from '@/components/ui/dialog'
import { useState } from 'react'
import {
  AdServerConfiguration,
  AdServerConfigurationConditional,
  AdServerConfigurationExists,
  AdServerConfigurationPlaceholder,
  AdServerConfigurationText,
} from '@/pages/adServers'
import { toast } from '@/components/ui/use-toast'
import {
  FormTypeText,
  formTypeTextSchema,
  getFormTypeTextDefaults,
  setFormTypeTextData,
} from '@/pages/adServerEdit/components/formTypeText'
import {
  FormTypePlaceholder,
  formTypePlaceholderSchema,
  getFormTypePlaceholderDefaults,
  setFormTypePlaceholderData,
} from '@/pages/adServerEdit/components/formTypePlaceholder'
import {
  formTypeExistsSchema,
  FormTypeIfExists,
  getFormTypeExistsDefaults,
  setFormTypeExistsData,
} from '@/pages/adServerEdit/components/formTypeIfExists'
import {
  FormTypeConditional,
  formTypeConditionalSchema,
  getFormTypeConditionalDefaults,
  setFormTypeConditionalData,
} from '@/pages/adServerEdit/components/formTypeConditional'
import { ScrollArea } from '@/components/ui/scroll-area'
import { SubmitButton } from '@/components/submitButton'
import { CreateAnotherEntryToggle } from '@/components/createAnotherEntryToogle'

interface Props {
  formType: 'text' | 'placeholder' | 'conditional' | 'exists'
  formValues?: AdServerConfiguration
  onCreate: (data: any, callback: (result: boolean, value: string) => void) => void
  onUpdate: (
    data: any,
    callback: (result: boolean, value: string) => void,
    configurationId: string
  ) => void
  onSaved: (addAnotherEntry: boolean) => void
}

export const FormByType = ({ formType, onCreate, onUpdate, onSaved, formValues }: Props) => {
  const [isSaving, setIsSaving] = useState(false)
  const [addAnotherEntry, setAddAnotherEntry] = useState(false)

  let formSchema
  let defaultValues

  switch (formType) {
    case 'text':
      formSchema = formTypeTextSchema
      defaultValues = getFormTypeTextDefaults(formValues as AdServerConfigurationText)
      break
    case 'placeholder':
      formSchema = formTypePlaceholderSchema
      defaultValues = getFormTypePlaceholderDefaults(formValues as AdServerConfigurationPlaceholder)
      break
    case 'exists':
      formSchema = formTypeExistsSchema
      defaultValues = getFormTypeExistsDefaults(formValues as AdServerConfigurationExists)
      break
    case 'conditional':
      formSchema = formTypeConditionalSchema
      defaultValues = getFormTypeConditionalDefaults(formValues as AdServerConfigurationConditional)
      break
    default:
      formSchema = formTypeTextSchema
      defaultValues = getFormTypeTextDefaults(formValues as AdServerConfigurationText)
      break
  }

  const form: any = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
    mode: 'all',
  })

  const formCallback = (result: boolean, _value: string) => {
    setIsSaving(false)
    if (result) {
      onSaved(addAnotherEntry)
      form.setFocus('targetKey')
      form.reset()
    } else {
      toast({
        variant: 'destructive',
        title: 'Failed',
        description: `Could not save the configuration.`,
      })
    }
  }

  const getFormDataForSubmit = (newFormvalues: any) => {
    switch (formType) {
      case 'text':
        return setFormTypeTextData(newFormvalues)
      case 'placeholder':
        return setFormTypePlaceholderData(newFormvalues)
      case 'exists':
        return setFormTypeExistsData(newFormvalues)
      case 'conditional':
        return setFormTypeConditionalData(newFormvalues)
    }
  }

  const submitForm = () => {
    const newFormValues = form.getValues()
    const data = getFormDataForSubmit(newFormValues)

    setIsSaving(true)

    if (!formValues) {
      onCreate(data, formCallback)
      return
    }

    onUpdate(data, formCallback, formValues.id)
  }

  const toggleAddAnotherEntry = () => {
    setAddAnotherEntry(!addAnotherEntry)
  }

  const renderForm = () => {
    switch (formType) {
      case 'text':
        return <FormTypeText form={form} formValues={formValues as AdServerConfigurationText} />
      case 'placeholder':
        return (
          <FormTypePlaceholder
            form={form}
            formValues={formValues as AdServerConfigurationPlaceholder}
          />
        )
      case 'exists':
        return (
          <FormTypeIfExists form={form} formValues={formValues as AdServerConfigurationExists} />
        )
      case 'conditional':
        return (
          <FormTypeConditional
            form={form}
            formValues={formValues as AdServerConfigurationConditional}
          />
        )
    }
  }

  return (
    <>
      <ScrollArea className="h-[85vh]">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(submitForm)} className="space-y-8 m-2">
            {renderForm()}

            <DialogFooter>
              <CreateAnotherEntryToggle
                addAnotherEntry={addAnotherEntry}
                toggleAddAnotherEntry={toggleAddAnotherEntry}
              />
              <SubmitButton isSaving={isSaving} label="Save" />
            </DialogFooter>
          </form>
        </Form>
      </ScrollArea>
    </>
  )
}
