import { Layout } from '@/components/layout'
import { useBoundStore } from '@/store'
import { ServerTable } from '@/pages/adServers/components/serverTable'
import { useEffect } from 'react'
import { CreateTile } from '@/components/createTile'
import { useToken } from '@/hooks/useToken'

export type AdServerConfigurationType = 'text' | 'placeholder' | 'conditional' | 'exists'

export type AdServerConfigurationText = {
  id: string
  config: {
    type: AdServerConfigurationType
    targetKey: string
    targetValue: string | number | boolean | null
  }
}

export type AdServerConfigurationPlaceholder = {
  id: string
  config: {
    type: AdServerConfigurationType
    targetKey: string
    targetValue: string | number | boolean | null
    skipIfEmpty: boolean
  }
}

export type AdServerConfigurationConditional = {
  id: string
  config: {
    type: AdServerConfigurationType
    key: string
    valueMapping: Record<string, string | number | boolean>[]
    targetKey: string
    default: string | number | boolean | null
  }
}

export type AdServerConfigurationExists = {
  id: string
  config: {
    type: AdServerConfigurationType
    key: string
    targetKey: string
    targetValue: string | number | boolean | null
    default: string | number | boolean | null
  }
}

export type AdServerConfiguration =
  | AdServerConfigurationText
  | AdServerConfigurationPlaceholder
  | AdServerConfigurationConditional
  | AdServerConfigurationExists

export type AdServer = {
  id: string
  name: string
  baseUrl: string
  enabled: boolean
  isDefault: boolean
  revision: number
  configuration?: AdServerConfiguration[]
}

export const AdServers = () => {
  const { getClerkToken } = useToken()
  const adServers = useBoundStore((state) => state.adServers)
  const getAdServers = useBoundStore((state) => state.getAdServers)
  const createServer = useBoundStore((state) => state.createServer)

  useEffect(() => {
    const fetchServers = async () => {
      const token = await getClerkToken()
      await getAdServers(token)
    }

    fetchServers()
  }, [getClerkToken, getAdServers])

  return (
    <Layout>
      <div>
        <h1 className="text-5xl">Ad Servers</h1>

        <div className="flex gap-20 mt-20">
          <div>
            <CreateTile
              label="Ad Server"
              number={adServers.length}
              dialogButtonText="Create"
              dialogDescription="This will create a new ad server. You can add new configurations and activate it after it's created."
              dialogLabel="Name"
              dialogTitle="Create Ad Server"
              dialogTriggerText="Create Ad Server"
              postEndpoint="/setting/ad-server"
              storeAction={createServer}
              redirectPath="/ad-servers"
            />
          </div>

          <ServerTable adServers={adServers} />
        </div>
      </div>
    </Layout>
  )
}
