import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useToast } from '@/components/ui/use-toast'
import { apiPost } from '@/lib/api'
import { useState } from 'react'
import { SubmitButton } from '@/components/submitButton'
import { useNavigate } from 'react-router-dom'
import { useToken } from '@/hooks/useToken'

type Inputs = {
  name: string
}

export interface Props {
  title: string
  description: string
  label: string
  buttonText: string
  triggerText: string
  postEndpoint: string
  storeAction: (data: any) => void
  redirectPath?: string
}

export const CreateByName = ({
  triggerText,
  title,
  description,
  label,
  buttonText,
  postEndpoint,
  storeAction,
  redirectPath,
}: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()
  const { getClerkToken } = useToken()
  const { toast } = useToast()
  const navigate = useNavigate()

  const [open, setOpen] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const callback = (result: boolean, data: any) => {
    setIsSaving(false)
    if (!result) {
      toast({
        title: 'Created',
        description: `${data.name} has been created.`,
      })

      return
    }

    storeAction(data)
    setOpen(false)

    if (redirectPath) {
      navigate(`${redirectPath}/${data.id}`)
    }

    toast({
      title: 'Created',
      description: `${data.name} has been created.`,
    })
  }

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const token = await getClerkToken()
    setIsSaving(true)
    await apiPost({
      path: postEndpoint,
      data,
      callback,
      token,
    })
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button>{triggerText}</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name" className="text-right">
                {label}
              </Label>
              <Input {...register('name', { required: true })} className="col-span-3" />
              {errors.name && <span>This field is required</span>}
            </div>
          </div>
          <DialogFooter>
            <SubmitButton label={buttonText} isSaving={isSaving} />
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}
