import { Loader2 } from 'lucide-react'

export const PageLoadingIndicator = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <Loader2 className="w-12 h-12 text-gray-300 animate-spin" />
      <span className="mt-4 text-gray-500">Loading...</span>
    </div>
  )
}
