import { Layout } from '@/components/layout'
import { useBoundStore } from '@/store'
import { useEffect } from 'react'
import { useToken } from '@/hooks/useToken'
import Chart from 'react-apexcharts'
import { BillingQuota } from '@/store/billing'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { InfoIcon } from 'lucide-react'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'

export const QuotaPage = () => {
  const { getClerkToken } = useToken()
  const currentQuota = useBoundStore((state) => state.currentQuota)
  const upcomingCosts = useBoundStore((state) => state.upcomingCosts)
  const quotaList = useBoundStore((state) => state.quotaList)
  const getQuota = useBoundStore((state) => state.getQuota)
  const quotaReset = useBoundStore((state) => state.quotaReset)
  const getCosts = useBoundStore((state) => state.getCosts)

  const labels = [
    { key: 'adImpression', label: 'Ad Impression' },
    { key: 'killSwitch', label: 'Kill Switches' },
  ]

  const renderChart = (dataList: BillingQuota[]) => {
    const chartData: any[] = []
    let chartCategories: any[] = []

    dataList.forEach((quota) => {
      chartData.push({
        name: labels.find((label) => label.key === quota.type)?.label,
        data: quota.quotas.map((quota) => quota.quota),
      })

      chartCategories = quota.quotas.map((quota) => quota.period)
    })

    const chartOptions = {
      chart: {
        id: 'basic-bar',
      },
      xaxis: {
        categories: chartCategories,
      },
    }

    return (
      <Chart options={chartOptions} series={chartData} type="bar" width="100%" height="450px" />
    )
  }

  const renderNoQuota = () => {
    if (!currentQuota || currentQuota.length === 0) {
      return (
        <div className="mt-10 w-1/4">
          <Alert>
            <InfoIcon className="h-4 w-4" />
            <AlertTitle>No data available</AlertTitle>
            <AlertDescription>
              The information on this page will be updated as you use the RapidStream service
            </AlertDescription>
          </Alert>
        </div>
      )
    }
  }

  useEffect(() => {
    const fetchQuota = async () => {
      const token = await getClerkToken()
      await quotaReset()
      await getQuota(token, 'ad-impression')
      await getQuota(token, 'kill-switch')
      await getCosts(token)
    }
    fetchQuota()
  }, [getQuota, getCosts, quotaReset, getClerkToken])

  return (
    <Layout>
      <div>
        <h1 className="text-5xl">Quota</h1>
        {renderNoQuota()}

        <div className="grid grid-cols-4 gap-1 mt-10">
          {currentQuota?.map((quota) => {
            const upcomingCost = upcomingCosts.find((cost) => cost.name === quota.type)?.amount
            const currency = upcomingCosts.find((cost) => cost.name === quota.type)?.currency

            return (
              <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                  <CardTitle className="text-sm font-medium">
                    {labels.find((label) => label.key === quota.type)?.label ?? ''}
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="text-2xl font-bold">{quota.quotas[0].quota}</div>
                  <hr className="my-2" />
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        {upcomingCost} {currency?.toUpperCase()}
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Estimated costs for the current billing period</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </CardContent>
              </Card>
            )
          })}
        </div>

        {quotaList.length > 0 && <div className="mt-10">{renderChart(quotaList)}</div>}
      </div>
    </Layout>
  )
}
