import { FormControl, FormDescription, FormField, FormItem, FormLabel } from '@/components/ui/form'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'
import { Check, ChevronsUpDown } from 'lucide-react'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandSeparator,
} from '@/components/ui/command'
import { useBoundStore } from '@/store'
import { useEffect, useState } from 'react'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { FormFieldError } from '@/components/formFieldError'
import { useToken } from '@/hooks/useToken'

interface Props {
  form: any
  context: string
  fieldName: string
  fieldLabel: string
  disabledTags?: string[]
  description?: string
  readonly?: boolean
  onSelect?: (value: string) => void
}

export type ComboboxEntry = {
  label: string
  value: string
  group?: string
}

export const TagComboBox = ({
  form,
  context,
  fieldName,
  fieldLabel,
  disabledTags,
  description,
  readonly,
  onSelect,
}: Props) => {
  const { getClerkToken } = useToken()

  const tags = useBoundStore((state) => state.tags)
  const getTags = useBoundStore((state) => state.getTags)
  const addTag = useBoundStore((state) => state.addTag)

  const [tagData, setTagData] = useState<ComboboxEntry[]>()

  const createNewTag = async (event: any) => {
    if (event.key === 'Enter' && event.target instanceof HTMLInputElement) {
      const token = await getClerkToken()

      addTag(event.target.value, context, token, () => {
        form.setValue(fieldName, event.target.value)
      })
    }
  }

  useEffect(() => {
    const fetchServers = async () => {
      const token = await getClerkToken()
      getTags(token)
    }

    fetchServers()
  }, [getClerkToken, getTags])

  useEffect(() => {
    const tagsByContext = tags.tags.filter((tag) => tag.context === context)[0]

    if (!tagsByContext) {
      return
    }

    const userTags = tagsByContext.userTags.filter((tag) => !disabledTags?.includes(tag))
    const systemTags = tagsByContext.systemTags.filter((tag) => !disabledTags?.includes(tag))

    const newUserTagData = userTags.map((tag) => {
      return {
        label: tag,
        value: tag,
        group: 'user',
      }
    })

    const newSystemTagData = systemTags.map((tag) => {
      return {
        label: tag,
        value: tag,
        group: 'system',
      }
    })

    const newTags = !readonly ? [...newUserTagData, ...newSystemTagData] : newSystemTagData

    setTagData(newTags)
  }, [setTagData, tags, context, disabledTags, readonly])

  if (!tagData) {
    return (
      <Alert variant="destructive">
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>
          We could not load the needed data for the {fieldLabel} field. Please try again.
        </AlertDescription>
      </Alert>
    )
  }

  return (
    <FormField
      control={form.control}
      name={fieldName}
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel>{fieldLabel}</FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  className={cn(
                    'w-[200px] justify-between',
                    !field.value && 'text-muted-foreground'
                  )}
                >
                  {field.value
                    ? tagData.find((tag) => tag.value === field.value)?.label
                    : `Select a ${fieldLabel}`}
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[200px] p-0">
              <Command>
                <CommandInput placeholder={`Search ${fieldLabel}...`} onKeyUp={createNewTag} />
                <CommandEmpty>{!readonly ? 'Press enter to create' : 'No results'}</CommandEmpty>
                {!readonly && (
                  <CommandGroup heading="Custom">
                    {tagData
                      .filter((tag) => tag.group === 'user')
                      .map((tag) => (
                        <CommandItem
                          value={tag.value}
                          key={tag.value}
                          onSelect={() => {
                            form.setValue(fieldName, tag.value)
                          }}
                        >
                          <Check
                            className={cn(
                              'mr-2 h-4 w-4',
                              tag.value === field.value ? 'opacity-100' : 'opacity-0'
                            )}
                          />
                          {tag.label}
                        </CommandItem>
                      ))}
                  </CommandGroup>
                )}
                <CommandSeparator />
                <CommandGroup heading="System">
                  {tagData
                    .filter((tag) => tag.group === 'system')
                    .map((tag) => (
                      <CommandItem
                        value={tag.value}
                        key={tag.value}
                        onSelect={() => {
                          onSelect && onSelect(tag.value)
                          form.setValue(fieldName, tag.value)
                        }}
                      >
                        <Check
                          className={cn(
                            'mr-2 h-4 w-4',
                            tag.value === field.value ? 'opacity-100' : 'opacity-0'
                          )}
                        />
                        {tag.label}
                      </CommandItem>
                    ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormDescription>
            <FormFieldError>{description}</FormFieldError>
          </FormDescription>
        </FormItem>
      )}
    />
  )
}
