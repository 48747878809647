import { Layout } from '@/components/layout'
import { DataTile } from '@/components/dataTile'
import { useEffect, useState } from 'react'
import { toast } from '@/components/ui/use-toast'
import { TodoList } from '@/pages/dashboard/components/todoList'
import { useBoundStore } from '@/store'
import { DataTileIngest, EpgCatalog } from '@/components/dataTileIngest'
import { useToken } from '@/hooks/useToken'
import { useOrganization } from '@clerk/clerk-react'
import env from '@beam-australia/react-env'

export const Dashboard = () => {
  const featureFastChannel = env('FEATURE_FAST_CHANNEL') === 'true'
  const { getClerkToken } = useToken()
  const { organization } = useOrganization()

  const dashboard = useBoundStore((state) => state.dashboard)
  const getDashboard = useBoundStore((state) => state.getDashboard)

  const [fastChannelCount, setFastChannelCount] = useState<number>(0)
  const [adRuleCount, setAdRuleCount] = useState<number>(0)
  const [adServerCount, setAdServerCount] = useState<number>(0)
  const [killSwitchCount, setKillSwitchCount] = useState<number>(0)
  const [epgCatalog, setEpgCatalog] = useState<EpgCatalog>({
    count: 0,
    lastUpdated: undefined,
  })

  useEffect(() => {
    const fetchServers = async () => {
      const token = await getClerkToken()

      getDashboard(token, (success, response, error) => {
        if (!success) {
          toast({
            variant: 'destructive',
            title: error?.title || 'Failed',
            description: error?.detail || 'Could not load dashboard data',
          })
        }
      })
    }

    fetchServers()
  }, [getClerkToken, getDashboard])

  useEffect(() => {
    const channels = !dashboard || !dashboard.fastChannels ? 0 : dashboard?.fastChannels.count
    const adRules = !dashboard || !dashboard.adRules ? 0 : dashboard?.adRules.count
    const adServers = !dashboard || !dashboard.adServers ? 0 : dashboard?.adServers.count
    const killSwitches = !dashboard || !dashboard.killSwitches ? 0 : dashboard?.killSwitches.count

    if (dashboard && dashboard.epgCatalog) {
      const lastUpdated = dashboard.epgCatalog.lastIngest?.dateTime
        ? new Date(dashboard.epgCatalog.lastIngest.dateTime)
        : undefined

      const newEpgCatalog = {
        count: dashboard.epgCatalog.count,
        lastUpdated: lastUpdated,
        status: dashboard.epgCatalog.lastIngest?.status,
        error: dashboard.epgCatalog.lastIngest?.errorMessage,
      }

      setEpgCatalog(newEpgCatalog)
    }

    setFastChannelCount(channels)
    setAdRuleCount(adRules)
    setAdServerCount(adServers)
    setKillSwitchCount(killSwitches)
  }, [dashboard])

  return (
    <Layout>
      <div>
        <h1 className="text-5xl">Dashboard</h1>

        <div className="flex flex-row space-x-4 mt-10">
          {featureFastChannel && (
            <DataTile title="FAST Channels" count={fastChannelCount} link="fast-channels" />
          )}
          <DataTile title="Ad Servers" count={adServerCount} link="ad-servers" />
          <DataTile title="Ad Rules" count={adRuleCount} link="ad-rules" />
          <DataTile title="Kill Switches" count={killSwitchCount} link="kill-switches" />
        </div>

        <div className="flex flex-row space-x-4 mt-4 mr-11">
          <DataTileIngest title="EPG Catalog" data={epgCatalog} />
        </div>

        <div className="mt-10">
          <TodoList
            adRuleCount={adRuleCount}
            adServerCount={adServerCount}
            killSwitchCount={killSwitchCount}
            ingestCount={dashboard?.epgCatalog?.lastIngest || null}
            stripeEmail={!!organization?.publicMetadata.paymentEmailAddress}
            stripePaymentMethod={!!organization?.publicMetadata.isPaymentMethodAvailable}
          />
        </div>
      </div>
    </Layout>
  )
}
