import { useForm } from 'react-hook-form'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { z } from 'zod'
import { useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from '@/components/ui/use-toast'
import { DialogFooter } from '@/components/ui/dialog'
import { SubmitButton } from '@/components/submitButton'
import { TagsInput } from 'react-tag-input-component'
import { TagComboBox } from '@/components/tagComboBox'
import { KillSwitchDefinition } from '@/pages/adRules'
import { CreateAnotherEntryToggle } from '@/components/createAnotherEntryToogle'
import { TagCheckboxGroup } from '@/components/tagCheckboxGroup'

interface Props {
  formValues?: KillSwitchDefinition
  usedDefinitions?: string[]
  onCreate: (data: any, callback: (result: boolean, value: string) => void) => void
  onSaved: (addAnotherEntry: boolean) => void
}

export const DefinitionForm = ({ formValues, onCreate, onSaved, usedDefinitions }: Props) => {
  const [isSaving, setIsSaving] = useState(false)
  const [addAnotherEntry, setAddAnotherEntry] = useState(false)
  const [createdTags, setCreatedTags] = useState<string[]>([])
  const [selectedKey, setSelectedKey] = useState<string>()

  const formSchema = z.object({
    key: z.string(),
    values: z.array(z.any()),
  })

  const form: any = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      key: formValues?.key ?? '',
      values: formValues?.values ?? [],
    },
    mode: 'all',
  })

  const formCallback = (result: boolean, _value: string) => {
    setIsSaving(false)
    if (result) {
      onSaved(addAnotherEntry)
      form.setFocus('key')
      form.reset()
      setCreatedTags([])
    } else {
      toast({
        variant: 'destructive',
        title: 'Failed',
        description: `Could not save the configuration.`,
      })
    }
  }

  const submitForm = (data: KillSwitchDefinition) => {
    setIsSaving(true)
    onCreate(data, formCallback)
  }

  const toggleAddAnotherEntry = () => {
    setAddAnotherEntry(!addAnotherEntry)
  }

  const updateTags = (tags: string[]) => {
    form.setValue('values', tags)
  }

  const onKeySelect = (value: string) => {
    setSelectedKey(value)
  }

  useEffect(() => {
    setCreatedTags(formValues?.values || [])
    setSelectedKey(formValues?.key || '')
  }, [setCreatedTags, formValues])

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(submitForm)} className="space-y-8">
          <TagComboBox
            form={form}
            context="adServer"
            fieldName="key"
            fieldLabel="Key"
            onSelect={onKeySelect}
          />

          {selectedKey === 'userAgent' ? (
            <div>
              <TagCheckboxGroup
                form={form}
                context="userAgent"
                fieldName="values"
                fieldLabel="userAgent"
              />
            </div>
          ) : (
            <FormField
              control={form.control}
              name="values"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Values</FormLabel>
                  <FormControl>
                    <TagsInput
                      {...form.register('values', {
                        required: true,
                      })}
                      onChange={updateTags}
                      placeHolder="Values"
                      value={createdTags}
                    />
                  </FormControl>
                  <FormDescription>Press enter to add values</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}

          <DialogFooter>
            <CreateAnotherEntryToggle
              addAnotherEntry={addAnotherEntry}
              toggleAddAnotherEntry={toggleAddAnotherEntry}
            />
            <SubmitButton isSaving={isSaving} label="Save" />
          </DialogFooter>
        </form>
      </Form>
    </>
  )
}
