import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { SubmitButton } from '@/components/submitButton'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { apiPost } from '@/lib/api'
import { useToken } from '@/hooks/useToken'
import env from '@beam-australia/react-env'
import { toast } from '@/components/ui/use-toast'

type Inputs = {
  email: string
}

export const PaymentReminder = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()
  const { getClerkToken } = useToken()
  const [open, setOpen] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const token = await getClerkToken()
    setIsSaving(true)

    await apiPost({
      path: '/billing/set-payment-email',
      data: {
        email: data.email,
      },
      callback: (success) => {
        setIsSaving(false)

        if (!success) {
          toast({
            variant: 'destructive',
            title: 'Failed',
            description: `Your email address could not be saved. Please try again or contact support.`,
          })

          return
        }

        if (success) {
          setOpen(false)
          const encodedEmail = encodeURIComponent(data.email)
          window.open(`${env('STRIPE_ACCOUNT_URL')}?prefilled_email=${encodedEmail}`, '_blank')
        }
      },
      token,
    })
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button data-umami-event="click-reminder-set-email">Set Email address</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Enter Email</DialogTitle>
          <DialogDescription>
            Enter an email address for your payment account. You will be redirected to our payment
            provider afterwards.
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="email" className="text-right">
                Email
              </Label>
              <Input
                {...register('email', { required: true })}
                className="col-span-3"
                type="email"
              />
              {errors.email && <span>This field is required</span>}
            </div>
          </div>
          <DialogFooter>
            <SubmitButton
              label="Save"
              isSaving={isSaving}
              data-umami-event="click-reminder-send-email"
            />
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}
