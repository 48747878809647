import { FormControl, FormItem, FormLabel } from '@/components/ui/form'
import { Switch } from '@/components/ui/switch'

export interface Props {
  addAnotherEntry: boolean
  toggleAddAnotherEntry: () => void
}

export const CreateAnotherEntryToggle = ({ addAnotherEntry, toggleAddAnotherEntry }: Props) => {
  return (
    <FormItem className="flex items-center gap-2">
      <FormLabel className="mt-1.5">Add another entry</FormLabel>
      <FormControl>
        <Switch checked={addAnotherEntry} onCheckedChange={toggleAddAnotherEntry} />
      </FormControl>
    </FormItem>
  )
}
