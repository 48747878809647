import { UseFormReturn } from 'react-hook-form'
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { z } from 'zod'
import { Input } from '@/components/ui/input'
import { Checkbox } from '@/components/ui/checkbox'
import { AdServerConfigurationPlaceholder } from '@/pages/adServers'
import { PlaceholderAutocomplete } from '@/components/placeholderAutocomplete'

interface Props {
  formValues?: AdServerConfigurationPlaceholder
  form: UseFormReturn<
    { type: string; targetKey: string; targetValue?: any; skipIfEmpty: boolean },
    any,
    undefined
  >
}

export const formTypePlaceholderSchema = z.object({
  type: z.string(),
  targetKey: z.string(),
  targetValue: z.any(),
  skipIfEmpty: z.boolean(),
})

export const getFormTypePlaceholderDefaults = (formValues?: AdServerConfigurationPlaceholder) => {
  return {
    type: 'text',
    targetKey: formValues?.config.targetKey ?? '',
    targetValue: formValues?.config.targetValue ?? '',
    skipIfEmpty: formValues?.config.skipIfEmpty ?? false,
  }
}

export const setFormTypePlaceholderData = (newFormValues: any) => {
  return {
    config: {
      targetKey: newFormValues.targetKey,
      targetValue: newFormValues.targetValue,
      skipIfEmpty: newFormValues.skipIfEmpty,
    },
    type: 'placeholder',
  }
}

export const FormTypePlaceholder = ({ form }: Props) => {
  return (
    <>
      <FormField
        control={form.control}
        name="targetKey"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Target Key</FormLabel>
            <FormControl>
              <Input
                {...form.register('targetKey', {
                  required: true,
                })}
                placeholder="Key"
              />
            </FormControl>
            <FormDescription>
              The URL key, for example <code>gdpr_consent</code>
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />

      <PlaceholderAutocomplete
        form={form}
        context="adServer"
        fieldName="targetValue"
        fieldLabel="Target Value"
        fieldDescription="Use { } to reference a placeholder. For example {gdpr_consent}"
      />
      <FormField
        control={form.control}
        name="skipIfEmpty"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Checkbox
                {...form.register('skipIfEmpty')}
                onCheckedChange={field.onChange}
                checked={field.value}
              />
            </FormControl>
            <FormLabel className="ml-2 relative -top-0.5">Skip if empty</FormLabel>
            <FormDescription>
              If the target key is not present, no value will be set
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  )
}
