import { DataTable } from '@/components/dataTable'
import { ColumnDef } from '@tanstack/react-table'
import { AdServer } from '@/pages/adServers'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Button } from '@/components/ui/button'
import { ArrowUpDown, MoreHorizontal } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { StoreError, useBoundStore } from '@/store'
import { Badge } from '@/components/ui/badge'
import { useErrorHandler } from '@/hooks/useErrorHandler'
import { useToken } from '@/hooks/useToken'

export interface Props {
  adServers: AdServer[]
}

export const ServerTable = ({ adServers }: Props) => {
  const { getClerkToken } = useToken()
  const navigate = useNavigate()
  const { handleError } = useErrorHandler()

  const deleteAdServer = useBoundStore((state) => state.deleteServer)
  const getAdServers = useBoundStore((state) => state.getAdServers)

  const onDeleteServer = async (id: string) => {
    const token = await getClerkToken()

    deleteAdServer(id, token, (success: boolean, response: any, error?: StoreError) => {
      handleError(
        success,
        () => {
          getAdServers(token)
        },
        error
      )
    })
  }

  const onSelectAdServer = (adServer: AdServer) => {
    navigate(`/ad-servers/${adServer.id}`)
  }

  const columns: ColumnDef<AdServer>[] = [
    {
      accessorKey: 'id',
      header: 'Server Id',
    },
    {
      accessorKey: 'name',
      enableHiding: false,
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Name
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
    },
    {
      accessorKey: 'baseUrl',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Base Url
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
    },
    {
      accessorKey: 'enabled',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Enabled
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
      cell: ({ row }) => {
        const badgeVariant = row.getValue('enabled') ? 'secondary' : 'destructive'
        return <Badge variant={badgeVariant}>{row.getValue('enabled') ? 'Yes' : 'No'}</Badge>
      },
    },
    {
      accessorKey: 'isDefault',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Default
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        )
      },
      cell: ({ row }) => {
        const badgeVariant = row.getValue('isDefault') ? 'secondary' : 'outline'
        return <Badge variant={badgeVariant}>{row.getValue('isDefault') ? 'Yes' : 'No'}</Badge>
      },
    },
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => {
        const adServerId: string = row.getValue('id')

        return (
          <div className="flex justify-center">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <DropdownMenuItem
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    navigate(`/ad-servers/${adServerId}`)
                  }}
                >
                  Edit configuration
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  className="text-red-500"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onDeleteServer(adServerId)
                  }}
                >
                  Delete Server
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )
      },
    },
  ]

  return <DataTable columns={columns} data={adServers} onSelect={onSelectAdServer} />
}
