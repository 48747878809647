import { Layout } from '@/components/layout'

import { useFormspark } from '@formspark/use-formspark'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import env from '@beam-australia/react-env'
import { Textarea } from '@/components/ui/textarea'
import { useOrganization, useUser } from '@clerk/clerk-react'
import { SubmitButton } from '@/components/submitButton'
import { useState } from 'react'
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert'
import { CrossIcon, SendIcon } from 'lucide-react'

export const AccountPage = () => {
  const { user } = useUser()
  const { organization } = useOrganization()

  const [submit, submitting] = useFormspark({
    formId: env('FORM_SPARK_ID'),
  })

  const [messageSent, setMessageSent] = useState<boolean | null>(null)

  const formSchema = z.object({
    message: z.string(),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      message: '',
    },
    mode: 'all',
  })

  const onSubmit = async (e: any) => {
    setMessageSent(false)
    await submit({
      message: form.getValues('message'),
      name: user?.fullName,
      email: user?.primaryEmailAddress?.emailAddress,
      orgId: organization?.id,
    })
      .then(() => {
        setMessageSent(true)
        form.reset()
      })
      .catch(() => {
        setMessageSent(false)
      })
  }

  const renderMessageSent = () => {
    if (messageSent)
      return (
        <Alert className="my-10">
          <SendIcon className="h-4 w-4" />
          <AlertTitle>Sent!</AlertTitle>
          <AlertDescription>
            Thank you for your message. We will get back to you as soon as possible.
          </AlertDescription>
        </Alert>
      )

    if (messageSent === false && !submitting) {
      return (
        <Alert variant="destructive" className="my-10">
          <CrossIcon className="h-4 w-4" />
          <AlertTitle>Failed</AlertTitle>
          <AlertDescription>
            Your message could not be sent. Please try again later.
          </AlertDescription>
        </Alert>
      )
    }

    return null
  }

  return (
    <Layout>
      <div className="w-1/2">
        <h1 className="text-5xl">Account</h1>

        <div className="my-10">Need help with your account? We're here to help.</div>

        {renderMessageSent()}

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="message"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Your message:</FormLabel>
                  <FormControl>
                    <Textarea
                      {...form.register('message', {
                        required: true,
                      })}
                      placeholder="Enter your message"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <SubmitButton isSaving={submitting} label="Send" />
          </form>
        </Form>
      </div>
    </Layout>
  )
}
