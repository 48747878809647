import { UseFormReturn } from 'react-hook-form'
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { z } from 'zod'
import { Input } from '@/components/ui/input'
import { AdServerConfigurationExists } from '@/pages/adServers'
import { PlaceholderAutocomplete } from '@/components/placeholderAutocomplete'

interface Props {
  formValues?: AdServerConfigurationExists
  form: UseFormReturn<
    {
      type: string
      key: string
      targetKey: string
      targetValue: string | number | boolean | null
      defaultValue: string | number | boolean | null
    },
    any,
    undefined
  >
}

export const formTypeExistsSchema = z.object({
  type: z.string(),
  key: z.string(),
  targetKey: z.string(),
  targetValue: z.any(),
  defaultValue: z.any(),
})

export const getFormTypeExistsDefaults = (formValues?: AdServerConfigurationExists) => {
  return {
    type: 'exists',
    key: formValues?.config.key ?? '',
    targetKey: formValues?.config.targetKey ?? '',
    targetValue: formValues?.config.targetValue ?? '',
    defaultValue: formValues?.config.default ?? null,
  }
}

export const setFormTypeExistsData = (newFormValues: any) => {
  return {
    config: {
      key: newFormValues.key,
      targetKey: newFormValues.targetKey,
      targetValue: newFormValues.targetValue,
      default: newFormValues.defaultValue ? newFormValues.defaultValue : null,
    },
    type: 'exists',
  }
}
export const FormTypeIfExists = ({ form }: Props) => {
  return (
    <>
      <PlaceholderAutocomplete
        form={form}
        context="adServer"
        fieldName="key"
        fieldLabel="Key"
        fieldDescription="This system will check if the key exists. Use { } to reference a placeholder. For example {gdpr_consent}"
      />
      <FormField
        control={form.control}
        name="targetKey"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Target Key</FormLabel>
            <FormControl>
              <Input
                {...form.register('targetKey', {
                  required: true,
                })}
                placeholder="Key"
              />
            </FormControl>
            <FormDescription>
              The URL key, for example <code>gdpr_consent</code>
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="targetValue"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Target value</FormLabel>
            <FormControl>
              <Input
                {...form.register('targetValue', {
                  required: true,
                })}
                placeholder="Value"
              />
            </FormControl>
            <FormDescription>
              The placeholder for the value, for example <code>{'{consent}'}</code>
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="defaultValue"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Default value</FormLabel>
            <FormControl>
              <Input
                {...form.register('defaultValue', {
                  required: true,
                })}
                placeholder="Value"
              />
            </FormControl>
            <FormDescription>
              If the lookup key is not found, this value will be used. Leave empty to remove the
              key.
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  )
}
