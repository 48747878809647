import { Layout } from '@/components/layout'
import { useToast } from '@/components/ui/use-toast'
import { useBoundStore } from '@/store'
import { useEffect } from 'react'
import { CreateTile } from '@/components/createTile'
import { PageLoadingIndicator } from '@/components/pageLoadingIndicator'
import { FastChannelTable } from '@/pages/fastChannels/fastChannelTable'
import { useToken } from '@/hooks/useToken'

export type FastChannel = {
  id: string
  revision: 0
  name: string
  enabled: boolean
  externalId: string
}

export const FastChannels = () => {
  const { getClerkToken } = useToken()
  const { toast } = useToast()
  const fastChannels = useBoundStore((state) => state.fastChannels)
  const getFastChannels = useBoundStore((state) => state.getFastChannels)
  const createFastChannel = useBoundStore((state) => state.createFastChannel)

  useEffect(() => {
    const fetchServers = async () => {
      const token = await getClerkToken()
      await getFastChannels(token)
    }

    fetchServers()
  }, [getClerkToken, toast, getFastChannels])

  if (!fastChannels) {
    return (
      <Layout>
        <PageLoadingIndicator />
      </Layout>
    )
  }

  return (
    <Layout>
      <div>
        <h1 className="text-5xl">FAST Channels</h1>

        <div className="flex gap-20 mt-20">
          <div>
            <CreateTile
              label="FAST Channels"
              number={fastChannels.length}
              dialogButtonText="Create"
              dialogDescription="Enter a name to create a new FAST Channel"
              dialogLabel="Name"
              dialogTitle="Create FAST Channel"
              dialogTriggerText="Create a FAST Channel"
              postEndpoint="/setting/fast-channel"
              redirectPath="/fast-channels"
              storeAction={createFastChannel}
            />
          </div>

          <FastChannelTable fastChannels={fastChannels} />
        </div>
      </div>
    </Layout>
  )
}
